import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { generateDateLabels } from '@emma-helpers/parse-chart-data';
import {
  ApiRelativeResponse,
  ApiResponse,
  ApiSerialResponse,
  DateRange,
  objectToParams,
} from 'emma-common-ts';
import { EMMA_API_VERSION } from 'emma-common-ts/emma';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { AuthHeaders } from '@platform/helpers/auth-headers.class';
import { ApiService } from '@platform/services/api.service';
import { DateChangeService } from '@platform/services/date-change.service';
import { MetaData } from '@platform/services/smart-store.class';
import { SmartStoreService } from '@platform/services/smart-store.service';
import { omit } from 'lodash';
import { CurrentAppService } from './current-app.service';

let _cacheDateLabels: (DateRange & { labels: string[] }) | undefined;

interface ApiServiceOptions {
  headers?: HttpHeaders;
  forced?: boolean;
}

@Injectable({ providedIn: 'root' })
export class EmmaApiService {
  constructor(
    private currentAppService: CurrentAppService,
    private dateService: DateChangeService,
    private smartStore: SmartStoreService,
    private apiService: ApiService,
    @Inject(AuthHeaders) private authHeaders: AuthHeaders
  ) {}
  static dateRangeToDates(dateRange: DateRange): DateRange {
    return { ...dateRange };
  }

  static getDateLabels(dateRange: DateRange): string[] {
    if (
      !_cacheDateLabels ||
      _cacheDateLabels.startDay !== dateRange.startDay ||
      _cacheDateLabels.endDay !== dateRange.endDay ||
      _cacheDateLabels.timeGroup !== dateRange.timeGroup
    ) {
      _cacheDateLabels = {
        ...dateRange,
        labels: generateDateLabels(dateRange),
      };
    }
    return _cacheDateLabels.labels.slice(0);
  }

  public get = this.apiService.get;
  public post = this.apiService.post;
  public put = this.apiService.put;
  public delete = this.apiService.delete;
  public patch = this.apiService.patch;

  private getHeaders = (headers?: HttpHeaders) => {
    let requestHeaders: HttpHeaders = headers || this.authHeaders.get();
    if (requestHeaders) {
      requestHeaders = requestHeaders.set('X-Api-Version', EMMA_API_VERSION.V20);
    }
    return requestHeaders;
  };

  /**
   * Get data from the server if needed and cache it in a smart store.
   */
  private _getApiWithSmartStore<T = ApiResponse>(
    endpoint: string,
    params: { [k: string]: any } = {},
    options: ApiServiceOptions = {},
    smartStoreParams: Partial<MetaData> = {}
  ) {
    // Generate smart store name
    const storeName = SmartStoreService.generateStoreName([
      endpoint,
      omit(params, ['startDay', 'endDay', 'timeGroup']),
    ]);

    // Generate a query ID that will be used to check if requested data is already in the store
    const smartStoreId = SmartStoreService.generateStoreName([
      this.currentAppService.getCurrentApp(),
      endpoint,
      params,
    ]);

    if (smartStoreParams.id) {
      console.info('Forced id to smartStore');
    }
    const nextMeta: Partial<MetaData> = { ttl: 3000, id: smartStoreId, ...smartStoreParams };

    const store = this.smartStore.get<T>(storeName);
    if (store.shouldUpdate(nextMeta)) {
      // If the data is not in the store or too old, get it from the server
      store.willUpdate(nextMeta);
      this.apiService
        .get<T>(
          endpoint + '?' + objectToParams(params),
          {},
          {
            ...options,
            headers: this.getHeaders(options.headers),
          }
        )
        // Another request incoming after this one
        .pipe(takeUntil(store.$loadingStarts))
        .subscribe({
          next: (response) => store.update(response),
          error: (error) => {
            store.error(error);
            return throwError(() => error);
          },
        });
    }
    return store.get();
  }

  /**
   * Get API data that refreshes when the date range changes. Every `null` response means that it is loading.
   */
  public getRange<T = ApiRelativeResponse | ApiSerialResponse>(
    endpoint: string,
    dateRangeForced?: DateRange | null,
    params: { [k: string]: any } = {},
    options: ApiServiceOptions = {},
    meta: Partial<MetaData> = {}
  ): Observable<T | null> {
    return this.dateService.getChangeEmitter().pipe(
      switchMap((dateRange) => {
        if (dateRangeForced) {
          console.warn('FORCED DATERANGE:', dateRangeForced);
        }
        const _dateRange = dateRangeForced || dateRange;
        const dateParams = {
          ..._dateRange,
          ...params,
        };
        return this._getApiWithSmartStore<T>(endpoint, dateParams, options, meta).pipe(
          map((response) => {
            if (response) {
              Object.assign(response, { dateLabels: EmmaApiService.getDateLabels(_dateRange) });
            }
            return response;
          })
        );
      })
    );
  }

  public getRangeSerial<T = ApiSerialResponse>(
    endpoint: string,
    dateRange: DateRange | null,
    params: { [k: string]: any } = {},
    options: ApiServiceOptions = {},
    meta: Partial<MetaData> = {}
  ): Observable<T | null> {
    return this.getRange<T>(endpoint, dateRange, params, options, meta);
  }
  public getRangeRelative<T = ApiRelativeResponse>(
    endpoint: string,
    dateRange: DateRange | null,
    params: { [k: string]: any } = {},
    options: ApiServiceOptions = {},
    meta: Partial<MetaData> = {}
  ): Observable<T | null> {
    return this.getRange<T>(endpoint, dateRange, params, options, meta);
  }
}
