<emma-modal (close)="onCloseButton()" size="xl" [noFooter]="false">
    <ng-container class="emma-modal-title">
        <i class="lab la-youtube" aria-hidden="true"></i>
        <span i18n><span>Videotutorial: </span>{{ title }}</span>
    </ng-container>
    <ng-container class="emma-modal-body">
        <span class="row">
            <div class="col-md-12">
                <iframe [src]="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </span>
    </ng-container>
    <ng-container class="emma-modal-footer">
        <button emmaButton color="brand" (click)="onCloseButton()" i18n>Aceptar</button>
    </ng-container>
</emma-modal>
<style>iframe[src*="youtube.com"] { width:100%; height:auto; aspect-ratio:16/9; }
</style>