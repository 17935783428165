import { Component, OnInit } from '@angular/core';
import { environment } from '@emma-environments/environment';

@Component({
  selector: 'emma-script-injector-gtm',
  template: '',
})
export class EMMAScriptInjectorGTMComponent implements OnInit {
  ngOnInit(): void {
    EMMAScriptInjectorGTMComponent.injectGTM();
  }

  static injectGTM(): void {
    const gtmId = environment.gtmId;

    if (window.document.getElementById('gtm-script')) return;

    const script = document.createElement('script');
    script.id = 'gtm-script'; // Asegurando que el script tenga un ID para evitar duplicados
    script.async = true;
    script.innerHTML = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`;
    document.head.appendChild(script);

    const noscript = window.document.createElement('noscript');
    const iframe = window.document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    window.document.body.insertBefore(noscript, window.document.body.firstChild);
  }
}
