import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EMMAUIModule } from '@emma-components/emmaui.module';
import { EMMAFormModule } from '@emma-components/forms/emma-form.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { HeaderNavComponent } from './header-nav.component';

@NgModule({
  declarations: [HeaderNavComponent],
  imports: [CommonModule, EMMADirectivesModule, EMMAFormModule, EMMAUIModule, FormsModule, RouterModule],
  exports: [HeaderNavComponent],
})
export class HeaderNavModule {}
