import { Component, Input } from '@angular/core';
import { BannerNotificationsService } from '@platform/services/banner-notifications.service';
import { BannerNotification } from '@platform/types/notification.model';

@Component({
  selector: 'emma-notification-announcement',
  templateUrl: './emma-notification-announcement.component.html',
})
export class EMMANotificationAnnouncementComponent {
  @Input() announcements!: Array<BannerNotification>;
  constructor(private notificationsService: BannerNotificationsService) {}

  onDismiss(notification: BannerNotification) {
    this.notificationsService.delete(notification.key, true);
  }
}
