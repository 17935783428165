<emma-notification-announcement [announcements]="announcements"></emma-notification-announcement>
<div class="m-subheader" *ngIf="pageTitle">
    <div class="d-flex align-items-center">
        <div class="me-auto">
            <h3
                class="m-subheader__title"
                [ngClass]="{
                    'm--no-bottom-padding': !pageDescription
                }"
            >
                <i *ngIf="icon" class="la la-{{ icon }}" aria-hidden="true"></i>
                {{ pageTitle }}
            </h3>
            <div *ngIf="pageDescription" class="m-subheader__description">
                {{ pageDescription }}
            </div>
        </div>
        <div *ngIf="youtubeVideoEmbed.length" class="ms-auto">
            <emma-help-video
                [modalTitle]="titleModalVideoEmbed"
                [videoYoutube]="youtubeVideoEmbed"
            ></emma-help-video>
        </div>
    </div>
</div>
<platform-notification-feedback
    *ngIf="notifications"
    [notifications]="notifications || []"
></platform-notification-feedback>
<div
    class="m-content"
    [ngClass]="{
        'm--padding-top-50': !pageTitle
    }"
>
    <ng-content></ng-content>
</div>
