<ng-template #content let-modal>
    <div class="modal-header" *ngIf="!noHeader">
        <h5 class="modal-title">
            <ng-content select=".emma-modal-title"></ng-content>
        </h5>
        <button
            type="button"
            class="btn close"
            aria-label="Close"
            [disabled]="loading"
            (click)="onCloseButton()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form class="m-form">
        <div class="modal-body">
            <ng-content select=".emma-modal-body"></ng-content>
        </div>
    </form>
    <div class="modal-footer" *ngIf="!noFooter">
        <ng-content select=".emma-modal-footer"></ng-content>
    </div>
</ng-template>
