import { Component, Input, OnInit } from '@angular/core';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { getYoutubeIdVideo } from '@platform/helpers/tutorial-video.helper';
import { getCurrentLanguage } from '@platform/services/locale.service';
import { UserMetaService } from '@platform/services/user-meta.service';
import { YOUTUBE_VIDEO } from 'emma-common-ts/skudo';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'emma-help-video',
  templateUrl: './emma-help-video.component.html',
})
export class EMMAHelpVideoComponent extends OnUnsubscribe implements OnInit {
  @Input() modalTitle!: string;
  @Input() videoYoutube!: YOUTUBE_VIDEO;
  @Input() showInColumn = true;
  @Input() showHighlight = true;

  loading = new Set<string>();
  youtubeId = '';

  showModal = false;

  constructor(private userMetaService: UserMetaService) {
    super();
  }

  ngOnInit(): void {
    const locale = getCurrentLanguage();
    this.youtubeId = getYoutubeIdVideo(locale, this.videoYoutube);
    this.statusShowHighlight(this.videoYoutube);
  }

  statusShowHighlight(key: string) {
    this.loading.add('statusShowHighlight');
    this.userMetaService
      .getMetaButtonVideo(key)
      .pipe(apiFeedback('statusShowHighlight', undefined, this.loading), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value !== null) {
          this.showHighlight = value;
        } else {
          this.showHighlight = true;
        }
      });
  }
  changeShowHighlight(key: string): void {
    if (this.showHighlight) {
      this.loading.add('changeShowHighlight');
      this.userMetaService
        .updateMetaButtonVideo(key, false)
        .pipe(apiFeedback('changeShowHighlight', undefined, this.loading))
        .subscribe(() => (this.showHighlight = false));
    }
  }
  onShowModal(key: string): void {
    this.showModal = true;
    this.changeShowHighlight(key);
  }

  onCloseModal(): void {
    this.showModal = false;
  }
}
