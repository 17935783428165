import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMALoadingModule } from '@emma-components/emma-loading.module';
import { EMMAUIModule } from '@emma-components/emmaui.module';
import { EMMAFormModule } from '@emma-components/forms/emma-form.module';
import { LayoutModule } from '@emma-components/layouts/layout.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { EMMAPlanConfiguratorComponent } from './emma-plan-configurator.component';

@NgModule({
  imports: [
    CommonModule,
    EMMADirectivesModule,
    EMMAFormModule,
    EMMALoadingModule,
    EMMAUIModule,
    FormsModule,
    LayoutModule,
  ],
  exports: [CommonModule, EMMAPlanConfiguratorComponent],
  declarations: [EMMAPlanConfiguratorComponent],
})
export class EMMAPlanConfiguratorModule {}
