import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import type { RouterRoutes } from '@platform/types/router-route.model';
const routes: RouterRoutes = [{ path: '**', redirectTo: '/index' }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotFoundRoutingModule {}
