import { YOUTUBE_VIDEO } from 'emma-common-ts/skudo';

const idVideosDefault = {
  [YOUTUBE_VIDEO.CREATE_CAMPAIGNS_OFFERS]: 'c0weT6hXdy4',
  [YOUTUBE_VIDEO.ADD_OFFER_PROVIDER]: 'ajJ7DbQtaVM',
  [YOUTUBE_VIDEO.SEGMENTATION_FILTER]: '2ktMF1rC-5s',
  [YOUTUBE_VIDEO.REPORTING]: 'H204HDntmcI',
  [YOUTUBE_VIDEO.CREATE_EVENTS]: 'Z8gewjayg0s',
};

const idVideosSpanish = {
  [YOUTUBE_VIDEO.CREATE_CAMPAIGNS_OFFERS]: 'Qx6nY1X2TlU',
  [YOUTUBE_VIDEO.ADD_OFFER_PROVIDER]: 'K1VkO8zXXL8',
  [YOUTUBE_VIDEO.SEGMENTATION_FILTER]: 'dW-vOAGEM74',
  [YOUTUBE_VIDEO.REPORTING]: 'vrt3hibzAME',
  [YOUTUBE_VIDEO.CREATE_EVENTS]: 'PKZCB7LKmWY',
};

export const getYoutubeIdVideo = (language: string, video: YOUTUBE_VIDEO) => {
  if (language === 'es') {
    return idVideosSpanish[video];
  }
  return idVideosDefault[video];
};
