import { Component, Input } from '@angular/core';

@Component({
  selector: 'emma-missing-alert',
  templateUrl: './emma-missing-alert.component.html',
})
export class EMMAMissingAlertComponent {
  @Input()
  missing = '';
  @Input()
  class = '';
  hasShow = true;
  show() {
    this.hasShow = true;
  }
  hide() {
    this.hasShow = false;
  }
}
