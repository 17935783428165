import { getGitInfo, GitInfo } from '@platform/helpers/get-git-info.helper';

export function printVersion() {
  printEmmaVersion(getGitInfo());
}

function printEmmaVersion(gitInfo: GitInfo) {
  const line = new Array(30).fill('-').join('');
  console.log(`
${line}
     .  /|
  |\\/ \\/ |  EMMA   ${gitInfo.version || ''}
  |      |  Build: ${gitInfo.hash || ''}
${line}
`);
}
