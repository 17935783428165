import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@emma-services/user.service';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { ROUTE } from 'emma-common-ts/emma';
import { AUTH_TYPE } from '../auth-wrapper.component';

@Component({
  selector: 'emma-forgot-page',
  templateUrl: './forgot-password.component.html',
  // encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordPageComponent {
  hash = '';
  loading = new Set<string>();
  type = AUTH_TYPE.FORGOT_PASS;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('email') emailText = '';

  ROUTE = ROUTE;

  constructor(
    private router: Router,
    private userService: UserService,
    public notifications: ToastNotificationsService
  ) {}

  forgotPassword() {
    this.loading.add('recover');
    this.notifications.clear();
    this.userService
      .forgotPassword(this.emailText)
      .pipe(apiFeedback('recover', this.notifications, this.loading))
      .subscribe(() => {
        this.notifications.setInfo(
          $localize`¡Guay! Te hemos enviado las instrucciones de recuperación a tu correo.`
        );
        this.router.navigateByUrl(ROUTE.LOGIN).catch(console.error);
      });
  }

  isValid() {
    return Boolean(this.emailText);
  }

  onSubmit(ev: Event) {
    ev.preventDefault();
    if (this.isValid()) {
      this.forgotPassword();
    }
  }
}
