import { Component, Input } from '@angular/core';
import { BannerNotification } from '@platform/types/notification.model';

@Component({
  selector: 'emma-page',
  templateUrl: './emma-page.component.html',
})
export class EMMAPageComponent {
  @Input() pageTitle = '';
  @Input() pageDescription = '';
  @Input() icon = '';
  @Input() showHelpVideoBtn = true;
  @Input() titleModalVideoEmbed = '';
  @Input() youtubeVideoEmbed = '';

  @Input() notifications: Array<BannerNotification> = [];
  @Input() announcements: Array<BannerNotification> = [];
}
