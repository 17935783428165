<ng-container *ngIf="notifications.length">
    <div
        *ngFor="let notification of notifications"
        [ngClass]="{
            'm-alert': true,
            'm-alert--outline': true,
            'm-alert--icon': notification.icon,
            'm-alert--square': true,
            alert: true,
            'alert-dismissible': notification.dismissable,
            'alert-info': notification.level === NOTIFICATION_LEVEL.INFO,
            'alert-danger': notification.level === NOTIFICATION_LEVEL.ERROR,
            'alert-warning': notification.level === NOTIFICATION_LEVEL.WARNING,
            'alert-success': notification.level === NOTIFICATION_LEVEL.SUCCESS,
            'alert-metal': notification.level === NOTIFICATION_LEVEL.DEBUG
        }"
        role="alert"
    >
        <div class="m-alert__icon">
            <i [class]="notification.icon" aria-hidden="true"></i>
        </div>
        <div class="m-alert__text">{{ notification.message }}</div>
        <div *ngIf="notification.dismissable" class="m-alert__close">
            <button
                emmaButton
                type="button"
                class="close"
                color="transparent"
                [noBg]="true"
                sizeButton="sm"
                [hasIcon]="true"
                [onlyIcon]="true"
                [isCircle]="true"
                data-dismiss="alert"
                aria-label="Cerrar"
                (click)="onDismiss(notification)"
                i18n-aria-label
            ></button>
        </div>
        <div *ngIf="notification.contactButton" class="m-alert__actions">
            <button
                emmaButton
                class="close"
                data-dismiss="alert"
                color="brand"
                sizeButton="sm"
                class="m--margin-left-10"
                (click)="onContactButton(notification)"
                i18n
            >
                Contactar
            </button>
        </div>
        <div *ngIf="notification.dontShowMore" class="m-alert__actions">
            <button
                emmaButton
                class="close"
                data-dismiss="alert"
                color="brand"
                sizeButton="sm"
                class="m--margin-left-10"
                (click)="onDontShowMore(notification)"
                i18n
            >
                No mostrar más
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="notification">
    <div
        [ngClass]="{
            'm-alert': true,
            'm-alert--outline': true,
            'm-alert--icon': notification.icon,
            'm-alert--square': true,
            alert: true,
            'alert-dismissible': notification.dismissable || notification.level === NOTIFICATION_LEVEL.ERROR,
            'alert-emmagreen': notification.level === NOTIFICATION_LEVEL.SUCCESS,
            'alert-emmadarkblue': notification.level === NOTIFICATION_LEVEL.INFO,
            'alert-danger':
                notification.level === NOTIFICATION_LEVEL.ERROR ||
                notification.level === NOTIFICATION_LEVEL.WARNING
        }"
        role="alert"
    >
        <div class="m-alert__icon">
            <i [class]="notification?.icon" aria-hidden="true"></i>
        </div>
        <div class="m-alert__text">
            {{ notification?.message }}
            <ng-container *ngIf="notification?.helpLinkText">
                <i class="m--font-brand la la-external-link" aria-hidden="true"></i>
                <a
                    [href]="notification?.helpLinkUrl"
                    class="m-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ notification?.helpLinkText }}
                </a>
            </ng-container>
        </div>
        <ng-template #defaultContactBtn>
            <div
                *ngIf="notification.contactButton"
                class="m-alert__actions"
            >
                <button
                    emmaButton
                    class="close"
                    data-dismiss="alert"
                    color="brand"
                    sizeButton="sm"
                    class="m--margin-left-10"
                    (click)="onContactButton(notification)"
                    i18n
                >
                    Contactar
                </button>
            </div>
        </ng-template>
        <ng-template
            [ngTemplateOutlet]="notificationActionsRightTemplateDirective || defaultContactBtn"
            [ngTemplateOutletContext]="{ notification: notification }"
        ></ng-template>
        <ng-container *ngIf="notification?.dismissable">
            <ng-template #defaultDismissBtn>
                <div class="m-alert__close">
                    <button
                        emmaButton
                        type="button"
                        class="close"
                        color="transparent"
                        [noBg]="true"
                        sizeButton="sm"
                        [hasIcon]="true"
                        [onlyIcon]="true"
                        [isCircle]="true"
                        data-dismiss="alert"
                        aria-label="Cerrar"
                        (click)="onDismiss(notification)"
                        i18n-aria-label
                    ></button>
                </div>
            </ng-template>
            <ng-template
                [ngTemplateOutlet]="notificationDismissButtonTemplateDirective || defaultDismissBtn"
                [ngTemplateOutletContext]="{}"
            ></ng-template>
        </ng-container>
    </div>
</ng-container>
