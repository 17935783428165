import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'emma-video-embed-modal',
  templateUrl: './emma-video-embed-modal.component.html',
})
export class EMMAVideoEmbedModalComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {}

  @Input() title = '';
  @Input() youtubeId = '';

  @Output() $close = new EventEmitter<void>();

  youtubelink = '';
  videoUrl: SafeResourceUrl = '';

  ngOnInit(): void {
    this.youtubelink = 'https://www.youtube.com/embed/' + this.youtubeId;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.youtubelink);
  }

  onCloseButton() {
    this.$close.emit();
  }
}
