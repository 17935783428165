import { AfterViewInit, Component, Input, OnInit, Inject } from '@angular/core';
import { isPowerAdminUser } from '@emma-helpers/auth.helper';
import { AppsService } from '@emma-services/apps.service';
import { FinalRouteService } from '@platform/services/final-route.service';
import { PermissionFlagsService } from '@emma-services/permission-flags.service';
import { UserService } from '@emma-services/user.service';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { isMenuActiveRoute } from '@platform/helpers/menu-item.helper';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { DateChangeService } from '@platform/services/date-change.service';
import { DOCUMENT } from '@angular/common';
import { DateRange } from 'emma-common-ts';
import {
  EMMAUserAppInfo,
  EMMA_API_VERSION,
  PERMISSION_ID,
  PERMISSION_TYPE,
  ROUTE,
  User,
} from 'emma-common-ts/emma';
import { get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { HeaderNavMenuGenerator, MenuItem } from './header-nav-menu-generator.class';

declare let mLayout: { initHeader: () => unknown };

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
})
export class HeaderNavComponent extends OnUnsubscribe implements OnInit, AfterViewInit {
  @Input() isIframeMode?: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dateService: DateChangeService,
    private userService: UserService,
    private appsService: AppsService,
    private finalRoute: FinalRouteService,
    private permissionsService: PermissionFlagsService
  ) {
    super();
    this.updateRouteVisibility();
  }
  userApps: EMMAUserAppInfo[] = [];
  currentApp?: EMMAUserAppInfo;
  user: User | undefined;

  menuItems: MenuItem[] = [];

  selectedAppId?: number;
  loading = new Set<string>();

  ROUTE = ROUTE;

  isDateSelectorVisible = false;
  hasFilterBar = false;

  static setSVGPath(): void {
    const placeSVG = window.location.href;
    const svgLogo = window.document.getElementById('EMMA-Logo');
    if (svgLogo) {
      const svgElementGradient = svgLogo.getElementsByClassName('gradientFillEMMA') || {};
      Object.keys(svgElementGradient).forEach((_, i) => {
        svgElementGradient[i].setAttribute('fill', 'url(' + placeSVG + '#gradientEMMA)');
      });
    }
  }

  ngOnInit(): void {
    HeaderNavComponent.setSVGPath();
    this.loading.add('user');
    this.userService
      .getCurrentUser()
      .pipe(apiFeedback('user', undefined, this.loading), takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        this.user = user;
        if (user && this.currentApp) {
          this.updateMenu();
        }
      });
    this.loading.add('apps');
    this.appsService
      .getApps()
      .pipe(apiFeedback('apps', undefined, this.loading), takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.userApps = (response && response.data) || [];
        const currentAppId = get(this.currentApp, 'appConfig.appId');
        this.appsService.selectCurrentApp(currentAppId);
      });
    this.appsService.$currentApp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((app) => {
      this.currentApp = app;
      this.selectedAppId = get(this.currentApp, 'appConfig.appId');
      this.updateMenu();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  ngAfterViewInit(): void {
    mLayout?.initHeader();
  }

  changeCurrentApp(appId: string | undefined): void {
    if (appId) {
      this.appsService.selectCurrentApp(Number(appId));
    } else {
      throw new Error('App ID not found');
    }
  }

  changeDate(dateRange: DateRange): void {
    this.dateService.changeDate(dateRange);
  }

  isActive = isMenuActiveRoute;

  isEnabled(route: string): boolean {
    return this.permissionsService.canWrite(`${PERMISSION_TYPE.ROUTE}__${route}` as PERMISSION_ID);
  }
  isVisible(route: string): boolean {
    return this.permissionsService.canRead(`${PERMISSION_TYPE.ROUTE}__${route}` as PERMISSION_ID);
  }

  isLegacy(): boolean {
    return get(this.currentApp, 'appConfig.apiVersion') === EMMA_API_VERSION.V10;
  }

  updateMenu(): void {
    const menuItems = HeaderNavMenuGenerator.getMenu({
      isLegacy: this.isLegacy(),
      isAdmin: isPowerAdminUser(this.user, this.currentApp),
    });

    // Función para calcular los permisos
    const menuItemPermissions = (mi: MenuItem) => {
      const routeCheck =
        mi.route &&
        mi.route
          .split('/')
          .filter((r) => r !== 'legacy')
          .join('/');
      const isDisabled = mi.disabled || (routeCheck ? !this.isEnabled(routeCheck) : false);
      const isHidden = mi.hidden || (routeCheck ? !this.isVisible(routeCheck) : false);
      return {
        ...mi,
        disabled: isDisabled,
        hidden: isHidden,
      };
    };

    this.menuItems = menuItems
      .map((mi) => {
        const menu = mi.menu && mi.menu.map(menuItemPermissions).filter((m) => !m.hidden);
        return {
          ...menuItemPermissions(mi),
          menu,
          // Si no tiene elementos, se desactiva
          disabled: mi.disabled || !menu || !menu.length,
        };
      })
      .filter((mi) => !mi.hidden);
  }

  updateRouteVisibility(): void {
    this.finalRoute.dataMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.isDateSelectorVisible = Boolean(data.isDateSelectorVisible);
      this.hasFilterBar = Boolean(data.hasFilterBar);
      if (this.isDateSelectorVisible) {
        this.document.body.classList.remove('no-date-bar');
      } else {
        this.document.body.classList.add('no-date-bar');
      }
    });
  }
}
