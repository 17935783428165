<div [class]="class">
    <div *ngIf="hasShow" class="emma-alert-nodata alertAttachedPortlet">
        <i class="las la-cloud-sun" aria-hidden="true"></i>
        <strong i18n>¡No hay datos!</strong>
        <span *ngIf="!missing" i18n
            >En el período de fechas elegido no hay datos para poder mostrar esta información.</span
        >
        <span *ngIf="missing"> {{ missing }}</span>
    </div>
</div>
