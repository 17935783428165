import { Injectable } from '@angular/core';
import { ApiChangeResponse, ApiResponse } from 'emma-common-ts';
import { AccountPlan, AccountPlanSave, Plan, PlanContactMail } from 'emma-common-ts/emma';

import { EmmaApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PlanService {
  constructor(private apiService: EmmaApiService) {}

  getAll() {
    return this.apiService.get<ApiResponse<Plan[]>>('/plans');
  }

  getAccountPlan(accountId: number) {
    const endpoint = `/account/${String(accountId)}/plan`;
    return this.apiService.get<ApiResponse<AccountPlan>>(endpoint);
  }

  updateAccountPlan(accountId: number, accountPlan: AccountPlanSave) {
    const endpoint = `/account/${String(accountId)}/plan`;
    return this.apiService.put<ApiChangeResponse>(endpoint, accountPlan);
  }

  planContact(contactMail: PlanContactMail) {
    const endpoint = `/plans/${contactMail.public ? 'contact-public' : 'contact'}`;
    return this.apiService.post<ApiResponse>(endpoint, contactMail);
  }
}
