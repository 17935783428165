import { Directive, TemplateRef } from '@angular/core';

// tslint:disable: max-classes-per-file
@Directive({ selector: '[platformNotificationActionsRightTpl]' })
export class PlatformNotificationActionsRightTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[platformNotificationDismissButtonTpl]' })
export class PlatformNotificationDismissButtonTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}
