import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DefaultComponent } from '@emma-components/default.component';
import { EMMAPlanConfiguratorModule } from '@emma-components/emma-plan-configurator/emma-plan-configurator.module';
import { EMMAUIModule } from '@emma-components/emmaui.module';
import { ConfiguratorPageComponent } from '@emma-pages/public/configurator/configurator.component';

import type { RouterRoutes } from '@platform/types/router-route.model';
const routes: RouterRoutes = [
  {
    path: 'ui-components',
    loadChildren: () =>
      import('@emma-pages/public/ui-components/ui-components.module').then((m) => m.UIComponentsPageModule),
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: 'configurator',
        component: ConfiguratorPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), EMMAPlanConfiguratorModule, EMMAUIModule],
  exports: [RouterModule],
  declarations: [ConfiguratorPageComponent],
})
export class PublicPathModule {}
