import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@emma-services/user.service';
import { BackToLoginService } from '@emma/_services/back-to-login.service';
import { getErrorFromResponse } from '@platform/helpers/response-error.helper';
import { cleanAuthTokenFromPath } from '@platform/helpers/url.helper';
import { AlertService } from '@platform/services/alert.service';
import { AuthenticationService } from '@platform/services/authentication.service';
import { EMMAUserScopeResponse, User } from 'emma-common-ts/emma';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private backToLoginService: BackToLoginService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let res: Observable<EMMAUserScopeResponse | boolean>;
    const authToken = route.queryParams['authToken'];
    const user = this.userService.getCurrentUser().getValue();
    if (authToken) {
      res = this.activateByToken(authToken);
    } else if (user) {
      res = this.activateByUser(user);
    } else {
      res = throwError(() => 'Not allowed');
    }
    return res.pipe(
      map((prev) => {
        if ('authToken' in route.queryParams) {
          // Remove authToken from URL
          this.router
            .navigateByUrl(cleanAuthTokenFromPath(state.url), {
              replaceUrl: true,
            })
            .catch(console.error);
        }
        return Boolean(prev);
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          this.authService.logout();
          this.alertService.error(getErrorFromResponse(error));
        }
        // Something went wrong? Remove authToken from URL and return to login page
        this.backToLoginService.backToLogin({ from: cleanAuthTokenFromPath(state.url) }).catch(console.error);
        return of(false);
      })
    );
  }

  activateByUser(user: User) {
    return this.userService.verify(user).pipe(
      map((userScope) => {
        this.userService.updateUser({
          ...userScope.user,
          apps: Object.values(userScope.scope.apps),
        });
        return true;
      })
    );
  }

  activateByToken(token: string) {
    return this.authService.autoLogin(token).pipe(
      switchMap((res) => {
        if (res && res.access_token) {
          this.userService.updateUser(res);
        }
        const user = this.userService.getCurrentUser().getValue();
        if (user) {
          return this.userService.verify(user);
        }
        return throwError(() => 'No user from token');
      })
    );
  }
}
