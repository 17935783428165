<router-outlet></router-outlet>
<emma-script-injector-gtm></emma-script-injector-gtm>
<platform-scroll-top></platform-scroll-top>
<script-injector-statuspage></script-injector-statuspage>
<div class="m-page-loader m-page-loader--non-block">
    <div class="customEMMALoading">
        <div class="customEMMALoading-Bg">
            <div class="customEMMALoading-Iso">
                <img
                    id="IsoEMMA"
                    class="svgImage loadingIsoEMMA"
                    src="assets/media/emma/img/logo/emma-isotipo-lightGreen.svg"
                    alt="EMMA"
                    i18n-alt
                />
            </div>
        </div>
    </div>
</div>
