import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmbedService } from '@emma-services/embed.service';
import { UserService } from '@emma-services/user.service';
import { WINDOW_VAR_TOKEN_NAME } from '@platform/constants/auth.constants';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { AuthenticationService } from '@platform/services/authentication.service';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { ROUTE } from 'emma-common-ts/emma';
import { switchMap } from 'rxjs/operators';

import { setGlobalLoading } from '@emma-helpers/legacy-jquery-utils.helper';
import { AUTH_TYPE } from '../auth-wrapper.component';

@Component({
  selector: 'emma-auto-login-page',
  templateUrl: './auto-login.component.html',
})
export class TokenLoginPageComponent implements OnInit {
  loading = new Set<string>();
  type = AUTH_TYPE.LOGIN;

  @Input() returnUrl = '';
  ROUTE = ROUTE;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private embedService: EmbedService,
    public notifications: ToastNotificationsService
  ) {}

  ngOnInit(): void {
    this.embedService.checkEmbedMode(this.activatedRoute.snapshot);
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
    setGlobalLoading(true);
    const w = window as any;
    if (w[WINDOW_VAR_TOKEN_NAME]) {
      this.login();
    } else {
      setTimeout(() => this.login(), 2000);
    }
  }

  login(): void {
    setGlobalLoading(false);
    const w = window as any;
    const token = w[WINDOW_VAR_TOKEN_NAME] || undefined;
    if (token) {
      this.loading.add('login');
      this.authService
        .tokenLogin(token)
        .pipe(
          switchMap((res) => {
            if (res?.access_token) {
              this.userService.updateUser(res);
            }
            const user = this.userService.getCurrentUser().getValue();
            return this.userService.verify(user);
          }),
          apiFeedback('login', this.notifications, this.loading)
        )
        .subscribe(() => {
          this.router.navigateByUrl(this.returnUrl || '/').catch(console.error);
        });
    } else {
      this.router.navigateByUrl(this.returnUrl || '/').catch(console.error);
    }
  }
}
