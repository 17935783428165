import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionFlagsService } from '@emma-services/permission-flags.service';
import { PERMISSION_BOOL_OP, PERMISSION_ID } from 'emma-common-ts/emma';

@Directive({
  selector: '[canView]',
})
export class CanViewDirective {
  private operator: PERMISSION_BOOL_OP = PERMISSION_BOOL_OP.AND;
  private permissionId: PERMISSION_ID | Array<PERMISSION_ID> = [];

  constructor(
    // private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionFlagsService
  ) {}

  @Input() set canView(permissionId: PERMISSION_ID | Array<PERMISSION_ID>) {
    this.permissionId = permissionId;
    this.updateView();
  }
  @Input() set canViewOperation(op: string) {
    this.operator = op as PERMISSION_BOOL_OP;
    this.updateView();
  }

  private updateView() {
    if (this.permissionService.canRead(this.permissionId, this.operator)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
