import { Injectable } from '@angular/core';
import { formatStringCase } from '@emma-helpers/format.helper';
import { ROUTE } from 'emma-common-ts/emma';
import { flatten } from 'lodash';

export interface MenuItem {
  title: string;
  icon: string;
  route?: string;
  menu?: Array<MenuItem>;
  disabled?: boolean;
  hidden?: boolean;
}

interface MenuOptions {
  isLegacy: boolean;
  isAdmin: boolean;
}

@Injectable({ providedIn: 'root' })
export class HeaderNavMenuGenerator {
  static getMenu({ isLegacy = false, isAdmin = false }: MenuOptions): Array<MenuItem> {
    const root = isLegacy ? ROUTE.LEGACY_ROOT : '';
    const menu: Array<Omit<MenuItem, 'menu'> & { menu?: Array<MenuItem | undefined> }> = [];
    menu.push(
      {
        icon: 'area-chart',
        title: $localize`General`,
        menu: [
          {
            title: $localize`Resumen ejecutivo`,
            icon: 'compass',
            route: root + ROUTE.MAIN_EXECUTIVE,
          },
          {
            title: $localize`Cuadro de mandos`,
            icon: 'dashboard',
            route: root + ROUTE.MAIN_DASHBOARDS,
          },
          {
            title: $localize`Informes`,
            icon: 'folder-o',
            route: root + ROUTE.MAIN_REPORTS,
          },
        ],
      },
      {
        title: $localize`Captación`,
        icon: 'user',
        menu: [
          !isLegacy
            ? undefined
            : {
                title: $localize`Instalaciones`,
                icon: 'user-plus',
                route: root + ROUTE.ACQUISITION_INSTALLS,
              },
          !isLegacy
            ? undefined
            : {
                title: $localize`Apptracker`,
                icon: 'user-secret',
                route: `${root}${ROUTE.ACQUISITION_APPTRACKER}`,
              },
          !isLegacy
            ? undefined
            : {
                title: $localize`Apptracker`,
                icon: 'glasses',
                route: `${root}${ROUTE.ACQUISITION_APPTRACKER_PROVIDER_VIEW}`,
              },
          {
            title: $localize`SKAN perspectiva`,
            icon: 'universal-access',
            route: ROUTE.ACQUISITION_SKADNETWORK,
          },
          !isLegacy
            ? undefined
            : {
                title: $localize`Desinstalaciones`,
                icon: 'user-times',
                route: root + ROUTE.ACQUISITION_UNINSTALLS,
              },
          {
            title: $localize`Fuentes de medios`,
            icon: 'magnet',
            route: root + ROUTE.ACQUISITION_PROVIDERS,
          },
          {
            title: $localize`Actividad (beta)`,
            icon: 'bar-chart',
            route: root + ROUTE.ACQUISITION_ACTIVITY,
          },
          {
            title: $localize`Enlaces cortos`,
            icon: 'cut',
            route: root + ROUTE.ACQUISITION_LINKS,
          },
          {
            title: $localize`Powlinks (beta)`,
            icon: 'bomb',
            route: root + ROUTE.ACQUISITION_POWLINKS,
          },
          {
            title: $localize`Retargeting (beta)`,
            icon: 'recycle',
            route: root + ROUTE.ACQUISITION_RETARGETING,
          },
          {
            title: $localize`Trackers (beta)`,
            icon: 'user-secret',
            route: root + ROUTE.ACQUISITION_TRACKERS,
          },
        ],
      },
      {
        title: $localize`Comportamiento`,
        icon: 'hand-o-up',
        menu: [
          {
            title: $localize`Explorador`,
            icon: 'binoculars',
            route: root + ROUTE.BEHAVIOR_EXPLORE,
          },
          {
            title: $localize`Gente`,
            icon: 'male',
            route: root + ROUTE.BEHAVIOR_PEOPLE,
          },
          {
            title: $localize`Embudos`,
            icon: 'filter',
            route: root + ROUTE.BEHAVIOR_FUNNELS,
            disabled: !isLegacy,
          },
          {
            title: $localize`Audiencias`,
            icon: 'users',
            route: root + ROUTE.BEHAVIOR_AUDIENCES,
          },
        ],
      },
      {
        title: $localize`Comunicación`,
        icon: 'comments',
        menu: [
          {
            title: $localize`Mensajes`,
            icon: 'comments',
            route: root + ROUTE.COMMUNICATION_MESSAGING,
          },
          {
            title: $localize`Informe de comunicaciones`,
            icon: 'line-chart',
            route: root + ROUTE.COMMUNICATION_REPORT,
          },
          {
            title: $localize`Informe de rendimiento`,
            icon: 'chart-bar',
            route: root + ROUTE.COMMUNICATION_PERFORMANCE_REPORT,
          },
          !isLegacy
            ? undefined
            : {
                title: $localize`Reglas`,
                icon: 'map-signs',
                route: root + ROUTE.COMMUNICATION_RULES,
              },
          {
            title: $localize`Rutas de cliente`,
            icon: 'route',
            route: root + ROUTE.COMMUNICATION_JOURNEYS,
          },
        ],
      },
      {
        title: $localize`Productos`,
        icon: 'flask',
        menu: [
          {
            title: $localize`EMMA Wallet`,
            icon: 'wallet',
            route: ROUTE.PRODUCTS_WALLET,
          },
        ],
      },
      {
        title: $localize`Gestión`,
        icon: 'gear',
        menu: [
          {
            title: $localize`Plantillas Nativas`,
            icon: 'file-code-o',
            route: root + ROUTE.MANAGE_NATIVE_TEMPLATES,
          },
          {
            title: $localize`Proveedores de email`,
            icon: 'envelope',
            route: root + ROUTE.MANAGE_MAIL_PROVIDERS,
          },
          {
            title: $localize`Integraciones`,
            icon: 'puzzle-piece',
            route: root + ROUTE.INTEGRATIONS,
          },
          {
            title: $localize`Eventos`,
            icon: 'flag-o',
            route: root + ROUTE.MANAGE_EVENTS,
          },
          {
            title: $localize`Etiquetas`,
            icon: 'tags',
            route: root + ROUTE.MANAGE_TAGS,
          },
          {
            title: $localize`Landings`,
            icon: 'file-code-o',
            route: root + ROUTE.MANAGE_LANDINGS,
          },
          {
            title: $localize`Categorías`,
            icon: 'tags',
            route: root + ROUTE.MANAGE_CATEGORIES,
          },
          {
            title: $localize`Parámetros de comunicación`,
            icon: 'sliders-h',
            route: root + ROUTE.MANAGE_COMMUNICATION_PARAMETERS,
          },
          {
            title: $localize`Importación`,
            icon: 'file-upload',
            route: root + ROUTE.MANAGE_IMPORT_TASK,
          },
        ],
      }
    );
    if (isAdmin) {
      menu.push({
        title: $localize`Administración`,
        icon: 'gear',
        menu: [
          {
            title: $localize`Fuentes de medios`,
            icon: 'magnet',
            route: ROUTE.ADMIN_PROVIDERS,
          },
          {
            title: $localize`Roles`,
            icon: 'user',
            route: ROUTE.ADMIN_ROLES,
          },
          {
            title: $localize`Plantillas Nativas`,
            icon: 'file-code-o',
            route: ROUTE.ADMIN_NATIVE_TEMPLATES,
          },
        ],
      });
    }
    return menu
      .filter(Boolean)
      .map((m) => (m.menu ? { ...m, menu: m.menu.filter(Boolean) } : m)) as Array<MenuItem>;
  }

  static consoleLogRoutePermissions(): void {
    const routes: string[] = flatten(
      HeaderNavMenuGenerator.getMenu({ isLegacy: true, isAdmin: true }).map((m) => m?.menu ?? [])
    )
      .map((m) => m.route || '')
      .filter(Boolean);
    const permissionFlags = new Set<string>();
    for (const route of routes) {
      const routeStr = route
        .split('/')
        .filter((r) => r !== 'legacy')
        .join('/');
      permissionFlags.add(`${formatStringCase(routeStr, 'uppersnake')}='${routeStr}',`);
    }
    for (const route of routes) {
      const routeStr = route
        .split('/')
        .filter((r) => r !== 'legacy')
        .join('/');
      permissionFlags.add(`ROUTE_${formatStringCase(routeStr, 'uppersnake')}='route__${routeStr}',`);
    }
  }
}
