import jQuery from 'jquery';
import { debounce } from 'lodash';

/**
 * LEGACY UTILS, METRONIC GARBAGE
 */

function __setGlobalLoading(enable = false): void {
  if (enable) {
    window.document.body.classList.add('m-page--loading-non-block');
  } else {
    window.document.body.classList.remove('m-page--loading-non-block', 'm-page--loading-opaque');
  }
}

export const setGlobalLoading = debounce(__setGlobalLoading, 100, { leading: true });

export function jQueryToggleClassDuringAnimation(selector: string, classname: string): void {
  jQuery(selector)
    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
      jQuery(selector).removeClass(classname);
    })
    .addClass(classname);
}
