import { Component, Input } from '@angular/core';

export enum AUTH_TYPE {
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASS = 'forgot_pass',
}

@Component({
  selector: 'emma-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
})
export class AuthWrapperComponent {
  @Input() type?: AUTH_TYPE;

  AUTH_TYPE = AUTH_TYPE;
}
