import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectOption } from '@platform/types';
import { PlanContactInfo } from 'emma-common-ts/emma';

@Component({
  selector: 'emma-contact-plan-configurator-modal',
  templateUrl: './emma-contact-plan-configurator-modal.component.html',
})
export class EMMAContactPlanConfiguratorModalComponent {
  @ViewChild('form', { static: false }) public form!: NgForm;
  @Output() $close = new EventEmitter<void>();
  @Output() $confirmation = new EventEmitter<PlanContactInfo>();

  companyName = '';
  workMail = '';
  phoneNumber = '';
  companySize = '';
  privacySwitch = false;

  companySizeOpt: SelectOption[] = [
    { label: $localize`1 empleado`, value: 0 },
    { label: $localize`2 - 10 empleados`, value: 1 },
    { label: $localize`51 - 200 empleados`, value: 2 },
    { label: $localize`201 - 500 empleados`, value: 3 },
    { label: $localize`501 - 1000 empleados`, value: 4 },
    { label: $localize`1001 - 5000 empleados`, value: 5 },
    { label: $localize`5001 - 10 000 empleados`, value: 6 },
    { label: $localize`Más de 10 000 empleados`, value: 7 },
  ];

  helpText = $localize`<span class="parrf"><strong>¿Qué empresas tratan sus datos?</strong> EMMA MOBILE SOLUTIONS, S.L. (en adelante "EMMA")</span> <span class="parrf"><strong>¿Por qué tratamos los datos que le pedimos?</strong> Tratamos sus datos para poder prestarle nuestros servicios y enviarle información sobre productos y/o servicios de terceras empresas.</span> <span class="parrf"><strong>¿Cuál es la legitimización para este tratamiento de sus datos?</strong> Estos datos son necesarios para llevar a cabo la prestación de los servicios que haya solicitado a través del Sitio Web.</span> <span class="parrf"><strong>¿Se utilizan sus datos para hacer perfilados o segmentaciones?</strong> EMMA podrá utilizar técnicas de profiling para poder ofrecerle publicidad de terceros acorde con sus intereses.</span> <span class="parrf"><strong>¿Tiene dudas?</strong> Tanto si tiene alguna o sugerencia como si quiere darse de baja póngase en contacto con nosotros enviando un email a la siguiente dirección: info@emma.io</span>`;
  helpLinkLabel = $localize`Saber más`;
  helpLink = $localize`https://emma.io/politica-de-privacidad/`;

  canSend(): boolean {
    return Boolean(this.form?.valid);
  }

  onCloseButton(): void {
    this.$close.emit();
  }

  onConfirmButton(): void {
    const companySizeSelected = this.companySizeOpt.find((cs) => cs.value === this.companySize);
    const contact = {
      company: this.companyName,
      email: this.workMail,
      phone: this.phoneNumber,
      companySize: companySizeSelected ? companySizeSelected.label : '',
    };
    this.$confirmation.emit(contact);
  }
}
