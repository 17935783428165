import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@emma-services/user.service';
import { User } from 'emma-common-ts/emma';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NoUserGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.userService.getCurrentUser().getValue();
    if (!user) {
      return true;
    } else {
      return this.userService.verify(user).pipe(
        catchError(() => of(true)),
        map(() => {
          this.router.navigateByUrl('/').catch(console.error);
          return false;
        })
      );
    }
  }

  validateUser(user: User) {
    return this.userService.verify(user);
  }
}
