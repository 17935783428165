import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { validatorModule } from '@emma-helpers/emma-validators.helper';
import { debounce, noop } from 'lodash';
import { ChangeContext } from 'ngx-slider-v2';
import { takeUntil } from 'rxjs/operators';

import { EMMAFormElementComponent } from './emma-form-element.component';

export interface SliderOptions {
  animate: boolean;
  floor: number;
  ceil: number;
  step?: number;
  minLimit?: number;
  maxLimit?: number;
  minRange?: number;
  showTicks?: boolean;
  showTicksValues?: boolean;
  tickStep?: number;
  noSwitching?: boolean;
  hidePointerLabels?: boolean;
  hideLimitLabels?: boolean;
  showSelectionBar?: boolean;
  showSelectionBarEnd?: boolean;
  draggableRange?: boolean;
  disabled?: boolean;
  translate?: any;
  getLegend?: any;
}

export const numberToTime = (value: number): string => {
  return `${Math.floor(value / 60)}:${value % 60 === 0 ? '00' : value % 60}`;
};

const numberToTimeLegend = (value: number): string => {
  return String(value / 60);
};

const EMMA_SLIDER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMASliderComponent),
  multi: true,
};

const EMMA_SLIDER_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMASliderComponent),
  multi: true,
};

@Component({
  selector: 'emma-slider',
  templateUrl: './emma-slider.component.html',
  providers: [
    EMMA_SLIDER_CONTROL_VALUE_ACCESSOR,
    EMMA_SLIDER_VALIDATOR,
    {
      provide: EMMAFormElementComponent,
      useExisting: EMMASliderComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EMMASliderComponent
  extends EMMAFormElementComponent
  implements AfterViewInit, OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator
{
  @Input() unit = '';
  @Input() min = 0;
  @Input() max = 100;
  @Input() minLimit?: number;
  @Input() maxLimit?: number;
  @Input() step = 1;
  @Input() isRange = false;
  @Input() minRange = 0;
  @Input() animate = false;
  @Input() showTicks = false;
  @Input() showTicksValues = false;
  @Input() tickStep = 10;
  @Input() noSwitching = true;
  @Input() hidePointerLabels = true;
  @Input() hideLimitLabels = false;
  @Input() showSelectionBar = true;
  @Input() showSelectionBarEnd = false;
  @Input() draggableRange = false;
  @Input() numberAsTime = false;
  @Input() getLegend?: (value: number) => string;
  @Input() color: 'brand' | 'altgreen' | 'altpink' | 'altblue' | 'altorange' | 'altyellow' | 'altviolet' =
    'altgreen';

  @Input() value = 0;
  @Input() highValue = 0;

  @Input() showTag = false;
  @Input() reverseTag = false;
  @Input() isPercent = false;
  @Input() unitsTag = '';

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Output() override $change = new EventEmitter<ChangeContext>();
  @Output() $changeEnd = new EventEmitter<void>();
  @Output() $changeStart = new EventEmitter<void>();

  @Output() valueChange = new EventEmitter<number>();
  @Output() highValueChange = new EventEmitter<number>();

  /**
   * Internal slider stuff
   */
  sliderClass = `emma-ngx-slider-${this.color}`;
  sliderOptions!: SliderOptions;
  manualRefresh = new EventEmitter<void>();

  onValueChange = debounce((value: number) => {
    this.valueChange.emit(value);
  }, 100);

  onHighValueChange = debounce((value: number) => {
    this.highValueChange.emit(value);
  }, 100);

  override ngOnInit(): void {
    this.updateSliderOptions();
    this.$changeStart.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.onTouchedCallback();
    });
    this.$changeEnd.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.onChangeCallback(this.value);
    });
  }

  override ngOnChanges(changes: SimpleChanges): void {
    const hasOptionsChange = Object.keys(changes).filter((k) => k !== 'value' && k !== 'highValue').length;
    if (hasOptionsChange) {
      this.updateSliderOptions();
    }
    if ('color' in changes) {
      this.updateColor();
    }
  }

  updateSliderOptions() {
    this.sliderOptions = {
      floor: this.min,
      ceil: this.max,
      step: this.step,
      minLimit: this.minLimit,
      maxLimit: this.maxLimit,
      showTicks: this.showTicks,
      showTicksValues: this.showTicksValues,
      hidePointerLabels: this.hidePointerLabels,
      hideLimitLabels: this.hideLimitLabels,
      showSelectionBar: this.showSelectionBar,
      showSelectionBarEnd: this.showSelectionBarEnd,
      tickStep: this.tickStep,
      animate: this.animate,
      disabled: this.disabled || this.readonly,
    };
    if (this.isRange) {
      Object.assign(this.sliderOptions, {
        minRange: this.minRange,
        noSwitching: this.noSwitching,
        draggableRange: this.draggableRange,
      });
    }
    if (this.numberAsTime) {
      Object.assign(this.sliderOptions, {
        translate: numberToTime,
        getLegend: numberToTimeLegend,
      });
    }
    if (this.getLegend) {
      Object.assign(this.sliderOptions, { getLegend: this.getLegend });
    }
  }

  updateColor() {
    this.sliderClass = `emma-ngx-slider-${this.color}`;
  }

  ngAfterViewInit(): void {
    // Force refresh to avoi wrong size due to an ongoing animation
    this.refreshElement(0);
  }
  private refreshElement(t: number) {
    window.requestAnimationFrame(() => {
      this.manualRefresh.emit();
      if (t < 50) {
        this.refreshElement(++t);
      }
    });
  }

  // From ControlValueAccessor interface
  writeValue(value: number) {
    if (value !== this.value) {
      this.value = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // From Validator interface
  override validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    validators.push(Validators.max(this.max));
    validators.push(Validators.min(this.min));
    validators.push(validatorModule(this.step));
    if (this.validateFn) {
      validators.push(this.validateFn);
    }
    if (validators.length) {
      const validator = Validators.compose(validators);
      return validator ? validator(control) : null;
    }
    return null;
  };
}
