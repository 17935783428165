<emma-modal (close)="onCloseButton()" size="md" [noFooter]="false">
    <ng-container class="emma-modal-title">
        <i class="la la-info-circle" aria-hidden="true"></i>
        <span i18n>Contacta con nosotros</span>
    </ng-container>
    <ng-container class="emma-modal-body">
        <p i18n>Tu plan está listo. Ponte en contacto con nosotros para activarlo.</p>
        <form #form="ngForm">
            <div emma-label label="Nombre de tu empresa" i18n-label>
                <emma-input
                    name="company_name"
                    [autofocus]="false"
                    [required]="true"
                    [(ngModel)]="companyName"
                ></emma-input>
            </div>
            <div emma-label label="Email de trabajo" i18n-label>
                <emma-input
                    name="company_email"
                    type="email"
                    inputmode="email"
                    [autofocus]="false"
                    [required]="true"
                    [(ngModel)]="workMail"
                ></emma-input>
            </div>
            <div emma-label label="Teléfono" i18n-label>
                <emma-input
                    inputmode="number"
                    name="phone_number"
                    [autofocus]="false"
                    [required]="true"
                    pattern="[0-9]{9}"
                    [(ngModel)]="phoneNumber"
                ></emma-input>
            </div>
            <div emma-label label="Tamaño de la empresa" i18n-label>
                <emma-select
                    [items]="companySizeOpt"
                    name="company_size"
                    [multiple]="false"
                    [clearable]="false"
                    [showChips]="false"
                    [required]="true"
                    [(ngModel)]="companySize"
                ></emma-select>
            </div>
            <div emma-label hiddenLabel="false" label="Privacidad" i18n-label>
                <div class="m--margin-top-15">
                    <emma-switch [(ngModel)]="privacySwitch" name="privacy" [required]="true">
                        <ng-template emma-switch-label-tmp>
                            <label>
                                <span i18n>Acepto recibir comunicaciones comerciales por parte de EMMA</span>
                                <emma-info-icon
                                    class="m--margin-left-5 m--margin-right-5"
                                    [helpLinkLabel]="helpLinkLabel"
                                    [helpLink]="helpLink"
                                    [helpText]="helpText"
                                ></emma-info-icon>
                                <a
                                    class="m-link"
                                    href="https://emma.io/politica-de-privacidad/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    i18n
                                >
                                    conforme a la política de privacidad
                                </a>
                            </label>
                        </ng-template>
                    </emma-switch>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container class="emma-modal-footer">
        <button emmaButton color="cancel" (click)="onCloseButton()" i18n>Cancelar</button>
        <button
            emmaButton
            color="brand"
            [isWide]="true"
            (click)="onConfirmButton()"
            [disabled]="!canSend()"
            i18n
        >
            Hablemos
        </button>
    </ng-container>
</emma-modal>
