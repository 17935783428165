<div
    [ngClass]="{
        'd-flex gap-2 align-items-center justify-content-center': true,
        'flex-column': showInColumn
    }"
>
    <span i18n>¿Necesitas ayuda?</span>
    <button
        emmaButton
        class="m-btn--icon-circled-skudolightkblue"
        [color]="'metal'"
        [isCircle]="true"
        [hasIcon]="true"
        [circledIcon]="true"
        [showHighlight]="showHighlight"
        sizeButton="sm"
        (click)="onShowModal(videoYoutube)"
    >
        <span>
            <i class="lab la-youtube" aria-hidden="true"></i>
            <span i18n>Ver videotutorial</span>
        </span>
    </button>
</div>
<emma-video-embed-modal
    *ngIf="showModal"
    ($close)="onCloseModal()"
    [title]="modalTitle"
    [youtubeId]="youtubeId"
>
</emma-video-embed-modal>