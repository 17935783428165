import { Injectable } from '@angular/core';
import { setGlobalLoading } from '@emma-helpers/legacy-jquery-utils.helper';
import { EMMA_API_VERSION, EMMAUserAppInfo } from 'emma-common-ts/emma';
import { get } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { LOCAL_STORAGE_KEY, LocalStorageService } from './local-storage.service';

export interface ChangeAppOptions {
  canReload: boolean;
  shouldKeepPath: boolean;
}

@Injectable({ providedIn: 'root' })
export class CurrentAppService {
  $currentApp: BehaviorSubject<EMMAUserAppInfo | undefined>;

  constructor(private localStorageService: LocalStorageService) {
    const currentApp = this.localStorageService.get(LOCAL_STORAGE_KEY.CURRRENT_APP);
    this.$currentApp = new BehaviorSubject(currentApp);
  }

  /**
   * Guarda la configuración actual de la app en el LocalStorage
   * @param currentApp objecto de la app a serializar
   */
  changeCurrentApp(currentApp: EMMAUserAppInfo, options?: Partial<ChangeAppOptions>): void {
    const canReload = options?.canReload ?? true;
    const shouldKeepPath = options?.shouldKeepPath ?? false;

    if (currentApp.appConfig.apiVersion === EMMA_API_VERSION.V10) {
      console.info('Legacy App');
    }
    const previousAppId = get(this.$currentApp, 'value.appConfig.appId');
    const currentAppId = currentApp.appConfig.appId;
    if (canReload && previousAppId && currentAppId && currentAppId !== previousAppId) {
      setGlobalLoading(true);
      // Permitimos feedback antes de ponerse a cargar
      setTimeout(() => {
        this.localStorageService.set(LOCAL_STORAGE_KEY.CURRRENT_APP, currentApp);
        if (shouldKeepPath) {
          window.location.reload();
        } else {
          // Manda a index para evitar errores de datos que no existen al cambiar de app
          window.location.pathname = '/';
        }
      }, 500);
    } else {
      this.localStorageService.set(LOCAL_STORAGE_KEY.CURRRENT_APP, currentApp);
      this.$currentApp.next(currentApp);
    }
  }

  /**
   * Recupera los datos guardados de la app en el LocalStorage
   * @returns {any} Representación json de los datos de la app
   */
  getCurrentApp(): EMMAUserAppInfo | undefined {
    return this.$currentApp.getValue();
  }
}
