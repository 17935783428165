<div *ngIf="announcements.length" class="announceEmma-container errorEmma m--margin-top-40">
    <div class="announceEmma-image">
        <img class="svgImage" src="assets/media/emma/img/icon/unicornIcon.svg" alt="Unicornio" i18n-alt />
        <p class="errorEmma-image-exclamation">
            <span>Wait...</span>
            <span>what?!</span>
        </p>
    </div>
    <div class="announceEmma-message">
        <div class="announceEmma-message__title-container">
            <h5>
                <span class="announceEmma-message__title" i18n>“Vaya, un error es algo muy raro de ver”</span>
                <span class="announceEmma-message__author" i18n>dijo el mitológico Unicornio</span>
            </h5>
        </div>
        <p>
            <span i18n>No te preocupes,</span>
            <a class="m-link" href="mailto:soporte@emma.io" target="_blank" rel="noopener noreferrer" i18n>
                contacta con soporte
            </a>
            <span class="m--font-bolder" i18n>copiando el siguiente mensaje de error</span>
            <span i18n>
                y haremos un concienzudo análisis de lo que ha podido pasar mientras los informáticos gritan y
                corren en círculos.
            </span>
        </p>
        <span *ngFor="let announcement of announcements" class="announceEmma-message__error m--font-danger">
            {{ announcement }}
            <div *ngIf="announcement.dismissable" class="m-alert__actions" style="width: 220px">
                <button
                    emmaButton
                    color="brand"
                    sizeButton="sm"
                    class="m--margin-left-10"
                    (click)="onDismiss(announcement)"
                    i18n
                >
                    Cerrar
                </button>
            </div>
        </span>
    </div>
</div>
