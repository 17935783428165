/* eslint-disable class-methods-use-this */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'script-injector-message-receiver',
  template: '',
})
export class EMMAScriptInjectorMessageReceiverComponent implements OnInit {
  static inject(): void {
    const script = document.createElement('script');
    script.src = '/assets/scripts/message-receiver.js';
    document.body.appendChild(script);
  }

  ngOnInit(): void {
    EMMAScriptInjectorMessageReceiverComponent.inject();
  }
}
