import { Component, OnInit } from '@angular/core';
import { PlanService } from '@emma-services/plan.service';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { Plan, PlanContactMail } from 'emma-common-ts/emma';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'configurator',
  templateUrl: './configurator.component.html',
})
export class ConfiguratorPageComponent extends OnUnsubscribe implements OnInit {
  loading = new Set<string>();
  plans: Array<Plan> = [];

  constructor(
    private planService: PlanService,
    public notifications: ToastNotificationsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading.add('plans');
    this.planService
      .getAll()
      .pipe(apiFeedback('plans', undefined, this.loading), takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        const data = (response && response.data) || [];
        this.plans = data.filter((plan) => !plan.deprecated);
      });
  }

  onContactPublic(contact: PlanContactMail) {
    this.loading.add('contact');
    this.planService
      .planContact(contact)
      .pipe(apiFeedback('contact', undefined, this.loading), takeUntil(this.ngUnsubscribe));
  }
}
