import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@platform/providers/window.provider';

interface PageView {
  event: 'page-view';
  pageName: string;
}

interface GtmWindow extends Window {
  dataLayer?: unknown[];
}

@Injectable({
  providedIn: 'root',
})
export class GtmDataLayerService {
  constructor(@Inject(WINDOW) private window: GtmWindow) {}

  logPageView(url: string) {
    const hit: PageView = {
      event: 'page-view',
      pageName: url,
    };
    this.window?.dataLayer?.push(hit);
  }
}
