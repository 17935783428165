import { Component, Input } from '@angular/core';
import { EMMAUserAppInfo, PERMISSION_ID, ROUTE, User } from 'emma-common-ts/emma';

@Component({
  selector: 'emma-user-menu',
  templateUrl: './emma-user-menu.component.html',
})
export class EMMAUserMenuComponent {
  @Input() user!: User;
  @Input() currentApp!: EMMAUserAppInfo;

  ROUTE = ROUTE;
  PERMISSIONS = PERMISSION_ID;
}
