import { Injectable } from '@angular/core';
import { ApiChangeResponse } from 'emma-common-ts';
import {
  extractPermissionId,
  extractPermissionLevel,
  getPermissionName,
  PermissionFlag,
  PERMISSION_BOOL_OP,
  PERMISSION_ID,
  PERMISSION_LEVEL,
  UserRole,
  UserRolesResponse,
} from 'emma-common-ts/emma';
import { Observable } from 'rxjs';

import { EmmaApiService } from './api.service';
import { CurrentAppService } from './current-app.service';

@Injectable({ providedIn: 'root' })
export class PermissionFlagsService {
  static generatePermissionName = getPermissionName;
  static extractPermissionId = extractPermissionId;
  static extractPermissionLevel = extractPermissionLevel;

  private disallowed?: Array<PermissionFlag>;

  constructor(private currentAppService: CurrentAppService, private apiService: EmmaApiService) {
    const currentApp = this.currentAppService.getCurrentApp();
    this.disallowed = currentApp?.disallowed;

    this.currentAppService.$currentApp.subscribe((app) => {
      this.disallowed = app?.disallowed;
    });
  }

  can = (
    permissionFlag: PermissionFlag | Array<PermissionFlag>,
    operator = PERMISSION_BOOL_OP.AND
  ): boolean => {
    if (!this.disallowed?.length) {
      return true;
    }
    if ('string' === typeof permissionFlag) {
      return !this.disallowed.includes(permissionFlag);
    } else {
      const { disallowed } = this;
      switch (operator) {
        case PERMISSION_BOOL_OP.OR:
          return !permissionFlag.some((p) => disallowed.includes(p));
        case PERMISSION_BOOL_OP.XOR: {
          let canDo = true;
          for (const p of permissionFlag) {
            if (disallowed.includes(p)) {
              if (canDo) {
                return true;
              }
              canDo = false;
            }
          }
          return canDo;
        }
        case PERMISSION_BOOL_OP.AND:
        default:
          return !permissionFlag.some((p) => !disallowed.includes(p));
      }
    }
  };

  canRead = (permissionId: PERMISSION_ID | Array<PERMISSION_ID>, operator?: PERMISSION_BOOL_OP): boolean => {
    if (Array.isArray(permissionId)) {
      return this.can(
        permissionId.map((p) => `${p}__${PERMISSION_LEVEL.READ}`),
        operator
      );
    } else {
      return this.can(`${permissionId}__${PERMISSION_LEVEL.READ}`);
    }
  };

  canWrite = (permissionId: PERMISSION_ID | Array<PERMISSION_ID>, operator?: PERMISSION_BOOL_OP): boolean => {
    if (Array.isArray(permissionId)) {
      return this.can(
        permissionId.map((p) => `${p}__${PERMISSION_LEVEL.WRITE}`),
        operator
      );
    } else {
      return this.can(`${permissionId}__${PERMISSION_LEVEL.WRITE}`);
    }
  };

  canReadWrite = (
    permissionId: PERMISSION_ID | Array<PERMISSION_ID>,
    operator?: PERMISSION_BOOL_OP
  ): boolean => {
    return this.canRead(permissionId, operator) && this.canWrite(permissionId, operator);
  };

  // Services
  getDisallowed = () => this.disallowed && this.disallowed.slice(0);

  // getAvailableFlags = () =>
  //   this.apiService.getOnceApi<IUserPermissionFlagsResponse>(
  //     '/permissionFlags'
  //   );

  getRoles = (): Observable<UserRolesResponse | null> =>
    this.apiService.get<UserRolesResponse>('/user-roles');
  saveRole = (userRole: UserRole): Observable<ApiChangeResponse> => {
    // De momento sólo usamos el PUT. En el caso de necesitar crear, tenemos el POST disponible
    return this.apiService.put(`/user-roles/${userRole.id}`, userRole);
  };
  removeRole = (id: string): Observable<ApiChangeResponse> => this.apiService.delete(`/user-roles/${id}`);
}
