import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface MetaData {
  ttl: number;
  forced: boolean;
  _last: number;
  id: string;
  [k: string]: any;
}

export class SmartStore<T> {
  protected debugMode = false;
  /** Identifier of the last request */
  meta!: Partial<MetaData>;
  /** Set the meta data of the next request */
  nextMeta?: Partial<MetaData>;

  /** Emit the data */
  $emitter: BehaviorSubject<T | null> | undefined;
  $loadingEnds = new EventEmitter<void>();
  $loadingStarts = new EventEmitter<void>();

  constructor(public name: string, meta: Partial<MetaData> = {}) {
    this.setMeta({ id: name, ...meta });
  }

  setDebugMode(dbg: boolean): void {
    this.debugMode = dbg;
    if (dbg) {
      console.log('SmartStore', this.name, 'debug mode');
    }
  }

  get = (): BehaviorSubject<T | null> => {
    if (!this.$emitter) {
      this.$emitter = new BehaviorSubject<T | null>(null);
    }
    return this.$emitter;
  };

  update = (data: T): BehaviorSubject<T | null> => {
    if (!this.nextMeta) {
      throw new Error('No meta data set for update');
    }
    this.meta._last = Date.now();
    this.setMeta(this.nextMeta);
    this.get().next(data);
    this.$loadingEnds.next();
    return this.get();
  };

  /** Set the meta data of the incoming data */
  willUpdate = (nextMeta: Partial<MetaData>): void => {
    this.nextMeta = nextMeta;
    this.get().next(null);
    this.$loadingStarts.next();
  };

  /** Check if the data should be updated */
  shouldUpdate = (requestMeta: Partial<MetaData>): boolean => {
    if (!this.meta) {
      return true;
    }
    const { ttl: requestTtl = 0, id: requestId } = { ...this.meta, ...requestMeta };
    const { _last, id: currentId } = this.meta;
    const nextId = this.nextMeta?.id;
    // If forced or never updated or ttl is zero
    if (requestMeta.forced || !_last || !requestTtl) {
      return true;
    }
    // There is new data incoming
    if (nextId) {
      // And it does not match the request
      if (nextId !== currentId) {
        return true;
      }
    } else if (requestId !== currentId) {
      return true;
    }
    // Data is too old
    return Date.now() - _last > requestTtl;
  };

  isLoading = (): boolean => Boolean(this.nextMeta);

  // META
  getMeta = (): Partial<MetaData> => ({ ...this.meta });

  setMeta = (meta: Partial<MetaData>): void => {
    this.meta = { ttl: 1000, _last: 0, ...meta };
    this.nextMeta = undefined;
  };

  error = (error: HttpErrorResponse | Error): void => {
    if (this.isLoading()) {
      this.nextMeta = undefined;
      this.$loadingEnds.next();
    }
    this.get().next({ error } as any);
  };
}
