import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BackToLoginInfo,
  BackToLoginService as PlatformBackToLoginService,
} from '@platform/services/back-to-login.service';
import { ROUTE } from 'emma-common-ts/emma';
import { EmbedService } from './embed.service';

@Injectable({ providedIn: 'root' })
export class BackToLoginService extends PlatformBackToLoginService {
  constructor(
    router: Router,
    private embedService: EmbedService
  ) {
    super(router, [ROUTE.LOGIN, ROUTE.AUTO_LOGIN]);
  }

  override backToLogin(info: BackToLoginInfo) {
    const isEmbedMode = this.embedService.getEmbedMode();
    return super.backToLogin({ ...info, addEmbedParam: isEmbedMode });
  }
}
