/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'emma-script-injector-google-sign-in',
  template: '',
})
export class EMMAScriptInjectorGoogleSignInComponent implements OnInit {
  static inject(): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);
  }

  ngOnInit(): void {
    setTimeout(() => EMMAScriptInjectorGoogleSignInComponent.inject(), 100);
  }
}
