import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@platform/services/authentication.service';
import { ROUTE } from 'emma-common-ts/emma';
import { takeUntil } from 'rxjs/operators';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';

@Component({
  selector: 'emma-logout',
  template: '',
})
export class LogoutComponent extends OnUnsubscribe implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.authService
      .logoutReport()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.authService.logout();
        this.router.navigateByUrl(ROUTE.ROOT).catch(console.error);
      });
  }
}
