import { ActivatedRouteSnapshot } from '@angular/router';
import { EMBED_URL_PARAM_NAME, EMBED_MODE_STORAGE_KEY } from '@platform/constants/embed.constants';
import { LocalStorageService } from './local-storage.service';

export class EmbedService {
  constructor(protected localStorageService: LocalStorageService) {}

  checkEmbedMode(route: ActivatedRouteSnapshot): void {
    const embedParam = route.queryParams[EMBED_URL_PARAM_NAME];
    if (embedParam) {
      this.setEmbedMode();
    } else {
      this.clearEmbedMode();
    }
  }

  setEmbedMode(): void {
    this.localStorageService.set(EMBED_MODE_STORAGE_KEY, true);
  }
  clearEmbedMode(): void {
    this.localStorageService.set(EMBED_MODE_STORAGE_KEY, false);
  }

  getEmbedMode(): boolean {
    return Boolean(this.localStorageService.get(EMBED_MODE_STORAGE_KEY));
  }
}
