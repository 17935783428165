<emma-contact-plan-configurator-modal
    *ngIf="contactModal"
    ($close)="onCloseModal()"
    ($confirmation)="onContactModalConfirmation($event)"
></emma-contact-plan-configurator-modal>
<emma-portlet
    [portletTitle]="editTitle"
    [sticky]="!configuratorStandalone"
    [hasForm]="true"
    hasSidebar="true"
    [isLoading]="loading"
    [ngClass]="{
        'emma-plan-generator--standalone': configuratorStandalone
    }"
>
    <div *ngIf="!configuratorStandalone" class="emma-portlet-head-tools">
        <button
            *ngIf="!isEdit"
            emmaButton
            color="brand"
            [isWide]="false"
            [hasIcon]="false"
            (click)="onEdit()"
            [loading]="loading"
        >
            <span i18n>Editar plan</span>
        </button>
        <button *ngIf="isEdit" emmaButton class="m--margin-right-10" color="cancel" (click)="onCancel()" i18n>
            Cancelar
        </button>

        <button
            *ngIf="isEdit"
            emmaButton
            color="brand"
            [isWide]="false"
            [hasIcon]="false"
            (click)="onSave()"
            [disabled]="
                currentPlanTypeSelected === PLAN_TYPE.MASTER ||
                (!accountPlanConfiguration.adquisitionAdquired &&
                    !accountPlanConfiguration.retentionAdquired) ||
                isHighVolumeAdquisition ||
                isHighVolumeRetention ||
                !canSave() ||
                isDowngrade
            "
            [loading]="loading"
        >
            <span i18n>Cambiar plan</span>
        </button>
    </div>
    <form #form="ngForm">
        <emma-form-block
            *ngIf="!configuratorStandalone"
            headTitle="Planes"
            description="Diseña tu plan perfecto."
            [isFirst]="true"
            i18n-headTitle
            i18n-description
        >
            <div emma-label label="Elige tu plan" i18n-label>
                <emma-select
                    [items]="planTypes"
                    name="planType"
                    [(ngModel)]="currentPlanTypeSelected"
                    [multiple]="false"
                    [clearable]="false"
                    [showChips]="false"
                    [disabled]="!isEdit"
                    ($change)="onSelectedPlanChanged($event)"
                ></emma-select>
            </div>
        </emma-form-block>
        <emma-form-block
            headTitle="Adquisición"
            description="Mide instalaciones mensuales no-orgánicas en todas tus fuentes de medios."
            [isFirst]="configuratorStandalone"
            i18n-headTitle
            i18n-description
        >
            <div
                *ngIf="currentPlanTypeSelected !== PLAN_TYPE.PADAWAN"
                emma-label
                label="Volumen de instalaciones no-orgánicas"
                helpText="Instalaciones mensuales no orgánicas en todas tus fuentes de medios."
                i18n-label
                i18n-helpText
                [@fadeInOutEnter]
            >
                <div class="input-group m--margin-top-15 m--margin-bottom-15">
                    <div class="input-group-slider">
                        <emma-slider
                            name="adquisitionSlider"
                            [min]="0"
                            [max]="maxAquisitionSlider"
                            [step]="currentPlanConfiguration.adquisitionStep"
                            [showTicks]="true"
                            [showTicksValues]="false"
                            [hideLimitLabels]="true"
                            [tickStep]="currentPlanConfiguration.adquisitionStep"
                            [required]="true"
                            [disabled]="
                                currentPlanTypeSelected !== PLAN_TYPE.KNIGHT ||
                                (!isEdit && !configuratorStandalone)
                            "
                            (valueChange)="onChangeAdqSlider($event)"
                            [value]="accountPlanConfiguration.adquisitionAdquiredTotal"
                        ></emma-slider>
                    </div>
                    <div class="valueSliderPlan input-group-slider-sibling">
                        <label>
                            <strong>
                                {{ adquisitionSlider }}
                                <span i18n>instalaciones</span>
                            </strong>
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="currentPlanTypeSelected === PLAN_TYPE.PADAWAN" [@fadeInOutEnter]>
                <span class="font-weight-normal m--regular-font-size-" i18n>
                    Instalación no-orgánica:
                    {{ defaultCostAdquisition | formatNumber: '{"style": "currency", "currency": "EUR"}' }}
                </span>
            </div>
        </emma-form-block>
        <emma-form-block
            headTitle="Retención"
            description="Envía notificaciones push y mensajes In-App sin límite a tus usuarios activos mensuales."
            [isFirst]="false"
            i18n-headTitle
            i18n-description
        >
            <div
                *ngIf="currentPlanTypeSelected !== PLAN_TYPE.PADAWAN"
                emma-label
                label="Volumen de usuarios activos"
                helpText="Usuarios activos mensuales de tu app que pueden recibir notificaciones push y mensajes In-App."
                i18n-label
                i18n-helpText
                [@fadeInOutEnter]
            >
                <div class="input-group m--margin-top-15 m--margin-bottom-15">
                    <div class="input-group-slider">
                        <emma-slider
                            name="retentionSlider"
                            [min]="0"
                            [max]="maxRetentionSlider"
                            [step]="currentPlanConfiguration.retententionStep"
                            [showTicks]="true"
                            [showTicksValues]="false"
                            [hideLimitLabels]="true"
                            [tickStep]="currentPlanConfiguration.retententionStep"
                            [required]="true"
                            [disabled]="
                                currentPlanTypeSelected !== PLAN_TYPE.KNIGHT ||
                                (!isEdit && !configuratorStandalone)
                            "
                            (valueChange)="onChangeRetSlider($event)"
                            [value]="accountPlanConfiguration.retentionAdquiredTotal"
                        ></emma-slider>
                    </div>
                    <div class="valueSliderPlan input-group-slider-sibling">
                        <label>
                            <strong>
                                {{ retentionSlider }}
                                <span i18n>usuarios</span>
                            </strong>
                        </label>
                    </div>
                </div>
            </div>
            <div
                *ngIf="currentPlanTypeSelected === PLAN_TYPE.PADAWAN"
                emma-label
                label="Usuario activo: {{ defaultCostRetention | formatNumber: '{\"style\": \"currency\", \"currency\": \"EUR\"}' }}"
                i18n-label
                [@fadeInOutEnter]
            >
                <emma-switch
                    name="retention"
                    [(ngModel)]="accountPlanConfiguration.retentionAdquired"
                    [disabled]="
                        currentPlanTypeSelected !== PLAN_TYPE.PADAWAN ||
                        !isEdit ||
                        accountPlan?.configuration.retentionAdquired
                    "
                    label="¿Activar retención?"
                    i18n-label
                ></emma-switch>
            </div>
        </emma-form-block>
        <emma-form-block
            *ngIf="currentPlanTypeSelected !== PLAN_TYPE.PADAWAN"
            headTitle="Funcionalidades Premium"
            description="Te dan acceso a más funciones de EMMA y su precio es siempre un porcentaje de tu plan base. Solo disponible con planes KNIGHT y MASTER."
            [isFirst]="false"
            i18n-headTitle
            i18n-description
            [@fadeScaleInOut]
        >
            <div
                emma-label
                label="Automatización"
                helpText="Crea <i>rutas de cliente</i> creando reglas para gestionar todas tus comunicaciones; enviar Notificaciones Push, SMS, Email o Mensajes In-App cuando el usuario realice determinadas acciones. Solo disponible con Retención."
                i18n-label
                i18n-helpText
            >
                <emma-switch
                    name="automation"
                    [disabled]="
                        currentPlanTypeSelected !== PLAN_TYPE.KNIGHT ||
                        accountPlanConfiguration.retentionAdquiredTotal === 0 ||
                        (!isEdit && !configuratorStandalone)
                    "
                    [(ngModel)]="accountPlanConfiguration.automationAdquired"
                    (checkedChange)="onChangeFeaturesBilling()"
                >
                    <ng-template emma-switch-label-tmp *ngIf="currentPlanTypeSelected !== PLAN_TYPE.MASTER">
                        <label i18n>
                            (Coste del {{ currentPlanConfiguration.automationPercent }}% del plan)
                        </label>
                    </ng-template>
                </emma-switch>
            </div>
            <div
                emma-label
                label="API"
                helpText="Sincroniza todos tus sistemas con nuestra API. Enriquece tus datos desde fuentes externas y comparte el comportamiento de tus usuarios. Lanza comunicaciones de forma remota y crea informes de tus campañas de forma automática."
                i18n-label
                i18n-helpText
            >
                <emma-switch
                    name="api"
                    [disabled]="
                        currentPlanTypeSelected !== PLAN_TYPE.KNIGHT ||
                        (accountPlanConfiguration.adquisitionAdquiredTotal === 0 &&
                            accountPlanConfiguration.retentionAdquiredTotal === 0) ||
                        (!isEdit && !configuratorStandalone)
                    "
                    [(ngModel)]="accountPlanConfiguration.apiAdquired"
                    (checkedChange)="onChangeFeaturesBilling()"
                >
                    <ng-template emma-switch-label-tmp *ngIf="currentPlanTypeSelected !== PLAN_TYPE.MASTER">
                        <label i18n>(Coste del {{ currentPlanConfiguration.apiPercent }}% del plan)</label>
                    </ng-template>
                </emma-switch>
            </div>
            <div class="m--margin-top-15">
                <div
                    *ngIf="
                        (!accountPlanConfiguration.automationAdquired ||
                            !accountPlanConfiguration.apiAdquired) &&
                        currentPlanTypeSelected !== PLAN_TYPE.MASTER
                    "
                    class="m-alert m-alert--outline m-alert--icon m-alert--square alert alert-emmadarkblue"
                    role="alert"
                    [@fadeInOutEnter]
                >
                    <div class="m-alert__icon">
                        <i class="flaticon-piggy-bank" aria-hidden="true"></i>
                    </div>
                    <div class="m-alert__text">
                        <h5 i18n>
                            ¡Ahorra un {{ currentPlanConfiguration.discountPremiumPercent }}% contratando un
                            pack premium!
                        </h5>
                        <span>
                            <span i18n>Contrata el pack "Automatización + API" y</span>
                            <strong i18n>
                                obtén un {{ currentPlanConfiguration.discountPremiumPercent }}% de descuento.
                            </strong>
                        </span>
                    </div>
                </div>
                <div
                    *ngIf="
                        accountPlanConfiguration.automationAdquired &&
                        accountPlanConfiguration.apiAdquired &&
                        currentPlanTypeSelected !== PLAN_TYPE.MASTER
                    "
                    class="m-alert m-alert--outline m-alert--icon m-alert--square alert alert-brand"
                    role="alert"
                    [@fadeInOutEnter]
                >
                    <div class="m-alert__icon">
                        <i class="flaticon-coins" aria-hidden="true"></i>
                    </div>
                    <div class="m-alert__text">
                        <h5 i18n>¡Has elegido el pack Automatización + API!</h5>
                        <span *ngIf="currentPlanConfiguration.discountAnnualPaymentPercent > 0">
                            <span i18n>Disfruta de las dos funcionalidades premium por</span>
                            <strong i18n>
                                {{
                                    currentPlanConfiguration.apiPercent +
                                        currentPlanConfiguration.automationPercent -
                                        currentPlanConfiguration.discountPremiumPercent
                                }}% del plan (en lugar del
                                {{
                                    currentPlanConfiguration.apiPercent +
                                        currentPlanConfiguration.automationPercent
                                }}%).
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </emma-form-block>
        <emma-form-block
            *ngIf="currentPlanTypeSelected !== PLAN_TYPE.PADAWAN"
            headTitle="Modelo de pago"
            description="Mensual o anual, escoge el que mejor se adapte a tu empresa."
            [isFirst]="false"
            i18n-headTitle
            i18n-description
            [@fadeScaleInOut]
        >
            <div emma-label label="¿Pago anual?" i18n-label>
                <emma-switch
                    name="payModel"
                    [disabled]="
                        currentPlanTypeSelected !== PLAN_TYPE.KNIGHT || (!isEdit && !configuratorStandalone)
                    "
                    [(ngModel)]="accountPlanConfiguration.annualPaymentActive"
                    (checkedChange)="onChangePayModel()"
                ></emma-switch>
            </div>
            <div class="m--margin-top-15">
                <div
                    *ngIf="
                        !accountPlanConfiguration.annualPaymentActive &&
                        currentPlanTypeSelected !== PLAN_TYPE.MASTER
                    "
                    class="m-alert m-alert--outline m-alert--icon m-alert--square alert alert-emmadarkblue"
                    role="alert"
                    [@fadeInOutEnter]
                >
                    <div class="m-alert__icon">
                        <i class="flaticon-piggy-bank" aria-hidden="true"></i>
                    </div>
                    <div class="m-alert__text">
                        <h5 i18n>
                            ¡Ahorra un {{ currentPlanConfiguration.discountAnnualPaymentPercent }}% con pago
                            anual!
                        </h5>
                        <span>
                            <span i18n>Pagando anualmente tendrás un ahorro del</span>
                            <strong i18n>
                                {{ currentPlanConfiguration.discountAnnualPaymentPercent }}% en el coste de tu
                                plan.
                            </strong>
                        </span>
                    </div>
                </div>
                <div
                    *ngIf="
                        accountPlanConfiguration.annualPaymentActive &&
                        currentPlanTypeSelected !== PLAN_TYPE.MASTER
                    "
                    class="m-alert m-alert--outline m-alert--icon m-alert--square alert alert-brand"
                    role="alert"
                    [@fadeInOutEnter]
                >
                    <div class="m-alert__icon">
                        <i class="flaticon-coins" aria-hidden="true"></i>
                    </div>
                    <div class="m-alert__text">
                        <h5 i18n>¡Has elegido el pago anual!</h5>
                        <span>
                            <span i18n>Disfruta de EMMA con un</span>
                            <strong i18n>
                                ahorro del {{ currentPlanConfiguration.discountAnnualPaymentPercent }}% en el
                                coste de tu plan.
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </emma-form-block>
    </form>
    <div class="m-form--sidebar-content">
        <div class="m-form--sidebar-content_sticky">
            <div class="m-form__heading" *ngIf="isDowngrade" [@fadeInOutEnter]>
                <h3 class="m-form__heading-title" i18n>Reducir plan</h3>
                <div class="m-form__contextual">
                    <p i18n>¿Quieres ajustar tu plan? Contacta con ventas para gestionar tu solicitud.</p>
                </div>
                <div class="m-form__contextual m--margin-top-15">
                    <button
                        emmaButton
                        color="brand"
                        [isWide]="false"
                        [hasIcon]="false"
                        [isBlock]="true"
                        [loading]="loading"
                        (click)="onContact(CONTACT_MAIL.DOWNGRADE_PLAN)"
                    >
                        <span i18n>Contacta con ventas</span>
                    </button>
                </div>
            </div>
            <div
                class="m-form__heading"
                *ngIf="currentPlanTypeSelected === PLAN_TYPE.PADAWAN && !isDowngrade"
                [@fadeInOutEnter]
            >
                <h3 class="m-form__heading-title" i18n>Plan Padawan</h3>
                <div class="m-form__contextual">
                    <p i18n>El plan sin coste mensual te permite pagar en función de volumen.</p>
                    <p>
                        <span i18n>Actualiza tu plan a Knight y disfruta de</span>
                        <strong i18n>Funcionalidades Premium como Automatización y API.</strong>
                    </p>
                </div>
                <div class="m-form__contextual font-italic">
                    <span i18n>Instalación no-orgánica: </span>
                    {{ extraCostAdquisition | formatNumber: '{"style": "currency", "currency": "EUR"}' }}
                    <br />
                    <span i18n>Usuario activo mensual: </span>
                    {{ extraCostRetention | formatNumber: '{"style": "currency", "currency": "EUR"}' }}
                </div>
            </div>
            <div
                class="m-form__heading"
                *ngIf="
                    currentPlanTypeSelected === PLAN_TYPE.KNIGHT &&
                    !(
                        accountPlanConfiguration.adquisitionAdquiredTotal <= 0 &&
                        accountPlanConfiguration.retentionAdquiredTotal <= 0
                    ) &&
                    !(isHighVolumeAdquisition || isHighVolumeRetention) &&
                    !isDowngrade
                "
                [@fadeInOutEnter]
            >
                <h3 class="m-form__heading-title" i18n>Facturación</h3>
                <div class="m-form__contextual">
                    <p i18n>Adapta el plan a tus necesidades.</p>
                </div>
                <div class="m-form--sidebar-row m--padding-top-20">
                    <span class="labelRow">Adquisición</span>
                    <strong class="priceRow">
                        {{
                            adquisitionBillingTotal
                                | roundNumber
                                | formatNumber: '{"style": "currency", "currency": "EUR"}'
                        }}
                    </strong>
                </div>
                <div class="m-form--sidebar-row m-form--sidebar-row_last m--padding-bottom-20">
                    <span class="labelRow" i18n>Retención</span>
                    <strong class="priceRow">
                        {{
                            retentionBillingTotal
                                | roundNumber
                                | formatNumber: '{"style": "currency", "currency": "EUR"}'
                        }}
                    </strong>
                </div>
                <div
                    *ngIf="
                        accountPlanConfiguration.automationAdquired && !accountPlanConfiguration.apiAdquired
                    "
                    class="m-form--sidebar-row"
                    [ngClass]="{
                        'm-form--sidebar-row_last': !accountPlanConfiguration.apiAdquired,
                        'm--padding-top-20 ': true,
                        'm--padding-bottom-20': !accountPlanConfiguration.apiAdquired
                    }"
                    [@fadeInOutEnter]
                >
                    <span class="labelRow" i18n>Automatización</span>
                    <strong class="priceRow">
                        {{
                            automationBillingTotal
                                | roundNumber
                                | formatNumber: '{"style": "currency", "currency": "EUR"}'
                        }}
                    </strong>
                </div>
                <div
                    *ngIf="
                        !accountPlanConfiguration.automationAdquired && accountPlanConfiguration.apiAdquired
                    "
                    class="m-form--sidebar-row m-form--sidebar-row_last m--padding-top-20 m--padding-bottom-20"
                    [ngClass]="{
                        'm-form--sidebar-row_last': true,
                        'm--padding-top-20 ': !accountPlanConfiguration.automationAdquired,
                        'm--padding-bottom-20': true
                    }"
                    [@fadeInOutEnter]
                >
                    <span class="labelRow" i18n>API</span>
                    <strong class="priceRow">
                        {{
                            apiBillingTotal
                                | roundNumber
                                | formatNumber: '{"style": "currency", "currency": "EUR"}'
                        }}
                    </strong>
                </div>
                <div
                    *ngIf="
                        accountPlanConfiguration.automationAdquired && accountPlanConfiguration.apiAdquired
                    "
                    class="m-form--sidebar-row m-form--sidebar-row_last m--padding-top-20 m--padding-bottom-20"
                    [@fadeInOutEnter]
                >
                    <span class="labelRow" i18n>Automatización + API</span>
                    <strong class="priceRow">
                        {{
                            featuresBillingTotal
                                | roundNumber
                                | formatNumber: '{"style": "currency", "currency": "EUR"}'
                        }}
                    </strong>
                </div>
                <div class="m-form--sidebar-row planTotal m--padding-top-20 m--padding-bottom-20">
                    <strong class="labelRow" i18n>Total</strong>
                    <span class="payModelPlan">
                        <span *ngIf="accountPlanConfiguration.annualPaymentActive" i18n>(anual)</span>
                        <span *ngIf="!accountPlanConfiguration.annualPaymentActive" i18n>(mensual)</span>
                    </span>
                    <strong class="priceRow">
                        {{
                            configuratorTotal
                                | roundNumber
                                | formatNumber: '{"style": "currency", "currency": "EUR"}'
                        }}
                    </strong>
                </div>
                <div class="m-form__contextual font-italic">
                    <span i18n>Instalación extra: </span>
                    {{ extraCostAdquisition | formatNumber: '{"style": "currency", "currency": "EUR"}' }}
                    <br />
                    <span i18n>Usuario activo mensual extra:</span>
                    {{ extraCostRetention | formatNumber: '{"style": "currency", "currency": "EUR"}' }}
                </div>
                <div *ngIf="configuratorStandalone" class="m-form__contextual m--margin-top-15">
                    <button
                        emmaButton
                        color="brand"
                        [isWide]="false"
                        [hasIcon]="false"
                        [isBlock]="true"
                        [loading]="loading"
                        (click)="onContact(CONTACT_MAIL.PUBLIC_CONFIGURATOR)"
                    >
                        <span i18n>Consúltanos sin compromiso</span>
                    </button>
                </div>
            </div>
            <div
                class="m-form__heading"
                *ngIf="
                    currentPlanTypeSelected === PLAN_TYPE.KNIGHT &&
                    accountPlanConfiguration.adquisitionAdquiredTotal <= 0 &&
                    accountPlanConfiguration.retentionAdquiredTotal <= 0 &&
                    !isDowngrade
                "
                [@fadeInOutEnter]
            >
                <h3 class="m-form__heading-title" i18n>Crea tu plan</h3>
                <div class="m-form__contextual">
                    <p i18n>Selecciona el volumen de instalaciones y usuarios para crear tu plan.</p>
                    <p i18n>¿Problemas para decidirte? Nuestro equipo te ayuda.</p>
                </div>
                <div *ngIf="!configuratorStandalone" class="m-form__contextual m--margin-top-15">
                    <button
                        emmaButton
                        color="brand"
                        [isWide]="false"
                        [hasIcon]="false"
                        [isBlock]="true"
                        [loading]="loading"
                        (click)="onContact(CONTACT_MAIL.DECISION_PROBLEMS)"
                    >
                        <span i18n>Contáctanos</span>
                    </button>
                </div>
                <div *ngIf="configuratorStandalone" class="m-form__contextual m--margin-top-15">
                    <button
                        emmaButton
                        color="brand"
                        [isWide]="false"
                        [hasIcon]="false"
                        [isBlock]="true"
                        [loading]="loading"
                        (click)="onContact(CONTACT_MAIL.PUBLIC_CONFIGURATOR)"
                    >
                        <span i18n>Consúltanos sin compromiso</span>
                    </button>
                </div>
            </div>
            <div
                class="m-form__heading"
                *ngIf="
                    currentPlanTypeSelected === PLAN_TYPE.KNIGHT &&
                    (isHighVolumeRetention || isHighVolumeAdquisition) &&
                    !isDowngrade
                "
                [@fadeInOutEnter]
            >
                <h3 class="m-form__heading-title" i18n>Plan de alto volumen</h3>
                <div class="m-form__contextual">
                    <p i18n>
                        Contacta con nosotros para contratar un plan de alto volumen. Nuestro equipo de ventas
                        puede diseñar el plan que mejor se adapte a tus objetivos.
                    </p>
                </div>
                <div *ngIf="!configuratorStandalone" class="m-form__contextual m--margin-top-15">
                    <button
                        emmaButton
                        color="brand"
                        [isWide]="false"
                        [hasIcon]="false"
                        [isBlock]="true"
                        [loading]="loading"
                        (click)="onContact(CONTACT_MAIL.HIGH_VOLUMEN)"
                    >
                        <span i18n>Contacta con ventas</span>
                    </button>
                </div>
                <div *ngIf="configuratorStandalone" class="m-form__contextual m--margin-top-15">
                    <button
                        emmaButton
                        color="brand"
                        [isWide]="false"
                        [hasIcon]="false"
                        [isBlock]="true"
                        [loading]="loading"
                        (click)="onContact(CONTACT_MAIL.PUBLIC_CONFIGURATOR)"
                    >
                        <span i18n>Consúltanos sin compromiso</span>
                    </button>
                </div>
            </div>
            <div
                class="m-form__heading"
                *ngIf="currentPlanTypeSelected === PLAN_TYPE.MASTER && !isDowngrade"
                [@fadeInOutEnter]
            >
                <h3 class="m-form__heading-title" i18n>Plan Master</h3>
                <div class="m-form__contextual">
                    <p i18n>
                        Contacta con nosotros para contratar un plan Master. Nuestro equipo de ventas puede
                        diseñar el plan que mejor se adapte a tus objetivos.
                    </p>
                </div>
                <div class="m-form__contextual m--margin-top-15">
                    <button
                        emmaButton
                        color="brand"
                        [isWide]="false"
                        [hasIcon]="false"
                        [isBlock]="true"
                        [loading]="loading"
                        (click)="onContact(CONTACT_MAIL.PLAN_MASTER)"
                    >
                        <span i18n>Contacta con ventas</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</emma-portlet>
