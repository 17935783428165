import { Injectable } from '@angular/core';
import { getErrorText, messageToNotification } from '@emma-helpers/emma-utils';
import { BannerNotification, NotificationIcon, NotificationLevel } from '../types/notification.model';

import { noop } from 'lodash';

import { UserMetaService } from './user-meta.service';

const announcementsMap: Map<string, BannerNotification> = new Map();

const parseNotification = (message: unknown) =>
  message instanceof BannerNotification ? message : messageToNotification(getErrorText(message));

@Injectable({ providedIn: 'root' })
export class BannerNotificationsService {
  notificationsMap: Map<string, BannerNotification> = new Map();

  notifications: BannerNotification[] = [];
  announcements: BannerNotification[] = [];
  errors: string[] = [];
  errorsString = '';
  timers = new Map<string, NodeJS.Timeout | string | number | undefined>();

  constructor(public userMetaService: UserMetaService) {}

  private update() {
    this.announcements = Array.from(announcementsMap.values()).map(parseNotification);
    this.notifications = Array.from(this.notificationsMap.values()).map(parseNotification);
    this.errors = this.notifications.filter((n) => n.level === NotificationLevel.ERROR).map((n) => n.message);
    this.errorsString = this.errors.join(' / ');
  }

  private _set(notification: BannerNotification, announcement = false): void {
    const _map = announcement ? announcementsMap : this.notificationsMap;
    _map.set(notification.key, notification);
    if (notification.dismissable && notification.level !== NotificationLevel.ERROR) {
      this.setAutodismissTimer(notification.key, announcement);
    }
    this.update();
  }

  set(key: string, notification?: BannerNotification, announcement = false): void {
    if (!notification?.message) {
      this.delete(key, announcement);
    } else if (notification.dontShowMore) {
      this.userMetaService.getNotificationDontShowMoreState(key).subscribe((isDontShowMore) => {
        if (!isDontShowMore) {
          this._set(notification, announcement);
        }
      });
    } else {
      this._set(notification, announcement);
    }
  }

  get(key: string, announcement = false): BannerNotification | undefined {
    const _map = announcement ? announcementsMap : this.notificationsMap;
    return _map.get(key);
  }

  delete(key: string, announcement = false): void {
    const _map = announcement ? announcementsMap : this.notificationsMap;
    _map.delete(key);
    this.update();
  }

  clear(announcement = false): void {
    if (announcement) {
      announcementsMap.clear();
    } else {
      this.notificationsMap.clear();
    }
    this.update();
  }

  protected setAutodismissTimer(key: string, announcement: boolean, delay = 10): void {
    this.unsetAutodismissTimer(key);
    this.timers.set(
      key,
      setTimeout(() => this.delete(key, announcement), delay * 1000)
    );
  }

  protected unsetAutodismissTimer(key: string): void {
    if (this.timers.has(key)) {
      clearTimeout(this.timers.get(key));
      this.timers.delete(key);
    }
  }

  setError(
    key: string,
    error?: unknown,
    dismissable = true,
    announcement = false,
    dontShowMore = false
  ): void {
    const message = getErrorText(error);
    this.set(
      key,
      message
        ? new BannerNotification({
            key,
            message,
            level: NotificationLevel.ERROR,
            dismissable,
            icon: NotificationIcon.ERROR,
            contactButton: true,
            dontShowMore,
          })
        : undefined,
      announcement
    );
  }

  setInfo(
    key: string,
    message?: string,
    dismissable = true,
    announcement = false,
    dontShowMore = false
  ): void {
    this.set(
      key,
      message
        ? new BannerNotification({
            key,
            message,
            level: NotificationLevel.INFO,
            dismissable,
            icon: NotificationIcon.INFO,
            dontShowMore,
          })
        : undefined,
      announcement
    );
  }

  setWarning(
    key: string,
    message?: string,
    dismissable = true,
    announcement = false,
    dontShowMore = false
  ): void {
    this.set(
      key,
      message
        ? new BannerNotification({
            key,
            message,
            level: NotificationLevel.WARNING,
            dismissable,
            icon: NotificationIcon.WARNING,
            dontShowMore,
          })
        : undefined,
      announcement
    );
  }

  setDebug(
    key: string,
    message?: string,
    dismissable = true,
    announcement = false,
    dontShowMore = false
  ): void {
    this.set(
      key,
      message
        ? new BannerNotification({
            key,
            message,
            level: NotificationLevel.DEBUG,
            dismissable,
            icon: NotificationIcon.DEBUG,
            dontShowMore,
          })
        : undefined,
      announcement
    );
  }

  setDontShowMore(key: string): void {
    this.delete(key);
    this.userMetaService.setNotificationDontShowMoreState(key, true).subscribe(() => noop());
  }

  resetDontShowMore(key: string): void {
    // permitir que se pueda volver a mostrar
    this.userMetaService.setNotificationDontShowMoreState(key, false).subscribe(() => noop());
  }
}
