import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[href]',
})
export class HrefPreventDefaultDirective {
  @Input()
  href: string;

  @HostListener('click')
  onClick(event: Event) {
    if (event) {
      event.preventDefault();
    }
  }
  constructor(/*private el: ElementRef*/) {
    this.href = '';
  }
  preventDefault(event: Event) {
    if ((event && this.href.length === 0) || this.href === '#') {
      event.preventDefault();
    }
  }
}
