<emma-auth-wrapper [type]="type">
    <div class="m-login--emma_container">
        <div class="m-login--emma_side-right">
            <div class="emma-form-header">
                <div class="m-login__logo login-layout">
                    <a href="https://emma.io" target="_self">
                        <img
                            class="emma-logo"
                            src="assets/media/emma/img/logo/emma-logo-darkBlue.svg"
                            alt="EMMA"
                            i18n-alt
                        />
                    </a>
                </div>
            </div>
            <div class="emma-form-content">
                <form (submit)="onSubmit($event)">
                    <div class="m-login__form m-form">
                        <h3 i18n>Tus datos de acceso</h3>
                        <div emma-label label="Email" i18n-label>
                            <emma-input
                                type="email"
                                inputmode="email"
                                placeholder="email@dominio.com"
                                [(ngModel)]="email"
                                (ngModelChange)="onEmailChange()"
                                [required]="true"
                                autocomplete="off"
                                name="email"
                                i18n-placeholder
                            ></emma-input>
                        </div>
                        <div *ngIf="!isMicrosoftFederationLogin" emma-label label="Contraseña" i18n-label>
                            <emma-input
                                type="password"
                                [(ngModel)]="password"
                                [required]="true"
                                name="password"
                            ></emma-input>
                        </div>
                        <div *ngIf="!isMicrosoftFederationLogin" class="row m-login__form-sub">
                            <div class="col">
                                <a
                                    [routerLink]="ROUTE.FORGOT_PASSWORD"
                                    id="m_login_forget_password"
                                    class="m-link m-link--brand"
                                    i18n
                                >
                                    ¿Contraseña olvidada?
                                </a>
                            </div>
                        </div>
                        <div
                            *ngIf="!isMicrosoftFederationLogin"
                            class="m-login__form-action m--margin-bottom-10"
                        >
                            <button
                                type="submit"
                                emmaButton
                                [disabled]="loading.size"
                                [loading]="loading.size"
                                [color]="'altgreen'"
                                [hasIcon]="true"
                                [isBlock]="true"
                            >
                                <span>
                                    <i class="las la-sign-in-alt fs-4" aria-hidden="true"></i>
                                    <span class="m--font-bolder" style="font-size: 1.15rem" i18n
                                        >Identificarme</span
                                    >
                                </span>
                            </button>
                        </div>
                        <div
                            *ngIf="!isMicrosoftFederationLogin"
                            class="form-group m-form__group m-form__group--md"
                        >
                            <div class="emma-form-social-login">
                                <span i18n>o</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group m-form__group--md">
                            <div [hidden]="isMicrosoftFederationLogin" class="d-flex justify-content-center">
                                <div
                                    [hidden]="isMicrosoftFederationLogin"
                                    id="g_id_onload"
                                    data-client_id="976671935890-tk0jp657ksqvsd9vusv38fsd13hn0j37.apps.googleusercontent.com"
                                    data-context="signin"
                                    data-ux_mode="redirect"
                                    [attr.data-login_uri]="googleCallbackUrl"
                                    data-auto_prompt="false"
                                ></div>

                                <div
                                    [hidden]="isMicrosoftFederationLogin"
                                    class="g_id_signin"
                                    data-type="standard"
                                    data-shape="rectangular"
                                    data-theme="outline"
                                    data-text="signin_with"
                                    [attr.data-locale]="googleButtonLocale"
                                    data-size="large"
                                    data-logo_alignment="center"
                                    data-width="400"
                                ></div>
                            </div>
                            <div *ngIf="isMicrosoftFederationLogin">
                                <button
                                    type="submit"
                                    emmaButton
                                    style="border-color: #dadce0"
                                    [disabled]="loading.size"
                                    [loading]="loading.size"
                                    [color]="'metal'"
                                    [hasIcon]="true"
                                    [isBlock]="true"
                                    [isOutline]="true"
                                    (click)="onMicrosoftLogin()"
                                >
                                    <span>
                                        <img
                                            id="Ms"
                                            class="svgImage"
                                            src="assets/media/emma/img/login/Microsoft_logo.svg"
                                            aria-hidden="true"
                                        />
                                        <span i18n
                                            >Iniciar sesión con Microsoft</span
                                        >
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="emma-form-footer">
                <div *ngIf="!isMicrosoftFederationLogin" class="m-login__desc">
                    <span i18n>¿No tienes cuenta?</span>
                    &nbsp;
                    <a
                        href="https://emma.io/demo/"
                        id="m_login_signup"
                        class="m-link m-link--brand m-login__account-link"
                        i18n
                    >
                        Haz una demo
                    </a>
                </div>
            </div>
        </div>
    </div>
</emma-auth-wrapper>
