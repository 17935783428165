import { Injectable } from '@angular/core';
import { FiltersChange, FilterView } from 'emma-common-ts/emma';
import {
  ENDPOINT,
  FILTER_BAR_STATE_PREFIX,
  FILTER_VIEWS_PREFIX,
  TABLE_COLUMNS_PREFIX,
  UserMetaResponse,
  YOUTUBE_VIDEO_PREFIX,
} from 'emma-common-ts';
import { catchError, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';

const NOTIFICATIONS_KEY = 'notifications__notificationsDontShowMore'; // solo necesario en dashboard
@Injectable({ providedIn: 'root' })
export class UserMetaService {
  private static responseValue<T>(response: UserMetaResponse) {
    if (response.data?.value) {
      return JSON.parse(response.data.value) as T;
    } else {
      return null;
    }
  }

  constructor(private api: ApiService) {}

  private get<T>(key: string) {
    return this.api.get<UserMetaResponse>(`${ENDPOINT}/${key}`).pipe(
      map((response) => UserMetaService.responseValue<T>(response)),
      catchError(() => of(null))
    );
  }

  private save<T>(key: string, rawValue: T) {
    const value = JSON.stringify(rawValue);
    return this.api.put(`${ENDPOINT}/${key}`, {
      value,
    });
  }

  // Specific methods
  getTableColumns(tableKey: string) {
    return this.get<string[]>(`${TABLE_COLUMNS_PREFIX}${tableKey}`);
  }
  setTableColumns(tableKey: string, value: string[]) {
    return this.save<string[]>(`${TABLE_COLUMNS_PREFIX}${tableKey}`, value);
  }
  getFilterViews(viewKey: string) {
    return this.get<FilterView[]>(`${FILTER_VIEWS_PREFIX}${viewKey}`);
  }
  setFilterViews(viewKey: string, value: FilterView[]) {
    return this.save<FilterView[]>(`${FILTER_VIEWS_PREFIX}${viewKey}`, value);
  }
  getFilterBarState(viewKey: string) {
    return this.get<FiltersChange>(`${FILTER_BAR_STATE_PREFIX}${viewKey}`);
  }
  setFilterBarState(viewKey: string, value: FiltersChange) {
    return this.save<FiltersChange>(`${FILTER_BAR_STATE_PREFIX}${viewKey}`, value);
  }
  getNotificationDontShowMoreState(notificationKey: string) {
    return this.get<{ [key: string]: boolean }>(NOTIFICATIONS_KEY).pipe(
      map((notifications) => {
        if (notifications) {
          return Boolean(notifications[notificationKey]);
        }
        return false;
      })
    );
  }
  setNotificationDontShowMoreState(notificationKey: string, isDontShowMore: boolean) {
    return this.get<{ [key: string]: boolean }>(NOTIFICATIONS_KEY).pipe(
      switchMap((notifications) => {
        const notificationsUpdate = notifications || {};
        notificationsUpdate[notificationKey] = isDontShowMore;
        return this.save<{ [key: string]: boolean }>(NOTIFICATIONS_KEY, notificationsUpdate);
      })
    );
  }
  getMetaButtonVideo(key: string) {
    return this.get<boolean>(`${YOUTUBE_VIDEO_PREFIX}${key}`);
  }
  updateMetaButtonVideo(key: string, value: boolean) {
    return this.save<boolean>(`${YOUTUBE_VIDEO_PREFIX}${key}`, value);
  }
  getPeopleRawExportUserColums(key: string) {
    return this.get<string[]>(key);
  }
  updatePeopleRawExportUserColums(key: string, value: string[]) {
    return this.save<string[]>(key, value);
  }
}
