import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppsService } from '@emma-services/apps.service';
import { UserService } from '@emma-services/user.service';
import { ROUTE } from 'emma-common-ts/emma';
import { get } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AppHasDataGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService, private appsService: AppsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.userService.getCurrentUser().getValue();
    const app = this.appsService.$currentApp.getValue();
    const can = Boolean(user && get(app, 'appConfig.hasData'));
    if (!can) {
      this.router.navigateByUrl(ROUTE.USER_WELCOME).catch(console.error);
    }
    return can;
  }
}
