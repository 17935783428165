import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import type { RouterRoutes } from '@platform/types/router-route.model';
const routes: RouterRoutes = [{ path: '', redirectTo: 'index', pathMatch: 'full' }];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
