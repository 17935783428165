import { CommonModule, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { Component, Input } from '@angular/core';
import { getGitInfo, GitInfo } from '@platform/helpers/get-git-info.helper';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'emma-footer',
  templateUrl: './footer.component.html',
  // encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements CommonModule {
  @Input() isIframeMode?: boolean;
  public currentYear: Date = new Date();
  public gitInfo: GitInfo;

  constructor() {
    this.gitInfo = getGitInfo();
  }
}
