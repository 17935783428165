import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppsService } from '@emma-services/apps.service';
import { CurrentAppService } from '@emma-services/current-app.service';
import { EmbedService } from '@emma-services/embed.service';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { EMMAUserAppInfo, ROUTE } from 'emma-common-ts/emma';
import { EMBED_CLASS_NAME } from '@platform/constants/embed.constants';
import { get } from 'lodash';

@Component({
  selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
  templateUrl: './emma-app.component.html',
})
export class EMMAAppComponent extends OnUnsubscribe implements OnInit {
  currentApp?: EMMAUserAppInfo;
  isIframeMode?: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private currentAppService: CurrentAppService,
    private appsService: AppsService,
    private embedService: EmbedService
  ) {
    super();
  }

  showWelcome(url: string): boolean {
    const userWelcomeRoutes: Array<string> = [
      ROUTE.USER_WELCOME,
      ROUTE.USER_SETUP,
      ROUTE.USER_APP,
      ROUTE.USER_ACCOUNT,
      ROUTE.USER_PROFILE,
      ROUTE.USER_PASSWORD,
    ];
    const isValidWithoutData = userWelcomeRoutes.some((route) => url.startsWith(route));
    return Boolean(this.currentApp && !get(this.currentApp, 'appConfig.hasData') && !isValidWithoutData);
  }

  ngOnInit(): void {
    this.checkIframeMode();
    this.currentApp = this.currentAppService.$currentApp.getValue();
    // Si en la URL viene el appId, cambia de app y mantiene el path
    const appId = Number(this.router.routerState.snapshot.root.queryParams.appId);
    if (appId && !isNaN(appId) && (!this.currentApp || this.currentApp.appConfig.appId !== appId)) {
      this.appsService.selectCurrentApp(appId, { shouldKeepPath: true });
    }
    this.currentApp = this.currentAppService.$currentApp.getValue();
    const { url } = this.router.routerState.snapshot;
    if (this.showWelcome(url)) {
      this.router.navigateByUrl(ROUTE.USER_WELCOME).catch(console.error);
    }
  }

  private checkIframeMode(): void {
    this.isIframeMode = this.embedService.getEmbedMode();
    if (this.isIframeMode) {
      this.document.body.classList.add(EMBED_CLASS_NAME);
    } else {
      this.document.body.classList.remove(EMBED_CLASS_NAME);
    }
  }
}
