import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { formatNumber } from '@emma-helpers/format.helper';
import {
  CONTACT_MAIL,
  calculateAdquisitionRetentionPrice,
  calculateAnnualPrice,
  getContractedPlan,
  getDefaultAccountPlanConfigV1,
  getDefaultCostAdq,
  getDefaultCostRet,
  getDefaultPlanConfigV1,
  getExtraCostAdq,
  getExtraCostRet,
  getPriceRangesAdq,
  getPriceRangesRet,
} from '@emma-pages/user/plan/user-plan.helper';
import { sectionAnimations } from '@platform/helpers/animations.helper';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { SelectOption } from '@platform/types';
import { safeDiv } from 'emma-common-ts';
import {
  AccountPlan,
  AccountPlanConfigurationV1,
  AccountPlanSave,
  PLAN_CONTACT_TYPE,
  PLAN_TYPE,
  Plan,
  PlanConfigurationV1,
  PlanContactInfo,
  PlanContactMail,
  PlanPriceRange,
  PriceValue,
} from 'emma-common-ts/emma';
import { orderBy, range } from 'lodash';

@Component({
  selector: 'emma-plan-configurator',
  templateUrl: './emma-plan-configurator.component.html',
  animations: [...sectionAnimations],
})
export class EMMAPlanConfiguratorComponent extends OnUnsubscribe implements OnChanges {
  @ViewChild('form', { static: false }) public form!: NgForm;

  PLAN_TYPE = PLAN_TYPE;
  CONTACT_MAIL = CONTACT_MAIL;

  @Input() loading = false;
  @Input() configuratorStandalone = false;
  @Input() plans: Plan[] = [];
  @Input() accountPlan: AccountPlan | undefined;
  @Input() accountId?: number;

  @Output() $planChanged = new EventEmitter<AccountPlanSave>();
  @Output() $contact = new EventEmitter<PlanContactMail>();

  isEdit = false;
  editTitle = '';
  contactModal = false;

  planTypes: SelectOption[] = [];

  currentPlanConfiguration: PlanConfigurationV1 = getDefaultPlanConfigV1();
  accountPlanConfiguration: AccountPlanConfigurationV1 = getDefaultAccountPlanConfigV1();
  contractedPlan: Plan | undefined;

  adquisitionPriceSlider: Array<PriceValue> = [];
  retentionPriceSlider: Array<PriceValue> = [];

  isDowngrade = false;
  isHighVolumeAdquisition = false;
  isHighVolumeRetention = false;
  currentPlanTypeSelected = PLAN_TYPE.PADAWAN;

  defaultCostAdquisition = 0;
  extraCostAdquisition = 0;
  defaultCostRetention = 0;
  extraCostRetention = 0;

  maxAquisitionSlider = 0;
  maxRetentionSlider = 0;
  adquisitionSlider = $localize`Sin`;
  adquisitionTotal = 0;
  adquisitionBillingTotal = 0;
  retentionSlider = $localize`Sin`;
  retentionTotal = 0;
  retentionBillingTotal = 0;
  automationTotal = 0;
  automationBillingTotal = 0;
  apiTotal = 0;
  apiBillingTotal = 0;
  featuresTotal = 0;
  featuresBillingTotal = 0;
  configuratorTotal = 0;

  contactFromSection = '';

  ngOnChanges(changes: SimpleChanges): void {
    if ('plans' in changes || 'accountPlan' in changes) {
      this.loadPlans();
    }
  }

  loadPlans(): void {
    this.planTypes = orderBy(this.plans, 'order', 'asc').map((plan) => {
      return {
        label: $localize`Plan ${plan.name}:name:`,
        value: plan.planType,
      };
    });

    if (this.planTypes.length) {
      if (this.accountPlan) {
        const contractedPlan = getContractedPlan(this.plans, this.accountPlan);
        if (contractedPlan) {
          this.currentPlanTypeSelected = contractedPlan.planType;
          this.contractedPlan = contractedPlan;
        }
      } else if (this.configuratorStandalone) {
        this.currentPlanTypeSelected = PLAN_TYPE.KNIGHT;
      }
      this.updateSelectedPlan();
    }
  }

  calculateSliders(
    step: number,
    maxValue: number,
    rangePrices: Array<PlanPriceRange>,
    defaultPrice: number
  ): PriceValue[] {
    const numSteps = maxValue / step;
    const priceSlider: PriceValue[] = [];
    const ranges = range(0, numSteps + 1);
    ranges.forEach((sliderStep) => {
      const value = sliderStep * step;
      const price = Math.round(calculateAdquisitionRetentionPrice(value, rangePrices, defaultPrice));
      priceSlider.push({
        value,
        price,
      });
    });
    return priceSlider;
  }

  onChangeAdqSlider = (value: number) => {
    if (value <= 0) {
      this.adquisitionSlider = $localize`Sin`;
      this.accountPlanConfiguration.adquisitionAdquired = false;
      this.accountPlanConfiguration.adquisitionAdquiredTotal = 0;
      this.adquisitionTotal = 0;
    } else if (value > this.currentPlanConfiguration.maxAdquisition) {
      this.adquisitionSlider = $localize`Más de ${formatNumber(
        this.currentPlanConfiguration.maxAdquisition
      )}`;
    } else {
      this.adquisitionSlider = formatNumber(value);
      const adquisition = this.adquisitionPriceSlider.find((slider) => slider.value === value);
      this.accountPlanConfiguration.adquisitionAdquired = true;
      this.adquisitionTotal = adquisition ? adquisition.price : 0;
      this.accountPlanConfiguration.adquisitionAdquiredTotal = value;
    }
    this.adquisitionBillingTotal = this.adquisitionTotal;
    this.onChangeFeaturesBilling();
    this.isHighVolumeAdquisition = value > this.currentPlanConfiguration.maxAdquisition;
  };

  onChangeRetSlider = (value: number) => {
    if (value <= 0) {
      this.retentionSlider = $localize`Sin`;
      this.accountPlanConfiguration.retentionAdquired = false;
      this.accountPlanConfiguration.retentionAdquiredTotal = 0;
      this.retentionTotal = 0;
    } else if (value > this.currentPlanConfiguration.maxRetention) {
      this.retentionSlider = $localize`Más de ${formatNumber(this.currentPlanConfiguration.maxRetention)}`;
    } else {
      this.retentionSlider = formatNumber(value);
      this.accountPlanConfiguration.retentionAdquired = true;
      this.accountPlanConfiguration.retentionAdquiredTotal = value;
      const retention = this.retentionPriceSlider.find((slider) => slider.value === value);
      this.retentionTotal = retention ? retention.price : 0;
    }
    this.retentionBillingTotal = this.retentionTotal;
    this.onChangeFeaturesBilling();
    this.isHighVolumeRetention = value > this.currentPlanConfiguration.maxRetention;
  };

  updateBillingTotal() {
    this.configuratorTotal = this.adquisitionTotal + this.retentionTotal + this.featuresBillingTotal;
    this.onChangePayModel();
  }

  calculatePrices(): void {
    this.adquisitionBillingTotal = this.adquisitionTotal;
    this.retentionBillingTotal = this.retentionTotal;
    this.automationBillingTotal = this.automationTotal;
    this.apiBillingTotal = this.apiTotal;
    this.featuresBillingTotal = this.featuresTotal;
    this.configuratorTotal = this.adquisitionTotal + this.retentionTotal + this.featuresBillingTotal;
  }

  onChangeFeaturesBilling(): void {
    if (
      !this.accountPlanConfiguration.adquisitionAdquired &&
      !this.accountPlanConfiguration.retentionAdquired
    ) {
      this.accountPlanConfiguration.automationAdquired = false;
      this.accountPlanConfiguration.apiAdquired = false;
    } else if (!this.accountPlanConfiguration.retentionAdquired) {
      this.accountPlanConfiguration.automationAdquired = false;
    }

    const automationPricePercent =
      this.currentPlanConfiguration.automationPercent > 0
        ? safeDiv(this.currentPlanConfiguration.automationPercent, 100)
        : 1;

    const apiPricePercent =
      this.currentPlanConfiguration.apiPercent > 0
        ? safeDiv(this.currentPlanConfiguration.apiPercent, 100)
        : 1;

    const discountPremiumPack = safeDiv(this.currentPlanConfiguration.discountPremiumPercent, 100);

    if (this.accountPlanConfiguration.automationAdquired && !this.accountPlanConfiguration.apiAdquired) {
      this.automationTotal = (this.adquisitionTotal + this.retentionTotal) * automationPricePercent;
      this.featuresTotal = this.automationTotal;
    } else if (
      !this.accountPlanConfiguration.automationAdquired &&
      this.accountPlanConfiguration.apiAdquired
    ) {
      this.apiTotal = (this.adquisitionTotal + this.retentionTotal) * apiPricePercent;
      this.featuresTotal = this.apiTotal;
    } else if (
      this.accountPlanConfiguration.automationAdquired &&
      this.accountPlanConfiguration.apiAdquired
    ) {
      this.featuresTotal =
        (this.adquisitionTotal + this.retentionTotal) *
        (automationPricePercent + apiPricePercent - discountPremiumPack);
    } else if (
      !this.accountPlanConfiguration.automationAdquired &&
      !this.accountPlanConfiguration.apiAdquired
    ) {
      this.featuresTotal = 0;
    }
    this.automationBillingTotal = this.automationTotal;
    this.apiBillingTotal = this.apiTotal;
    this.featuresBillingTotal = this.featuresTotal;
    this.updateBillingTotal();
  }

  onChangePayModel(): void {
    this.calculatePrices();
    if (this.accountPlanConfiguration.annualPaymentActive) {
      this.adquisitionBillingTotal = calculateAnnualPrice(
        this.adquisitionBillingTotal,
        this.accountPlanConfiguration,
        this.currentPlanConfiguration
      );
      this.retentionBillingTotal = calculateAnnualPrice(
        this.retentionBillingTotal,
        this.accountPlanConfiguration,
        this.currentPlanConfiguration
      );
      this.automationBillingTotal = calculateAnnualPrice(
        this.automationBillingTotal,
        this.accountPlanConfiguration,
        this.currentPlanConfiguration
      );
      this.apiBillingTotal = calculateAnnualPrice(
        this.apiBillingTotal,
        this.accountPlanConfiguration,
        this.currentPlanConfiguration
      );
      this.featuresBillingTotal = calculateAnnualPrice(
        this.featuresBillingTotal,
        this.accountPlanConfiguration,
        this.currentPlanConfiguration
      );
      this.configuratorTotal = calculateAnnualPrice(
        this.configuratorTotal,
        this.accountPlanConfiguration,
        this.currentPlanConfiguration
      );
    }
  }

  sendContact(section: string, contact?: PlanContactInfo): void {
    const adquisitionAdquiredTotal = this.isHighVolumeAdquisition
      ? this.currentPlanConfiguration.maxAdquisition
      : this.accountPlanConfiguration.adquisitionAdquiredTotal;
    const retentionAdquiredTotal = this.isHighVolumeRetention
      ? this.currentPlanConfiguration.maxRetention
      : this.accountPlanConfiguration.retentionAdquiredTotal;

    this.$contact.emit({
      ...contact,
      public: false,
      type: PLAN_CONTACT_TYPE.CONFIGURATOR,
      contactFromSection: section,
      planConfig: {
        ...this.accountPlanConfiguration,
        adquisitionAdquiredTotal,
        retentionAdquiredTotal,
      },
    });
  }

  onContact(section: string): void {
    this.contactFromSection = section;
    this.applyTotal();
    if (this.configuratorStandalone) {
      this.contactModal = true;
    } else {
      this.sendContact(section);
    }
  }

  onContactModalConfirmation(contact: PlanContactInfo): void {
    this.sendContact(this.contactFromSection, contact);
    this.onCloseModal();
  }

  onCloseModal(): void {
    this.contactModal = false;
  }

  onCancel(): void {
    if (this.contractedPlan && this.accountPlan) {
      this.currentPlanTypeSelected = this.contractedPlan.planType;
    }
    this.updateSelectedPlan();
    this.isEdit = false;
  }

  onEdit(): void {
    this.isEdit = true;
  }

  onSave(): void {
    const selectedPlan = this.plans.find((plan) => plan.planType === this.currentPlanTypeSelected);
    if (selectedPlan && this.accountId) {
      this.applyTotal();
      const changePlan: AccountPlanSave = {
        planId: selectedPlan.id,
        accountId: this.accountId,
        configuration: this.accountPlanConfiguration,
      };
      if (this.accountPlan) {
        changePlan.createdAt = this.accountPlan.createdAt;
        if (selectedPlan.id === this.accountPlan.planId) {
          // En el caso que tenga unos precios especiales los mantenemos para el mismo plan
          changePlan.configuration = { ...this.accountPlan.configuration, ...this.accountPlanConfiguration };
        }
      }
      this.isEdit = false;
      this.$planChanged.emit(changePlan);
    }
  }

  canSave(): boolean {
    return Boolean(this.form?.valid);
  }

  resetBillingInfo(): void {
    this.adquisitionBillingTotal = 0;
    this.retentionBillingTotal = 0;
    this.automationBillingTotal = 0;
    this.apiBillingTotal = 0;
    this.featuresBillingTotal = 0;
    this.configuratorTotal = 0;
  }

  reset(): void {
    if (
      this.accountPlan &&
      this.contractedPlan &&
      this.contractedPlan.planType === this.currentPlanTypeSelected
    ) {
      this.accountPlanConfiguration = { ...(this.accountPlan.configuration as AccountPlanConfigurationV1) };
      this.resetPlanVariables();
      // El plan padawan no se basa en el total adquirido
      if (this.currentPlanTypeSelected !== PLAN_TYPE.PADAWAN) {
        this.onChangeAdqSlider(this.accountPlanConfiguration.adquisitionAdquiredTotal);
        this.onChangeRetSlider(this.accountPlanConfiguration.retentionAdquiredTotal);
      }
      this.onChangeFeaturesBilling();
    } else {
      this.accountPlanConfiguration = getDefaultAccountPlanConfigV1();
      this.resetPlanVariables();
      this.resetBillingInfo();
    }
  }

  resetPlanVariables(): void {
    const configuration = this.accountPlan?.configuration as AccountPlanConfigurationV1;
    this.adquisitionPriceSlider = this.calculateSliders(
      this.currentPlanConfiguration.adquisitionStep,
      this.currentPlanConfiguration.maxAdquisition,
      getPriceRangesAdq(configuration, this.currentPlanConfiguration),
      getDefaultCostAdq(configuration, this.currentPlanConfiguration)
    );

    this.retentionPriceSlider = this.calculateSliders(
      this.currentPlanConfiguration.retententionStep,
      this.currentPlanConfiguration.maxRetention,
      getPriceRangesRet(configuration, this.currentPlanConfiguration),
      getDefaultCostRet(configuration, this.currentPlanConfiguration)
    );

    this.maxAquisitionSlider =
      this.currentPlanConfiguration.maxAdquisition + this.currentPlanConfiguration.adquisitionStep;
    this.maxRetentionSlider =
      this.currentPlanConfiguration.maxRetention + this.currentPlanConfiguration.retententionStep;
    this.defaultCostAdquisition = getDefaultCostAdq(configuration, this.currentPlanConfiguration);
    this.extraCostAdquisition = getExtraCostAdq(configuration, this.currentPlanConfiguration);
    this.defaultCostRetention = getDefaultCostRet(configuration, this.currentPlanConfiguration);
    this.extraCostRetention = getExtraCostRet(configuration, this.currentPlanConfiguration);
  }

  updateSelectedPlan(): void {
    const selectedPlan = this.plans.find((plan) => plan.planType === this.currentPlanTypeSelected);
    if (selectedPlan) {
      this.editTitle = $localize`Plan ${selectedPlan.name}:name:`;
      this.currentPlanConfiguration = selectedPlan.configuration as PlanConfigurationV1;
      this.checkIfDowngradePlan(selectedPlan);
    }
    this.reset();
  }

  checkIfDowngradePlan(selectedPlan: Plan): void {
    this.isDowngrade = false;
    if (this.accountPlan && this.contractedPlan) {
      this.isDowngrade = selectedPlan.order < this.contractedPlan.order;
    }
  }

  onSelectedPlanChanged(): void {
    this.updateSelectedPlan();
  }

  applyTotal(): void {
    this.accountPlanConfiguration.totalPrice = Math.round(this.configuratorTotal);
  }
}
