<emma-auth-wrapper [type]="type">
    <div class="m-grid m-grid--ver m-grid__item m-grid__item--fluid m-login--emma_container">
        <div class="m-grid m-grid--hor m-grid--root m-grid__item m-grid__item--fluid m-login--emma_side-left">
            <div class="emma-form-context-header">
                <div class="m-login__logo">
                    <a href="https://emma.io" target="_self">
                        <img src="assets/media/emma/img/logo/emma-logo-darkBlue.svg" alt="EMMA" i18n-alt />
                    </a>
                </div>
                <p>
                    <span i18n
                        >¿Listo para tomar decisiones de app marketing más inteligentes? Obtén una visión
                        única de tu App
                    </span>
                </p>
            </div>

            <div
                *ngIf="currentStep === steps.EMAIL"
                class="m-grid m-grid--hor m-grid__item m-grid__item--fluid justify-content-center align-self-center emma-form-context-content"
            >
                <div class="emma-form-context-content_plan">
                    <h3 class="altgreen" i18n>EMMA Crecimiento</h3>
                    <p>
                        <span i18n>Con el plan EMMA Crecimiento </span
                        ><strong i18n>tendrás las funcionalidades básicas para tu App</strong
                        ><span i18n>, como atribución, </span><em i18n>customer journey</em
                        ><span i18n>, notificaciones push, emails y mensajes </span><em i18n>in app.</em>
                    </p>
                </div>
                <div class="emma-form-context-content_plan">
                    <h3 class="altpink" i18n>EMMA Avanzado</h3>
                    <p>
                        <span i18n>Con el plan EMMA Avanzado tendrás </span
                        ><strong i18n>todas las funcionalidades de EMMA Crecimiento</strong
                        ><span i18n> y también </span><em i18n>retargeting</em><span i18n>, </span
                        ><em i18n>native ads</em
                        ><span i18n>, audiencias, Google Ads, Facebook, Twitter...</span>
                    </p>
                </div>
                <div class="emma-form-context-content_plan">
                    <h3 class="altblue" i18n>EMMA Empresa</h3>
                    <p>
                        <span i18n>Un plan pensado para grandes corporaciones con </span
                        ><strong i18n>necesidades específicas</strong
                        ><span i18n> con todas las características como roles de accesos por niveles.</span>
                    </p>
                </div>
            </div>
            <div
                *ngIf="currentStep === steps.EMAIL"
                class="justify-content-end align-self-center emma-form-context-footer"
            >
                <p>
                    <span i18n>¿Tienes dudas? Puedes ver todas las características de los planes </span
                    ><a href="https://emma.io/precios/" class="m-link" target="_self" i18n
                        >en nuestra tabla comparativa</a
                    ><span i18n>.</span>
                </p>
            </div>
            <div
                *ngIf="currentStep === steps.CONTACT"
                class="m-grid m-grid--hor m-grid__item m-grid__item--fluid justify-content-center align-self-center emma-form-context-content"
            >
                <div class="emma-form-context-content_plan">
                    <h3 class="altgreen" i18n>Crece</h3>
                    <p>
                        <span i18n
                            >Mide la atribución de todas tus campañas de adquisición de usuarios bajo el mismo
                            criterio.</span
                        >
                    </p>
                </div>
                <div class="emma-form-context-content_plan">
                    <h3 class="altpink" i18n>Fideliza</h3>
                    <p>
                        <span i18n
                            >Crea una comunicación fluida con tus usuarios a través de tu app para
                            convertirlos en usuarios recurrentes.</span
                        >
                    </p>
                </div>
                <div class="emma-form-context-content_plan">
                    <h3 class="altblue" i18n>Optimiza</h3>
                    <p>
                        <span i18n
                            >Segmenta a tus usuarios en base a su comportamiento y crea experimentos para
                            evaluar acciones y rendimiento.</span
                        >
                    </p>
                </div>
            </div>
            <div
                *ngIf="currentStep === steps.CARD"
                class="m-grid m-grid--hor m-grid__item m-grid__item--fluid justify-content-center align-self-center emma-form-context-content"
            >
                <div class="emma-form-context-payfeatures">
                    <div class="emma-payfeature">
                        <img
                            src="assets/media/emma/img/auth/icon-shield.svg"
                            alt="Icono de protección"
                            i18n-alt
                        />
                        <p><span i18n>Pago </span><br /><span i18n>seguro</span></p>
                    </div>
                    <div class="emma-payfeature">
                        <img
                            src="assets/media/emma/img/auth/icon-checkcircle.svg"
                            alt="Icono de fiabilidad garantizada"
                            i18n-alt
                        />
                        <p><span i18n>Satisfacción </span><br /><span i18n>garantizada</span></p>
                    </div>
                    <div class="emma-payfeature">
                        <img
                            src="assets/media/emma/img/auth/icon-lock.svg"
                            alt="Icono de privacidad"
                            i18n-alt
                        />
                        <p><span i18n>Privacidad </span><br /><span i18n>asegurada</span></p>
                    </div>
                </div>
                <p class="emma-pay-explanation" i18n>
                    Con la confianza de más de 100 apps en todo el mundo con pensamiento avanzado.
                </p>
                <div class="emma-divider-feature"></div>
                <div class="emma-pay-powered">
                    <img
                        src="assets/media/emma/img/auth/poweredbyStripe.png"
                        alt="Icono de Stripe"
                        i18n-alt
                    />
                </div>
                <div class="emma-pay-creditcards">
                    <img
                        src="assets/media/emma/img/auth/creditcards.png"
                        alt="Imagen de las distintas tarjetas de credito compatibles: Visa, Mastercard y Amex"
                        i18n-alt
                    />
                </div>
            </div>
        </div>
        <div class="m-grid__item m-login--emma_divider"></div>
        <div
            class="m-grid m-grid--hor m-grid--root m-grid__item m-grid__item--fluid m-login--emma_side-right"
        >
            <div class="emma-form-header">
                <div class="m-login__logo">
                    <a href="https://emma.io" target="_self">
                        <img src="assets/media/emma/img/logo/emma-logo-darkBlue.svg" alt="EMMA" i18n-alt />
                    </a>
                </div>
                <div class="m-login__desc">
                    <span i18n>¿Ya tienes EMMA?</span>
                    &nbsp;
                    <a
                        i18n
                        [routerLink]="ROUTE.LOGIN"
                        id="m_login_signup"
                        class="m-link m-link--brand m-login__account-link"
                    >
                        Inicia sesión
                    </a>
                </div>
            </div>
            <div
                class="m-grid m-grid--hor m-grid__item m-grid__item--fluid justify-content-center emma-form-content"
            >
                <form #form="ngForm">
                    <div class="m-login__form m-form">
                        <ng-container [ngSwitch]="currentStep">
                            <div *ngSwitchCase="steps.EMAIL">
                                <h3 i18n>Elige tu plan</h3>
                                <div emma-label label="Plan" i18n-label>
                                    <emma-select
                                        name="plan"
                                        [items]="selectablePlans"
                                        [(ngModel)]="selectedPlanType"
                                        [multiple]="false"
                                        [clearable]="false"
                                        [showChips]="false"
                                        [required]="true"
                                        ($change)="onSelectedPlanChanged($event)"
                                        color="link-metal"
                                    ></emma-select>
                                </div>
                                <div emma-label label="Volumen de usuarios activos mensuales" i18n-label>
                                    <emma-select
                                        [items]="allVolumeMaus"
                                        name="volumeMaus"
                                        [(ngModel)]="planConfiguration.retentionAdquiredTotal"
                                        [multiple]="false"
                                        [clearable]="false"
                                        [showChips]="false"
                                        ($change)="onMausChanged()"
                                    ></emma-select>
                                </div>
                                <div class="emma-price-plan m--margin-top-10 m--margin-bottom-20">
                                    <span
                                        ><strong>{{ planConfiguration.totalPrice }}</strong
                                        ><span> €</span
                                        ><span class="emma-price-plan--period" i18n> / mes </span
                                        ><span class="emma-price-plan--include font-italic" i18n
                                            >(más IVA)</span
                                        ></span
                                    >
                                </div>

                                <h3 i18n>Configura tu cuenta</h3>
                                <div emma-label label="Email" i18n-label>
                                    <emma-input
                                        name="email"
                                        type="email"
                                        inputmode="email"
                                        placeholder="email@dominio.com"
                                        [(ngModel)]="signUpData.email"
                                        (ngModelChange)="onEmailChange()"
                                        [ngbPopover]="federationErrorMsg"
                                        [autocomplete]="false"
                                        [required]="true"
                                        i18n-placeholder
                                    ></emma-input>
                                </div>
                                <div emma-label label="Contraseña" i18n-label>
                                    <emma-input
                                        name="password"
                                        type="password"
                                        [(ngModel)]="signUpData.password"
                                        [validateFn]="validatePassword"
                                        ($change)="showPasswordWarning()"
                                        [required]="true"
                                        inputClass="form-control m-input m-login__form-input--last"
                                        [minlength]="MIN_PASSWORD_LENGTH"
                                        placement="right"
                                        triggers="focus"
                                    ></emma-input>
                                </div>
                            </div>
                            <div *ngSwitchCase="steps.CONTACT">
                                <h3 i18n>Configura tu perfil</h3>
                                <div emma-label label="Nombre" i18n-label>
                                    <emma-input
                                        [(ngModel)]="signUpData.firstName"
                                        [required]="true"
                                        name="firstName"
                                    ></emma-input>
                                </div>
                                <div emma-label label="Apellidos" i18n-label>
                                    <emma-input
                                        [(ngModel)]="signUpData.lastName"
                                        [required]="true"
                                        name="lastName"
                                    ></emma-input>
                                </div>
                                <div class="emma-form-samerow equal m--margin-bottom-20">
                                    <div emma-label label="Empresa" i18n-label>
                                        <emma-input
                                            [(ngModel)]="signUpData.company"
                                            [required]="true"
                                            name="company"
                                        ></emma-input>
                                    </div>
                                    <div emma-label label="Nombre de la app" i18n-label>
                                        <emma-input [required]="true" name="app"></emma-input>
                                    </div>
                                </div>
                                <h3 i18n>Indica tu dirección</h3>
                                <div class="emma-form-samerow lastsmall">
                                    <div emma-label label="Dirección" i18n-label>
                                        <emma-input
                                            [(ngModel)]="signUpData.address"
                                            [required]="true"
                                            name="direction"
                                        ></emma-input>
                                    </div>
                                    <div emma-label label="Código postal" i18n-label>
                                        <emma-input
                                            name="postalCode"
                                            type="text"
                                            size="5"
                                            [(ngModel)]="signUpData.postalCode"
                                            [required]="true"
                                        ></emma-input>
                                    </div>
                                </div>
                                <div emma-label label="Localidad" i18n-label>
                                    <emma-input
                                        name="city"
                                        [(ngModel)]="signUpData.city"
                                        [required]="true"
                                    ></emma-input>
                                </div>
                                <div emma-label label="País" i18n-label>
                                    <emma-select
                                        name="country"
                                        [items]="countries"
                                        [(ngModel)]="signUpData.country"
                                        [required]="true"
                                        [searchable]="true"
                                        color="link-metal"
                                    ></emma-select>
                                </div>
                            </div>
                            <div *ngSwitchCase="steps.CARD">
                                <div class="emma-pay-card-preview">
                                    <p i18n>
                                        Tienes 45 días de Onboarding para integrar el SDK de EMMA en tu App.
                                        Una vez transcurrido este período se emitirá el primer cobro.
                                    </p>
                                    <div class="emma-pay-card-preview-card">
                                        <img
                                            *ngIf="typecard === 'default'"
                                            class="creditcardcompany"
                                            src="assets/media/emma/img/auth/card-EMMA.svg"
                                            alt="Icono de tarjeta por defecto"
                                            i18n-alt
                                        />
                                        <img
                                            *ngIf="typecard === 'mastercard'"
                                            class="creditcardcompany"
                                            src="assets/media/emma/img/auth/card-Mastercard.svg"
                                            alt="Icono de tarjeta Mastercard"
                                            i18n-alt
                                        />
                                        <img
                                            *ngIf="typecard === 'visa'"
                                            class="creditcardcompany"
                                            src="assets/media/emma/img/auth/card-Visa.svg"
                                            alt="Icono de tarjeta Visa"
                                            i18n-alt
                                        />
                                        <img
                                            *ngIf="typecard === 'amex'"
                                            class="creditcardcompany"
                                            src="assets/media/emma/img/auth/card-Amex.svg"
                                            alt="Icono de Amex"
                                            i18n-alt
                                        />
                                        <div class="emma-pay-card-preview-owner">
                                            <span class="title" i18n>Titular</span>
                                            <span class="content"
                                                >{{ signUpData.firstName }} {{ signUpData.lastName }}</span
                                            >
                                        </div>
                                        <div class="emma-pay-card-preview-number">
                                            <span class="content">{{ signUpData.cardNumber }}</span>
                                        </div>
                                        <div class="emma-pay-card-preview-date">
                                            <span class="title" i18n>Valido hasta</span>
                                            <span class="content"
                                                ><span>{{ signUpData.cardMonth }}</span
                                                ><span i18n>/</span
                                                ><span>{{ signUpData.cardYear }}</span></span
                                            >
                                        </div>
                                        <img
                                            class="cardimage"
                                            src="assets/media/emma/img/auth/creditCardBg.png"
                                            alt=""
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                                <h3 i18n>Datos de pago</h3>
                                <div emma-label label="Número de tarjeta" i18n-label>
                                    <emma-input
                                        name="cardNumber"
                                        placeholder="0000 0000 0000 0000"
                                        [(ngModel)]="signUpData.cardNumber"
                                        [required]="true"
                                        [validateFn]="validateCreditCardNumber"
                                    ></emma-input>
                                </div>
                                <div class="form-group m-form__group">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div emma-label label="Mes" i18n-label>
                                                <emma-select
                                                    name="cardMonth"
                                                    [items]="months"
                                                    [(ngModel)]="signUpData.cardMonth"
                                                    [required]="true"
                                                    color="link-metal"
                                                ></emma-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div emma-label label="Año" i18n-label>
                                                <emma-select
                                                    name="cardYear"
                                                    [items]="years"
                                                    [(ngModel)]="signUpData.cardYear"
                                                    [required]="true"
                                                    color="link-metal"
                                                ></emma-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div emma-label label="CVC" i18n-label>
                                    <emma-input
                                        name="cvc"
                                        type="password"
                                        inputmode="number"
                                        [(ngModel)]="signUpData.cardCVC"
                                        [required]="true"
                                        [minlength]="3"
                                        [maxlength]="4"
                                        [validateFn]="validateCreditCardCVC"
                                    ></emma-input>
                                </div>
                                <emma-switch name="agree" [(ngModel)]="signUpData.agree" [required]="true">
                                    <ng-template emma-switch-label-tmp>
                                        <strong>
                                            <span i18n>He leído y acepto los </span>
                                            <a
                                                href="https://emma.io/es/politica-de-privacidad/"
                                                class="m-link m-link--brand"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                ngbPopover="<p><strong>¿Qué empresas tratan sus datos?</strong> EMMA MOBILE SOLUTIONS, S.L. (en adelante 'EMMA')</p><p><strong>¿Por qué tratamos los datos que le pedimos?</strong> Tratamos sus datos para poder prestarle nuestros servicios y enviarle información sobre productos y/o servicios de terceras empresas.</p><p><strong>¿Cuál es la legitimización para este tratamiento de sus datos?</strong> Estos datos son necesarios para llevar a cabo la prestación de los servicios que haya solicitado a través del Sitio Web.</p><p><strong>¿Se utilizan sus datos para hacer perfilados o segmentaciones?</strong> EMMA podrá utilizar técnicas de profiling para poder ofrecerle publicidad de terceros acorde con sus intereses.</p><p><strong>¿Tiene dudas?</strong> Tanto si tiene alguna o sugerencia como si quiere darse de baja póngase en contacto con nosotros enviando un email a: info@emma.io</p>"
                                                placement="right"
                                                i18n-ngbPopover
                                                i18n
                                            >
                                                Términos de uso y la Política de privacidad de EMMA.
                                            </a>
                                        </strong>
                                    </ng-template>
                                </emma-switch>
                            </div>
                        </ng-container>

                        <div class="m-login__form-action">
                            <button
                                type="button"
                                emmaButton
                                [routerLink]="ROUTE.LOGIN"
                                [disabled]="loading.size"
                                class="m--margin-right-10"
                                i18n
                            >
                                Ya tengo usuario
                            </button>
                            <button
                                type="button"
                                emmaButton
                                [disabled]="loading.size || !isValid()"
                                [loading]="loading.size"
                                color="altgreen"
                                (click)="currentStep < steps.CARD ? nextStep() : onSignup()"
                                [ngSwitch]="currentStep"
                            >
                                <ng-container *ngSwitchCase="steps.CONTACT" i18n>Continuar</ng-container>
                                <ng-container *ngSwitchDefault i18n>Crear cuenta</ng-container>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</emma-auth-wrapper>
