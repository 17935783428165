import { Injectable } from '@angular/core';
import { EmbedService as PlatformEmbedService } from '@platform/services/embed.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class EmbedService extends PlatformEmbedService {
  constructor(localStorageService: LocalStorageService) {
    super(localStorageService);
  }
}
