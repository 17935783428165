import { Injectable } from '@angular/core';
import { ApiManyResponse, ApiResponse, STATUS } from 'emma-common-ts';
import { EMMAUserAppInfo } from 'emma-common-ts/emma';
import { get } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { ChangeAppOptions, CurrentAppService } from './current-app.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AppsService {
  $currentApp: BehaviorSubject<EMMAUserAppInfo | undefined>;
  apps: EMMAUserAppInfo[] = [];

  fakeGetApps = new BehaviorSubject<ApiManyResponse<EMMAUserAppInfo>>({
    data: [],
    status: STATUS.SUCCESS,
  });

  constructor(private currentAppService: CurrentAppService, private userService: UserService) {
    this.$currentApp = this.currentAppService.$currentApp;
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.apps = Object.values(user.apps);
        this.fakeGetApps.next({ status: STATUS.SUCCESS, data: this.apps });
        if (this.apps.length) {
          const currentApp = this.currentAppService.$currentApp.value;
          if (!currentApp) {
            this.currentAppService.changeCurrentApp(this.apps[0]);
          } else {
            const realApp = this.apps.find(
              (app) => get(app, 'appConfig.appId') === get(currentApp, 'appConfig.appId')
            );
            if (!realApp) {
              this.currentAppService.changeCurrentApp(this.apps[0]);
            } else {
              this.currentAppService.changeCurrentApp(realApp);
            }
          }
        }
      } else {
        console.warn('No user');
        this.apps = [];
      }
      this.fakeGetApps.next({ status: STATUS.SUCCESS, data: this.apps });
    });
  }
  getApps(): Observable<ApiManyResponse<EMMAUserAppInfo>> {
    return this.fakeGetApps;
    // return this.service.getApi<ApiManyResponse<EMMAUserAppInfo>>(`/apps`);
  }
  getApp(id: number): BehaviorSubject<ApiResponse<EMMAUserAppInfo>> {
    const app = this.apps.find((a) => get(a, 'appConfig.appId') === id);
    const emit = new BehaviorSubject<ApiResponse<EMMAUserAppInfo>>({
      status: app ? STATUS.SUCCESS : STATUS.FAILURE,
      data: app,
    });
    return emit;
    // return this.service.getApi<ApiResponse<EMMAUserAppInfo>>(`/apps/${id}`);
  }
  selectCurrentApp(id: number, options?: Partial<ChangeAppOptions>): void {
    this.getApp(id).subscribe((response) => {
      if (response && response.data) {
        this.currentAppService.changeCurrentApp(response.data, options);
      }
    });
  }
  /**
   * Recupera los datos guardados de la app en el LocalStorage
   * @returns {any} Representación json de los datos de la app
   */
  getCurrentApp(): EMMAUserAppInfo | undefined {
    return this.$currentApp.getValue();
  }
}
