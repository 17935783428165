import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EMMAAppComponent } from '@emma-components/emma-app.component';
import { ApiVersionGuard } from '@emma-guards/api-version.guard';
import { AppHasDataGuard } from '@emma-guards/app-has-data.guard';
import { AuthGuard } from '@emma-guards/auth.guard';
import { RoutePermissionGuard } from '@emma-guards/route-permission.guard';
import { UserIsAdminGuard } from '@emma-guards/user-is-admin.guard';
import { AuthModule } from '@emma-pages/auth/auth.module';
import { PublicPathModule } from '@emma-pages/public/public.module';
import { EMMA_API_VERSION } from 'emma-common-ts/emma';

import type { RouterRoutes } from '@platform/types/router-route.model';
const routes: RouterRoutes = [
  {
    path: '',
    component: EMMAAppComponent,
    canActivate: [AuthGuard, RoutePermissionGuard],
    children: [
      {
        path: 'index',
        loadChildren: () => import('@emma-pages/index/index.module').then((m) => m.IndexPageModule),
      },
      {
        path: 'legacy',
        loadChildren: () => import('@emma-pages/legacy/legacy.module').then((m) => m.LegacyPathModule),
        canActivate: [AppHasDataGuard, ApiVersionGuard],
        data: { allowedApiVersions: [EMMA_API_VERSION.V10] },
      },
      {
        path: 'main',
        loadChildren: () => import('@emma-pages/main/main.module').then((m) => m.MainPathModule),
        canActivate: [AppHasDataGuard, ApiVersionGuard],
        data: { forbiddenApiVersions: [EMMA_API_VERSION.V10] },
      },
      {
        path: 'acquisition',
        loadChildren: () =>
          import('@emma-pages/acquisition/acquisition.module').then((m) => m.AcquisitionPathModule),
        canActivate: [AppHasDataGuard],
        data: {
          forbiddenApiVersions: [EMMA_API_VERSION.V10],
        },
      },
      {
        path: 'behavior',
        loadChildren: () => import('@emma-pages/behavior/behavior.module').then((m) => m.BehaviorPathModule),
        canActivate: [AppHasDataGuard, ApiVersionGuard],
        data: {
          forbiddenApiVersions: [EMMA_API_VERSION.V10],
        },
      },
      {
        path: 'admin',
        loadChildren: () => import('@emma-pages/admin/admin.module').then((m) => m.AdminPathModule),
        canActivate: [UserIsAdminGuard, AppHasDataGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('@emma-pages/user/user.module').then((m) => m.UserPathModule),
      },
      {
        path: 'communication',
        loadChildren: () =>
          import('@emma-pages/communication/communication.module').then((m) => m.CommunicationPathModule),
      },
      {
        path: 'manage',
        loadChildren: () => import('@emma-pages/manage/manage.module').then((m) => m.ManagePathModule),
      },
      {
        path: 'products',
        loadChildren: () => import('@emma-pages/products/products.module').then((m) => m.ProductsPathModule),
      },
    ],
  },
];

@NgModule({
  imports: [AuthModule, PublicPathModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  // providers: [{ provide: LocationStrategy, useClass: EmmaIframePathLocationStrategy }],
})
export class EMMARoutingModule {}
