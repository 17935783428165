import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { CurrentAppService } from '@emma-services/current-app.service';
import { get } from 'lodash';

@Injectable({ providedIn: 'root' })
export class ApiVersionGuard implements CanActivate {
  constructor(private currentApp: CurrentAppService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const app = this.currentApp.getCurrentApp();
    let isActivable = true;
    if (app && route.data) {
      const apiVersion = get(app, 'appConfig.apiVersion');
      const { allowedApiVersions = [], forbiddenApiVersions = [] } = route.data;
      if (allowedApiVersions && allowedApiVersions.length) {
        isActivable = isActivable && allowedApiVersions.includes(apiVersion);
      }
      if (forbiddenApiVersions && forbiddenApiVersions.length) {
        isActivable = isActivable && !forbiddenApiVersions.includes(apiVersion);
      }
    } else {
      isActivable = false;
    }
    return isActivable;
  }
}
