import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { isAdminUser } from '@emma-helpers/auth.helper';
import { CurrentAppService } from '@emma-services/current-app.service';
import { UserService } from '@emma-services/user.service';
import { ROUTE } from 'emma-common-ts/emma';

@Injectable({ providedIn: 'root' })
export class UserIsAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private currentAppService: CurrentAppService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.userService.getCurrentUser().getValue();
    const app = this.currentAppService.$currentApp.getValue();
    const can = isAdminUser(user, app);
    if (!can) {
      this.router.navigateByUrl(ROUTE.ROOT).catch(console.error);
    }
    return can;
  }
}
