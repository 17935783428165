<div *ngIf="!isFirst" class="m-form__separator m-form__separator--dashed"></div>
<div
    [@fadeInOut]
    [ngClass]="{
        'm-form__section': true,
        'm-form__section--first': isFirst,
        'm-form__section--last': isLast
    }"
>
    <div class="row">
        <div class="col-md-3">
            <div class="m-form__heading">
                <h3 *ngIf="headTitle || titleIcon" class="m-form__heading-title">
                    <i *ngIf="titleIcon" class="la la-{{ titleIcon }}" aria-hidden="true"></i>
                    {{ headTitle }}
                </h3>
                <div class="m-form__contextual">
                    <p>{{ description }}</p>
                    <p *ngIf="descriptionHighlight && !powerAdminBlock" class="m--font-danger">
                        {{ descriptionHighlightText }}
                    </p>
                    <p *ngIf="powerAdminBlock" class="m--font-danger">(PowerAdmin)</p>
                    <i
                        *ngIf="descriptionLink"
                        class="m--font-brand la la-external-link"
                        aria-hidden="true"
                    ></i>
                    &nbsp;
                    <a
                        *ngIf="descriptionLink"
                        class="m-link"
                        href="{{ descriptionHelpLink }}"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ descriptionHelpLinkText }}
                    </a>
                </div>

                <div
                    *ngIf="copyContent"
                    class="m-form__url-preview"
                    emmaCopyToClipboard
                    [copyValue]="copyContent"
                >
                    {{ copyContent }}
                </div>
                <div *ngIf="youtubeVideoEmbed.length">
                    <emma-help-video
                    [modalTitle]="titleModalVideoEmbed"
                    [videoYoutube]="youtubeVideoEmbed"
                    ></emma-help-video>
                </div>
            </div>
        </div>
        <div class="offset-md-1 col-md-8">
            <ng-content></ng-content>
        </div>
    </div>
</div>
