import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EMMAAppModule } from '@emma-components/emma-app.module';
import { EMMAUIModule } from '@emma-components/emmaui.module';
import { EMMAFormModule } from '@emma-components/forms/emma-form.module';
import { LayoutModule } from '@emma-components/layouts/layout.module';
import { environment } from '@emma-environments/environment';
import { EMMAPipesModule } from '@emma-pipes/emma-pipes.module';
import { PlatformErrorHandler } from '@platform/providers/error-handler.class';
import { API_URL, BASE_URL } from '@platform/providers/url.provider';
import { WINDOW } from '@platform/providers/window.provider';
import { LocaleService, localeFactory } from '@platform/services/locale.service';
import { ENVIRONMENT } from 'emma-common-ts';
import { MarkdownModule } from 'ngx-markdown';

import { EmmaAuthHeaders } from '@emma-helpers/auth-headers.class';
import { ApiAuthInterceptor } from '@emma/_interceptors/api-auth.interceptor';
import { AuthHeaders } from '@platform/helpers/auth-headers.class';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EMMAScriptInjectorsModule } from './emma/_script-injectors/script-injectors.module';
import { EMMARoutingModule } from './emma/emma-routing.module';
import { NotFoundRoutingModule } from './not-found-routing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AmChartsModule,
    BrowserAnimationsModule,
    BrowserModule,
    EMMAFormModule,
    EMMAPipesModule,
    EMMAUIModule,
    FormsModule,
    LayoutModule,
    MarkdownModule.forRoot(),
    EMMAScriptInjectorsModule,
    EMMAAppModule,
    // Routes
    AppRoutingModule,
    EMMARoutingModule,
    NotFoundRoutingModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      disableTimeOut: false,
      preventDuplicates: true,
    }),
  ],
  providers: [
    LocaleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: PlatformErrorHandler },
    { provide: LOCALE_ID, useFactory: () => localeFactory() },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: BASE_URL, useValue: environment.baseUrl },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: AuthHeaders, useClass: EmmaAuthHeaders },
    { provide: WINDOW, useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
