import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EMMAScriptInjectorGoogleSignInComponent } from './script-injector-google-signin.component';
import { EMMAScriptInjectorGTMComponent } from './script-injector-gtm.component';
import { EMMAScriptInjectorMessageReceiverComponent } from './script-injector-message-receiver.component';
import { EMMAScriptInjectorStatuspageComponent } from './script-injector-statuspage.component';

@NgModule({
  declarations: [
    EMMAScriptInjectorStatuspageComponent,
    EMMAScriptInjectorMessageReceiverComponent,
    EMMAScriptInjectorGoogleSignInComponent,
    EMMAScriptInjectorGTMComponent,
  ],
  imports: [CommonModule],
  exports: [
    EMMAScriptInjectorStatuspageComponent,
    EMMAScriptInjectorMessageReceiverComponent,
    EMMAScriptInjectorGoogleSignInComponent,
    EMMAScriptInjectorGTMComponent,
  ],
})
export class EMMAScriptInjectorsModule {}
