import { HttpHeaders } from '@angular/common/http';
import { AuthHeaders } from '@platform/helpers/auth-headers.class';
import { EMMA_API_VERSION } from 'emma-common-ts/emma';
import { getAuthKeys } from './auth.helper';

export class EmmaAuthHeaders implements AuthHeaders {
  // eslint-disable-next-line class-methods-use-this
  get = () => {
    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'X-Api-Version': EMMA_API_VERSION.V20,
    });
    // create authorization header with jwt token
    const authKeys = getAuthKeys();
    if (authKeys && authKeys.apiKey && authKeys.accessToken) {
      headers = headers.set('apiKey', authKeys.apiKey).set('token', authKeys.accessToken);
    }
    return headers;
  };
}
