import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'emma-modal',
  templateUrl: './emma-modal.component.html',
})
export class EMMAModalComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: true }) content!: any;
  @Input() disableOverlayClose = false;
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' | any;
  @Input() centered = true;
  @Input() classModal = 'emma-modal';
  @Input() classBgModal = 'modal-bg-emma';
  @Input() noFooter = false;
  @Input() noHeader = false;
  @Input() loading = false;

  @Output() close = new EventEmitter<void>();

  modalRef: NgbModalRef | undefined;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.modalRef = this.modalService.open(this.content, {
      ariaLabelledBy: 'modal-basic-title',
      size: this.size,
      windowClass: this.classModal,
      backdropClass: this.classBgModal,
      centered: this.centered,
      backdrop: this.disableOverlayClose ? 'static' : undefined,
    });
    this.modalRef.result.then(
      () => {
        this.onCloseButton();
      },
      () => {
        this.onCloseButton();
      }
    );
  }
  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    } else {
      this.modalService.dismissAll();
    }
  }

  onCloseButton(): void {
    this.close.emit();
  }
  onOverlayButton(): void {
    if (!this.disableOverlayClose) {
      this.close.emit();
    }
  }
}
