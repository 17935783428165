import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DefaultComponent } from '@emma-components/default.component';
import { EMMAUIModule } from '@emma-components/emmaui.module';
import { EMMAFormModule } from '@emma-components/forms/emma-form.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { HrefPreventDefaultDirective } from '@emma-directives/href-prevent-default.directive';

import { ScrollTopComponent } from '@platform/components/scroll-top/scroll-top.component';
import { AsideLeftDisplayDisabledComponent } from './aside-left-display-disabled/aside-left-display-disabled.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { QuickSidebarComponent } from './quick-sidebar/quick-sidebar.component';

@NgModule({
  declarations: [
    AsideLeftDisplayDisabledComponent,
    DefaultComponent,
    FooterComponent,
    HrefPreventDefaultDirective,
    LayoutComponent,
    QuickSidebarComponent,
    ScrollTopComponent,
  ],
  exports: [
    AsideLeftDisplayDisabledComponent,
    DefaultComponent,
    FooterComponent,
    HrefPreventDefaultDirective,
    LayoutComponent,
    QuickSidebarComponent,
    ScrollTopComponent,
  ],
  imports: [CommonModule, EMMADirectivesModule, EMMAFormModule, EMMAUIModule, FormsModule, RouterModule],
})
export class LayoutModule {}
