import { environment } from '@emma-environments/environment';
import { noop } from 'lodash';

export class ConsoleLogHelper {
  constructor(public prefix: string) {}
  log = environment.production ? noop : (...params: unknown[]) => console.log(this.prefix, '>>', ...params);
  info = (...params: unknown[]): void => console.warn(this.prefix, '>>', ...params);
  warn = (...params: unknown[]): void => console.warn(this.prefix, '>>', ...params);
  error = (...params: unknown[]): void => console.error(this.prefix, '>>', ...params);
}
