import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BannerNotificationsService } from '@platform/services/banner-notifications.service';

import { BannerNotification, NotificationLevel } from '@platform/types/notification.model';
import {
  PlatformNotificationActionsRightTemplateDirective,
  PlatformNotificationDismissButtonTemplateDirective,
} from './platform-notification-feedback.directive';

@Component({
  selector: 'platform-notification-feedback',
  templateUrl: './platform-notification-feedback.component.html',
})
export class PlatformNotificationFeedbackComponent {
  @Input() notifications: BannerNotificationsService[] = [];
  @Input() notification?: BannerNotificationsService;
  NOTIFICATION_LEVEL = NotificationLevel;
  /** Template de acción a la derecha */
  @ContentChild(PlatformNotificationActionsRightTemplateDirective, { read: TemplateRef, static: false })
  notificationActionsRightTemplateDirective!: TemplateRef<any>;
  /** Template botón cerrar notificación */
  @ContentChild(PlatformNotificationDismissButtonTemplateDirective, { read: TemplateRef, static: false })
  notificationDismissButtonTemplateDirective!: TemplateRef<any>;

  constructor(private notificationsService: BannerNotificationsService) {}

  onDismiss(notification: BannerNotification) {
    this.notificationsService.delete(notification.key);
  }

  onDontShowMore(notification: BannerNotification) {
    this.notificationsService.setDontShowMore(notification.key);
  }

  onContactButton(notification: BannerNotification) {
    this.onDismiss(notification);
    window.open(notification.helpLinkUrl, '_blank');
  }
}
