import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { PermissionFlagsService } from '@emma-services/permission-flags.service';
import { PERMISSION_BOOL_OP, PERMISSION_ID } from 'emma-common-ts/emma';

@Directive({
  selector: '[canEdit]',
})
export class CanEditDirective implements OnChanges {
  private operator: PERMISSION_BOOL_OP = PERMISSION_BOOL_OP.AND;
  private permissionId: PERMISSION_ID | Array<PERMISSION_ID> = [];
  private disabledPrevState?: boolean;
  private readonlyPrevState?: boolean;

  constructor(
    private element: ElementRef,
    // private templateRef: TemplateRef<any>,
    // private viewContainer: ViewContainerRef,
    private permissionService: PermissionFlagsService
  ) {}

  ngOnChanges(): void {
    this.disabledPrevState = this.element.nativeElement.disabled;
    this.readonlyPrevState = this.element.nativeElement.readonly;
  }

  @Input() set canEdit(permissionId: PERMISSION_ID | Array<PERMISSION_ID>) {
    this.permissionId = permissionId;
    this.updateView();
  }
  @Input() set canEditOperation(op: string) {
    this.operator = op as PERMISSION_BOOL_OP;
    this.updateView();
  }

  private updateView() {
    if (this.permissionService.canWrite(this.permissionId, this.operator)) {
      // Reset attributes readonly, disabled
      this.element.nativeElement.disabled = this.disabledPrevState;
      this.element.nativeElement.readonly = this.readonlyPrevState;
      this.element.nativeElement.classList.remove('disabled');
    } else {
      // Set attributes readonly, disabled
      this.element.nativeElement.disabled = true;
      this.element.nativeElement.readonly = true;
      this.element.nativeElement.classList.add('disabled');
    }
  }
}
