import { Pipe, PipeTransform } from '@angular/core';
import { FormatNumberOptions, formatNumber } from '@emma-helpers/format.helper';

/*
 * Format number to locale
 * Usage:
 *   value | formatNumber
 *   value | formatNumber:style
 * Example:
 *   {{ 2000.45 | formatNumber }}
 *   formats to: 2.000,45
 */
@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
  transform(value: number | string | null | undefined, options?: string): string {
    if (value === undefined) {
      return '';
    } else if (value === null) {
      return '-';
    }

    let opts: FormatNumberOptions = {};
    if (options) {
      opts = JSON.parse(options);
    }

    return formatNumber(Number(value || 0), opts);
  }
}
