import { Injectable } from '@angular/core';
import { LocalStorageService as PlatformLocalStorageService } from '@platform/services/local-storage.service';
import { EMMAUserAppInfo, User } from 'emma-common-ts/emma';

export enum LOCAL_STORAGE_KEY {
  CURRRENT_APP = 'currentApp',
  CURRENT_USER = 'currentUser',
  LATEST_DASHBOARD = 'latestDashboard',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends PlatformLocalStorageService {
  constructor() {
    super('emmaBuild');
  }

  override get(key: LOCAL_STORAGE_KEY.CURRRENT_APP): EMMAUserAppInfo | undefined;
  override get(key: LOCAL_STORAGE_KEY.CURRENT_USER): User | undefined;
  override get(key: LOCAL_STORAGE_KEY.LATEST_DASHBOARD): number | undefined;
  override get<T>(key: LOCAL_STORAGE_KEY): T | undefined {
    return super.get<T>(key);
  }

  override set(key: LOCAL_STORAGE_KEY.CURRRENT_APP, value: EMMAUserAppInfo): void;
  override set(key: LOCAL_STORAGE_KEY.CURRENT_USER, value: User): void;
  override set(key: LOCAL_STORAGE_KEY.LATEST_DASHBOARD, value: number): void;
  override set<T>(key: LOCAL_STORAGE_KEY, value: T): void {
    return super.set<T>(key, value);
  }

  override remove(key: LOCAL_STORAGE_KEY): void {
    return super.remove(key);
  }
}
