import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EMMAAppComponent } from './emma-app.component';
import { HeaderNavModule } from './layouts/header-nav/headre-nav.module';
import { LayoutModule } from './layouts/layout.module';
import { EMMAScriptInjectorsModule } from '../_script-injectors/script-injectors.module';

@NgModule({
  declarations: [EMMAAppComponent],
  imports: [CommonModule, HeaderNavModule, LayoutModule, RouterModule, EMMAScriptInjectorsModule],
  exports: [EMMAAppComponent],
  providers: [],
})
export class EMMAAppModule {}
