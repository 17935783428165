<div [class.input-group]="showTag">
    <div [class.input-group-slider]="showTag">
        <ngx-slider
            *ngIf="!isRange"
            [ngClass]="sliderClass"
            [value]="value"
            (valueChange)="onValueChange($event)"
            (userChangeStart)="$changeStart.emit($event)"
            (userChangeEnd)="$changeEnd.emit($event)"
            [options]="sliderOptions"
            [manualRefresh]="manualRefresh"
        ></ngx-slider>
        <ngx-slider
            *ngIf="isRange"
            [ngClass]="sliderClass"
            [value]="value"
            (valueChange)="onValueChange($event)"
            [highValue]="highValue"
            (highValueChange)="onHighValueChange($event)"
            (userChangeStart)="$changeStart.emit($event)"
            (userChangeEnd)="$changeEnd.emit($event)"
            [options]="sliderOptions"
            [manualRefresh]="manualRefresh"
        ></ngx-slider>
    </div>
    <div *ngIf="showTag" class="input-group-text input-group-text-transparent input-group-slider-value">
        <label
            [ngClass]="{
                'reverse-tag': reverseTag
            }"
        >
            <strong>{{ value }}<ng-container *ngIf="isPercent">%</ng-container></strong>
            <span *ngIf="unitsTag">{{ unitsTag }}</span> 
        </label>
    </div>
</div>
