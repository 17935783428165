import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'emma-portlet',
  templateUrl: './emma-portlet.component.html',
})
export class EMMAPortletComponent {
  @Input()
  portletTitle = '';
  @Input()
  titlePopover = '';
  @Input()
  description = '';
  @Input()
  sticky = false;
  @Input()
  stickyPosition?: '' | 'filterbar' | 'top' = '';
  @Input()
  hasWizard = false;
  @Input()
  missing = '';
  @Input()
  class = '';
  @Input()
  hasForm = false;
  @Input()
  hasSidebar = false;
  @Input()
  hasData = true;
  @Input()
  isLoading = false;
  @Input()
  backButton: boolean | string = false;
  @Input() showPadding = true;
  @Input() showMargin = true;
  @Input() isError = false;
  @Input()
  href = '';

  constructor(private router: Router) {}

  setHasData(hasIt: boolean) {
    this.hasData = hasIt;
  }

  goBack() {
    if (this.backButton) {
      if ('string' === typeof this.backButton) {
        this.router.navigateByUrl(this.backButton).catch(console.error);
      } else {
        window.history.back();
      }
    }
  }
}
