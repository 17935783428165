import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { jQueryToggleClassDuringAnimation, setGlobalLoading } from '@emma-helpers/legacy-jquery-utils.helper';
import { printVersion } from '@emma-helpers/print-version.helper';
import { GtmDataLayerService } from '@emma-services/gtm-data-layer.service';
import { UserService } from '@emma-services/user.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { AuthenticationService } from '@platform/services/authentication.service';
import { loadDayjsLocale } from '@platform/services/locale.service';
import { takeUntil } from 'rxjs/operators';

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;

@Component({
  selector: 'emma-app-root',
  templateUrl: './app.component.html',
  providers: [NgbPopoverConfig],
})
export class AppComponent extends OnUnsubscribe implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private gtmDataLayerService: GtmDataLayerService,
    config: NgbPopoverConfig
  ) {
    super();
    // customize default values of popovers used by this component tree
    config.placement = 'top';
    config.triggers = 'hover';
    config.popoverClass = 'm-popover m-popover--skin-dark popover';
  }

  ngOnInit(): void {
    this.document.documentElement.lang = this.locale;
    loadDayjsLocale(this.locale);
    printVersion();
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        setGlobalLoading(true);
        mLayout?.closeMobileAsideMenuOffcanvas();
        mLayout?.closeMobileHorMenuOffcanvas();
      } else if (route instanceof NavigationEnd) {
        mApp?.init();
        mUtil?.init();
        this.gtmDataLayerService.logPageView(route.url);
        jQueryToggleClassDuringAnimation('.m-wrapper', 'm-animate-fade-in-up');
        setGlobalLoading(false);
      }
    });

    // Remove user from local storage when logout
    this.authService.logout$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.userService.removeUser();
    });
  }
}
