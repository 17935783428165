import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { validatorCreditCardCVC, validatorCreditCardNumber } from '@emma-helpers/emma-validators.helper';
import { PlanService } from '@emma-services/plan.service';
import { UserService } from '@emma-services/user.service';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { CountriesService } from '@platform/services/countries.service';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { SelectOption } from '@platform/types';
import { STATUS } from 'emma-common-ts';
import {
  AccountPlanConfigurationV2,
  MIN_PASSWORD_LENGTH,
  PLAN_TYPE,
  Plan,
  PlanConfigurationV2,
  ROUTE,
  SignUpData,
  isEmailInAccountFederated,
  isPlanConfigurationV2,
} from 'emma-common-ts/emma';
import { orderBy } from 'lodash';
import { takeUntil } from 'rxjs/operators';

import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { getNotificationsPassword } from '@platform/helpers/auth.helper';
import {
  addNotSelectablePermissions,
  calculateMausPrice,
  getAllVolumenMaus,
  getDefaultAccountPlanConfigV2,
} from '../../user/plan/user-plan.helper';
import { AUTH_TYPE } from '../auth-wrapper.component';

@Component({
  selector: 'emma-register-page',
  templateUrl: './register.component.html',
})
export class RegisterPageComponent extends OnUnsubscribe implements OnInit {
  @ViewChild('form', { static: false }) public form!: NgForm;

  MIN_PASSWORD_LENGTH = MIN_PASSWORD_LENGTH;

  validateCreditCardNumber = validatorCreditCardNumber;
  validateCreditCardCVC = validatorCreditCardCVC;

  loading = new Set<string>();
  type = AUTH_TYPE.REGISTER;
  signUpData: Partial<SignUpData> = {
    agree: false,
  };
  steps = {
    EMAIL: 1,
    CONTACT: 2,
    CARD: 3,
  };
  currentStep = this.steps.EMAIL;

  typecard = 'default';
  planLocalize = $localize`Plan`;

  countries: SelectOption[] = [];
  selectablePlans: SelectOption[] = [];
  plans: Plan[] = [];
  allVolumeMaus: SelectOption<number>[] = [];
  selectedPlan: Plan | undefined;
  selectedPlanType: PLAN_TYPE = PLAN_TYPE.GROWTH;
  planConfiguration: AccountPlanConfigurationV2 = getDefaultAccountPlanConfigV2();
  maxLimitedMaus = 100000;

  discardedPlans = [PLAN_TYPE.ENTERPRISE];
  months: number[] = [];
  years: number[] = [];
  federationErrorMsg: string | undefined;

  ROUTE = ROUTE;

  isPasswordValid = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private countriesService: CountriesService,
    public notifications: ToastNotificationsService,
    private planService: PlanService
  ) {
    super();
    this.allVolumeMaus = (getAllVolumenMaus() as SelectOption<number>[]).filter(
      (volumeMaus) => volumeMaus.value <= this.maxLimitedMaus
    );
  }

  ngOnInit(): void {
    for (let i = 1; i <= 12; ++i) {
      this.months.push(i);
    }
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 10; ++i) {
      this.years.push(currentYear + i);
    }
    this.countriesService
      .getCountriesOptions()
      .pipe(apiFeedback('countries', this.notifications, this.loading), takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.countries = res;
      });

    this.loading.add('plans');
    this.planService
      .getAll()
      .pipe(apiFeedback('plans', this.notifications, this.loading), takeUntil(this.ngUnsubscribe))
      .subscribe((planResponse) => {
        this.plans = orderBy(planResponse?.data || [], 'order', 'asc').filter(
          (plan) => isPlanConfigurationV2(plan.configuration) && !this.discardedPlans.includes(plan.planType)
        );

        this.selectablePlans = this.plans.map((plan) => {
          return {
            label: `${this.planLocalize} ${plan.name}`,
            value: plan.planType,
          };
        });

        this.selectedPlan = this.plans.length ? this.plans[0] : undefined;
        if (this.selectedPlan) {
          this.selectedPlanType = this.selectedPlan.planType;
          this.calculatePlanPrice();
        }
      });
  }

  private getCleanData(): SignUpData {
    const data = this.signUpData as SignUpData;
    for (const key of Object.keys(data)) {
      if ('string' === typeof data[key]) {
        data[key] = data[key].trim();
      }
    }
    const signUpData = {
      ...data,
      cardNumber: data.cardNumber.replace(' ', ''),
    };

    if (this.selectedPlan?.id) {
      addNotSelectablePermissions(this.selectedPlan.planType, this.planConfiguration);
      return {
        ...signUpData,
        plan: {
          planId: this.selectedPlan.id,
          configuration: this.planConfiguration,
        },
      };
    }
    return signUpData;
  }

  onSignup(): void {
    this.loading.add('signup');
    this.userService
      .signUp(this.getCleanData())
      .pipe(apiFeedback('signup', this.notifications, this.loading))
      .subscribe((data) => {
        if (data.status === STATUS.SUCCESS) {
          this.notifications.setSuccess($localize`¡Guay! Comprueba tu correo para confirmar tu cuenta.`);
        }
        this.router.navigateByUrl(ROUTE.LOGIN).catch(console.error);
      });
  }

  isValid(): boolean {
    return Boolean(this.form?.valid);
  }

  nextStep(): void {
    if (this.currentStep === this.steps.EMAIL && this.signUpData.email) {
      this.loading.add('verifyEmail');
      this.userService
        .verifyIfUserEmailExists(this.signUpData.email)
        .pipe(apiFeedback('verifyEmail', this.notifications, this.loading), takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          if (res.data?.isRegistered) {
            this.notifications.setError($localize`El email ya está registrado.`);
          } else {
            this.currentStep += 1;
          }
        });
    } else {
      this.currentStep += 1;
    }
  }

  calculatePlanPrice(): void {
    if (this.selectedPlan) {
      const configuration = this.selectedPlan?.configuration as PlanConfigurationV2;
      this.planConfiguration.totalPrice = calculateMausPrice(
        this.planConfiguration.retentionAdquiredTotal,
        configuration.retentionRanges
      );
    }
  }

  onSelectedPlanChanged(planType: PLAN_TYPE): void {
    this.selectedPlan = this.plans.find((plan) => plan.planType === planType);
    this.calculatePlanPrice();
  }

  onMausChanged(): void {
    this.calculatePlanPrice();
  }

  onEmailChange() {
    if (this.signUpData.email) {
      this.federationErrorMsg = undefined;
      if (isEmailInAccountFederated(this.signUpData.email)) {
        this.form.form.controls['email'].setErrors({ federation: true });
        this.federationErrorMsg = $localize`Este dominio pertenece a una cuenta gestionada por otra empresa.`;
      }
    }
  }

  showPasswordWarning() {
    const errors = getNotificationsPassword(MIN_PASSWORD_LENGTH, this.signUpData.password ?? '');
    const activeNotifications = this.notifications.getActives();
    console.log(activeNotifications);
    activeNotifications.forEach((notification) => {
      if (!errors.includes(notification.message)) {
        this.notifications.remove(notification.toastId);
      }
    });
    this.isPasswordValid = errors.length === 0;
    if (!this.isPasswordValid) {
      errors.forEach((error) => {
        this.notifications.setError(error, undefined, {
          easeTime: 0,
        });
      });
    }
  }

  validatePassword(control: AbstractControl) {
    if (control.value) {
      const errors = getNotificationsPassword(MIN_PASSWORD_LENGTH, control.value);
      return errors.length === 0 ? null : { passwordError: true };
    }
    return { passwordError: true };
  }
}
