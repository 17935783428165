import { Injectable } from '@angular/core';
import { join, kebabCase, map, sortBy, toPairs } from 'lodash';

import { SmartStore } from './smart-store.class';

@Injectable({ providedIn: 'root' })
export class SmartStoreService {
  protected stores: { [k: string]: SmartStore<any> } = {};
  protected debugMode = false;

  static generateStoreName(d: any): string {
    if (typeof d === 'string') {
      return d;
    } else if (typeof d === 'object') {
      let data = d;
      if (!Array.isArray(d)) {
        data = toPairs(data);
      }
      data = data.flat(Infinity);
      data = map(data, (v) => (typeof v === 'object' ? SmartStoreService.generateStoreName(v) : v));
      data = sortBy(data);
      data = join(data, '--');
      data = kebabCase(data);
      return data;
    } else {
      return String(d);
    }
  }
  setDebugMode(dbg: boolean): void {
    this.debugMode = dbg;
  }
  get<T>(storeName: string): SmartStore<T> {
    if (!this.stores[storeName]) {
      this.stores[storeName] = new SmartStore<T>(storeName);
      if (this.debugMode) {
        console.log('Created', storeName, this.stores[storeName]);
      }
    } else if (this.debugMode) {
      console.log('Stored', storeName, this.stores[storeName]);
    }
    return this.stores[storeName] as SmartStore<T>;
  }

  // TODO: Clean old stores without subscribers
}
