<div class="emmaLoading {{ error ? 'emmaLoading--error' : '' }}">
    <div class="emmaLoading-overlay"></div>
    <div class="emmaLoading-spinner">
        <div *ngIf="!error" class="m-loader m-loader--emma m-loader--lg"></div>
        <div *ngIf="error || message">
            <img class="svgImage" src="assets/media/emma/img/icon/alertIcon.svg" alt="Alerta" i18n-alt />
            <p i18n>Vaya, esto es embarazoso...</p>
            <p class="emmaLoading-message">{{ error || message }}</p>
        </div>
    </div>
</div>
