import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionFlagsService } from '@emma-services/permission-flags.service';
import { PERMISSION_ID, PERMISSION_TYPE } from 'emma-common-ts/emma';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoutePermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private permissionsService: PermissionFlagsService,
    private router: Router
  ) {}

  canActivateChild = this.canActivate;

  /**
   * Check if the route is disallowed, level by level, from root to full path.
   * @param path Current URL
   */
  isRouteEnabled(path: string): boolean {
    // Known public routes
    if (['/', '/index', '/login', '/logout', '/register', '/forgot-password'].includes(path)) {
      return true;
    }
    const routePaths = path.split('/').filter((r) => r !== 'legacy');
    // Why 2? To skip root empty path.
    for (let i = routePaths.length; i >= 2; --i) {
      const incrementalPath = routePaths.slice(0, i).join('/');
      const permissionId = `${PERMISSION_TYPE.ROUTE}__${incrementalPath}` as PERMISSION_ID;
      if (!this.permissionsService.canWrite(permissionId)) {
        return false;
      }
    }
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const queryParamsIdx = state.url.indexOf('?');
    const path = queryParamsIdx < 0 ? state.url : state.url.substring(0, queryParamsIdx);
    if (this.isRouteEnabled(path)) {
      return true;
    }
    this.router.navigateByUrl('/').catch(console.error);
    return false;
  }
}
