<emma-auth-wrapper [type]="type">
    <div class="m-grid m-grid--ver m-grid__item m-grid__item--fluid m-login--emma_container">
        <div
            class="m-grid m-grid--hor m-grid--root m-grid__item m-grid__item--fluid m-login--emma_side-right"
        >
            <div class="emma-form-header">
                <div class="m-login__logo login-layout">
                    <a href="https://emma.io" target="_self">
                        <img src="assets/media/emma/img/logo/emma-logo-darkBlue.svg" alt="EMMA" i18n-alt />
                    </a>
                </div>
            </div>
            <div
                class="m-grid m-grid--hor m-grid__item m-grid__item--fluid justify-content-center emma-form-content"
            >
                <form (submit)="onSubmit($event)">
                    <div class="m-login__form m-form">
                        <h3 i18n>¿Contraseña olvidada?</h3>
                        <div emma-label label="Email de la cuenta" i18n-label>
                            <emma-input
                                type="email"
                                inputmode="email"
                                placeholder="email@dominio.com"
                                [(ngModel)]="emailText"
                                [required]="true"
                                name="email"
                                autocomplete="off"
                            ></emma-input>
                        </div>
                        <div class="m-login__form-action text-left">
                            <button
                                type="button"
                                emmaButton
                                [routerLink]="ROUTE.LOGIN"
                                [disabled]="loading.size"
                                class="m--margin-right-10"
                                i18n
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                class="float-right"
                                emmaButton
                                [disabled]="loading.size"
                                [disabled]="loading.size || !isValid()"
                                [color]="'altgreen'"
                            >
                                <strong i18n>Restablecer contraseña</strong>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</emma-auth-wrapper>
