<emma-script-injector-google-sign-in></emma-script-injector-google-sign-in>
<div
    class="m-page m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--emma m-login--emma_fullpage m-login--signin fullheight"
    id="m_login"
>
    <div
        [ngClass]="{
            'm-grid__item m-grid m-grid--center m-grid--ver m-login__content': true,
            'simple-container': type !== AUTH_TYPE.REGISTER
        }"
    >
        <div class="align-self-stretch">
            <ng-content></ng-content>
        </div>
    </div>
</div>
