export interface Timezone {
  identifier: string;
  names: { [k: string]: string };
  description: string;
  offset: string;
}

export const timezones: Timezone[] = [
  {
    identifier: 'Pacific/Midway',
    names: {
      en: 'Pacific/Midway',
      es: 'Pacífico/Central',
    },
    description: 'International Date Line West, Midway Island',
    offset: 'UTC -11:00',
  },
  {
    identifier: 'Pacific/Pago_Pago',
    names: {
      en: 'Pacific/Pago_Pago',
      es: 'Pacífico/Pago_Pago',
    },
    description: 'American Samoa',
    offset: 'UTC -11:00',
  },
  {
    identifier: 'Pacific/Honolulu',
    names: {
      en: 'Pacific/Honolulu',
      es: 'Pácifico/Honolulu',
    },
    description: 'Hawai',
    offset: 'UTC -10:00',
  },
  {
    identifier: 'America/Juneau',
    names: {
      en: 'America/Juneau',
      es: 'América/Juneau',
    },
    description: 'Alaska',
    offset: 'UTC -08:00',
  },
  {
    identifier: 'America/Los_Angeles',
    names: {
      en: 'America/Los_Angeles',
      es: 'América/Los_Angeles',
    },
    description: 'Pacific Time (US & Canada)',
    offset: 'UTC -07:00',
  },
  {
    identifier: 'America/Phoenix',
    names: {
      en: 'America/Phoenix',
      es: 'América/Phoenix',
    },
    description: 'Arizona',
    offset: 'UTC -07:00',
  },
  {
    identifier: 'America/Tijuana',
    names: {
      en: 'America/Tijuana',
      es: 'América/Tijuana',
    },
    description: 'Tijuana',
    offset: 'UTC -07:00',
  },
  {
    identifier: 'America/Denver',
    names: {
      en: 'America/Denver',
      es: 'América/Denver',
    },
    description: 'Mountain Time (US & Canada)',
    offset: 'UTC -06:00',
  },
  {
    identifier: 'America/Chihuahua',
    names: {
      en: 'America/Chihuahua',
      es: 'América/Chihuahua',
    },
    description: 'Chihuahua',
    offset: 'UTC -06:00',
  },
  {
    identifier: 'America/Mazatlan',
    names: {
      en: 'America/Mazatlan',
      es: 'América/Mazatlan',
    },
    description: 'Mazatlan',
    offset: 'UTC -06:00',
  },
  {
    identifier: 'America/Regina',
    names: {
      en: 'America/Regina',
      es: 'América/Regina',
    },
    description: 'Saskatchewan',
    offset: 'UTC -06:00',
  },
  {
    identifier: 'America/Guatemala',
    names: {
      en: 'America/Guatemala',
      es: 'América/Guatemala',
    },
    description: 'Central America',
    offset: 'UTC -06:00',
  },
  {
    identifier: 'America/Mexico_City',
    names: {
      en: 'America/Mexico_City',
      es: 'América/Mexico_City',
    },
    description: 'Guadalajara, Mexico City',
    offset: 'UTC -05:00',
  },
  {
    identifier: 'America/Monterrey',
    names: {
      en: 'America/Monterrey',
      es: 'América/Monterrey',
    },
    description: 'Monterrey',
    offset: 'UTC -05:00',
  },
  {
    identifier: 'America/Chicago',
    names: {
      en: 'America/Chicago',
      es: 'América/Chicago',
    },
    description: 'Central Time (US & Canada)',
    offset: 'UTC -05:00',
  },
  {
    identifier: 'America/Lima',
    names: {
      en: 'America/Lima',
      es: 'América/Lima',
    },
    description: 'Lima, Quito',
    offset: 'UTC -05:00',
  },
  {
    identifier: 'America/Bogota',
    names: {
      en: 'America/Bogota',
      es: 'América/Bogotá',
    },
    description: 'Bogota',
    offset: 'UTC -05:00',
  },
  {
    identifier: 'America/Indiana/Indianapolis',
    names: {
      en: 'America/Indiana/Indianapolis',
      es: 'América/Indiana/Indianápolis',
    },
    description: 'Indiana (East)',
    offset: 'UTC -04:00',
  },
  {
    identifier: 'America/New_York',
    names: {
      en: 'America/New_York',
      es: 'America/Nueva_York',
    },
    description: 'Eastern Time (US & Canada)',
    offset: 'UTC -04:00',
  },
  {
    identifier: 'America/Guyana',
    names: {
      en: 'America/Guyana',
      es: 'América/Guyana',
    },
    description: 'Georgetown',
    offset: 'UTC -04:00',
  },
  {
    identifier: 'America/La_Paz',
    names: {
      en: 'America/La_Paz',
      es: 'América/La_Paz',
    },
    description: 'La Paz',
    offset: 'UTC -04:00',
  },
  {
    identifier: 'America/Caracas',
    names: {
      en: 'America/Caracas',
      es: 'América/Caracas',
    },
    description: 'Caracas',
    offset: 'UTC -04:30',
  },
  {
    identifier: 'America/Santiago',
    names: {
      en: 'America/Santiago',
      es: 'América/Santiago',
    },
    description: 'Santiago',
    offset: 'UTC -03:00',
  },
  {
    identifier: 'America/Halifax',
    names: {
      en: 'America/Halifax',
      es: 'América/Halifax',
    },
    description: 'Atlantic Time (Canada)',
    offset: 'UTC -03:00',
  },
  {
    identifier: 'America/Sao_Paulo',
    names: {
      en: 'America/Sao_Paulo',
      es: 'América/Sao_Paulo',
    },
    description: 'Brasilia',
    offset: 'UTC -03:00',
  },
  {
    identifier: 'America/Montevideo',
    names: {
      en: 'America/Montevideo',
      es: 'América/Montevideo',
    },
    description: 'Montevideo',
    offset: 'UTC -03:00',
  },
  {
    identifier: 'America/Argentina/Buenos_Aires',
    names: {
      en: 'America/Argentina/Buenos_Aires',
      es: 'América/Argentina/Buenos_Aires',
    },
    description: 'Buenos Aires',
    offset: 'UTC -03:00',
  },
  {
    identifier: 'America/St_Johns',
    names: {
      en: 'America/St_Johns',
      es: 'América/St_Johns',
    },
    description: 'Newfoundland',
    offset: 'UTC -02:30',
  },
  {
    identifier: 'America/Godthab',
    names: {
      en: 'America/Godthab',
      es: 'América/Godthab',
    },
    description: 'Greenland',
    offset: 'UTC -02:00',
  },
  {
    identifier: 'Atlantic/South_Georgia',
    names: {
      en: 'Atlantic/South_Georgia',
      es: 'Atlántico/South_Georgia',
    },
    description: 'Mid-Atlantic',
    offset: 'UTC -02:00',
  },
  {
    identifier: 'Atlantic/Cape_Verde',
    names: {
      en: 'Atlantic/Cape_Verde',
      es: 'Atlántico/Cabo_Verde',
    },
    description: 'Cape Verde Is.',
    offset: 'UTC -01:00',
  },
  {
    identifier: 'Etc/UTC',
    names: {
      en: 'Etc/UTC',
    },
    description: 'UTC',
    offset: 'UTC +00:00',
  },
  {
    identifier: 'Atlantic/Azores',
    names: {
      en: 'Atlantic/Azores',
      es: 'Atlántico/Azores',
    },
    description: 'Azores',
    offset: 'UTC +00:00',
  },
  {
    identifier: 'Africa/Monrovia',
    names: {
      en: 'Africa/Monrovia',
      es: 'África/Monrovia',
    },
    description: 'Monrovia',
    offset: 'UTC +00:00',
  },
  {
    identifier: 'Europe/Lisbon',
    names: {
      en: 'Europe/Lisbon',
      es: 'Europa/Lisboa',
    },
    description: 'Lisbon',
    offset: 'UTC +01:00',
  },
  {
    identifier: 'Africa/Casablanca',
    names: {
      en: 'Africa/Casablanca',
      es: 'África/Casablanca',
    },
    description: 'Casablanca',
    offset: 'UTC +01:00',
  },
  {
    identifier: 'Europe/London',
    names: {
      en: 'Europe/London',
      es: 'Europa/Londres',
    },
    description: 'Edinburgh, London',
    offset: 'UTC +01:00',
  },
  {
    identifier: 'Europe/Dublin',
    names: {
      en: 'Europe/Dublin',
      es: 'Europa/Dublín',
    },
    description: 'Dublin',
    offset: 'UTC +01:00',
  },
  {
    identifier: 'África/Algiers',
    names: {
      en: 'Africa/Algiers',
      es: 'África/Argelia',
    },
    description: 'West Central Africa',
    offset: 'UTC +01:00',
  },
  {
    identifier: 'Europe/Skopje',
    names: {
      en: 'Europe/Skopje',
      es: 'Europa/Skopje',
    },
    description: 'Skopje',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Budapest',
    names: {
      en: 'Europe/Budapest',
      es: 'Europa/Budapest',
    },
    description: 'Budapest',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Ljubljana',
    names: {
      en: 'Europe/Ljubljana',
      es: 'Europa/Ljubljana',
    },
    description: 'Ljubljana',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Prague',
    names: {
      en: 'Europe/Prague',
      es: 'Europa/Praga',
    },
    description: 'Prague',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Sarajevo',
    names: {
      en: 'Europe/Sarajevo',
      es: 'Europa/Sarajevo',
    },
    description: 'Sarajevo',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Bratislava',
    names: {
      en: 'Europe/Bratislava',
      es: 'Europa/Bratislava',
    },
    description: 'Bratislava',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Warsaw',
    names: {
      en: 'Europe/Warsaw',
      es: 'Europa/Varsovia',
    },
    description: 'Warsaw',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Zagreb',
    names: {
      en: 'Europe/Zagreb',
      es: 'Europa/Zagreb',
    },
    description: 'Zagreb',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Brussels',
    names: {
      en: 'Europe/Brussels',
      es: 'Europa/Bruselas',
    },
    description: 'Brussels',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Copenhagen',
    names: {
      en: 'Europe/Copenhagen',
      es: 'Europa/Copenhagen',
    },
    description: 'Copenhagen',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Madrid',
    names: {
      en: 'Europe/Madrid',
      es: 'Europa/Madrid',
    },
    description: 'Madrid',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Paris',
    names: {
      en: 'Europe/Paris',
      es: 'Europa/París',
    },
    description: 'Paris',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Amsterdam',
    names: {
      en: 'Europe/Amsterdam',
      es: 'Europa/Amsterdam',
    },
    description: 'Amsterdam',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Berlin',
    names: {
      en: 'Europe/Berlin',
      es: 'Europa/Berlín',
    },
    description: 'Berlin, Bern',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Rome',
    names: {
      en: 'Europe/Rome',
      es: 'Europa/Roma',
    },
    description: 'Rome',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Stockholm',
    names: {
      en: 'Europe/Stockholm',
      es: 'Europa/Estocolmo',
    },
    description: 'Stockholm',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Vienna',
    names: {
      en: 'Europe/Vienna',
      es: 'Europa/Viena',
    },
    description: 'Vienna',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Africa/Harare',
    names: {
      en: 'Africa/Harare',
      es: 'África/Harare',
    },
    description: 'Harare',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Africa/Johannesburg',
    names: {
      en: 'Africa/Johannesburg',
      es: 'África/Johannesburg',
    },
    description: 'Pretoria',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Belgrade',
    names: {
      en: 'Europe/Belgrade',
      es: 'Europa/Belgrado',
    },
    description: 'Belgrade',
    offset: 'UTC +02:00',
  },
  {
    identifier: 'Europe/Helsinki',
    names: {
      en: 'Europe/Helsinki',
      es: 'Europa/Helsinki',
    },
    description: 'Helsinki',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Kiev',
    names: {
      en: 'Europe/Kiev',
      es: 'Europa/Kiev',
    },
    description: 'Kyiv',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Riga',
    names: {
      en: 'Europe/Riga',
      es: 'Europa/Riga',
    },
    description: 'Riga',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Sofia',
    names: {
      en: 'Europe/Sofia',
      es: 'Europa/Sofía',
    },
    description: 'Sofia',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Tallinn',
    names: {
      en: 'Europe/Tallinn',
      es: 'Europa/Tallinn',
    },
    description: 'Tallinn',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Asia/Tehran',
    names: {
      en: 'Asia/Tehran',
      es: 'Asia/Teherán',
    },
    description: 'Tehran',
    offset: 'UTC +03:30',
  },
  {
    identifier: 'Europe/Athens',
    names: {
      en: 'Europe/Athens',
      es: 'Europa/Atenas',
    },
    description: 'Athens',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Istanbul',
    names: {
      en: 'Europe/Istanbul',
      es: 'Europa/Estanbul',
    },
    description: 'Istanbul',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Minsk',
    names: {
      en: 'Europe/Minsk',
      es: 'Europa/Minsk',
    },
    description: 'Minsk',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Asia/Jerusalem',
    names: {
      en: 'Asia/Jerusalem',
      es: 'Asia/Jerusalén',
    },
    description: 'Jerusalem',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Africa/Cairo',
    names: {
      en: 'Africa/Cairo',
      es: 'África/Cairo',
    },
    description: 'Cairo',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Bucharest',
    names: {
      en: 'Europe/Bucharest',
      es: 'Europa/Bucarest',
    },
    description: 'Bucharest',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Moscow',
    names: {
      en: 'Europe/Moscow',
      es: 'Europa/Moscú',
    },
    description: 'Moscow, St. Petersburg, Volgograd',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Asia/Kuwait',
    names: {
      en: 'Asia/Kuwait',
      es: 'Asia/Kuwait',
    },
    description: 'Kuwait',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Asia/Riyadh',
    names: {
      en: 'Asia/Riyadh',
      es: 'Asia/Riyadh',
    },
    description: 'Riyadh',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Africa/Nairobi',
    names: {
      en: 'Africa/Nairobi',
      es: 'África/Nairobi',
    },
    description: 'Nairobi',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Asia/Baghdad',
    names: {
      en: 'Asia/Baghdad',
      es: 'Asia/Bagdad',
    },
    description: 'Baghdad',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Europe/Vilnius',
    names: {
      en: 'Europe/Vilnius',
      es: 'Europa/Vilnius',
    },
    description: 'Vilnius',
    offset: 'UTC +03:00',
  },
  {
    identifier: 'Asia/Muscat',
    names: {
      en: 'Asia/Muscat',
      es: 'Asia/Muscat',
    },
    description: 'Abu Dhabi, Muscat',
    offset: 'UTC +04:00',
  },
  {
    identifier: 'Asia/Kabul',
    names: {
      en: 'Asia/Kabul',
      es: 'Asia/Kabul',
    },
    description: 'Kabul',
    offset: 'UTC +04:30',
  },
  {
    identifier: 'Asia/Tbilisi',
    names: {
      en: 'Asia/Tbilisi',
      es: 'Asia/Tbilisi',
    },
    description: 'Tbilisi',
    offset: 'UTC +04:00',
  },
  {
    identifier: 'Asia/Yerevan',
    names: {
      en: 'Asia/Yerevan',
      es: 'Asia/Yerevan',
    },
    description: 'Yerevan',
    offset: 'UTC +04:00',
  },
  {
    identifier: 'Asia/Yekaterinburg',
    names: {
      en: 'Asia/Yekaterinburg',
      es: 'Asia/Yekaterinburg',
    },
    description: 'Yekaterinburg',
    offset: 'UTC +05:00',
  },
  {
    identifier: 'Asia/Baku',
    names: {
      en: 'Asia/Baku',
      es: 'Asia/Baku',
    },
    description: 'Baku',
    offset: 'UTC +05:00',
  },
  {
    identifier: 'Asia/Karachi',
    names: {
      en: 'Asia/Karachi',
      es: 'Asia/Karachi',
    },
    description: 'Islamabad, Karachi',
    offset: 'UTC +05:00',
  },
  {
    identifier: 'Asia/Colombo',
    names: {
      en: 'Asia/Colombo',
      es: 'Asia/Colombo',
    },
    description: 'Sri Jayawardenepura',
    offset: 'UTC +05:30',
  },
  {
    identifier: 'Asia/Kolkata',
    names: {
      en: 'Asia/Kolkata',
      es: 'Asia/Calcuta',
    },
    description: 'Chennai, Kolkata, Mumbai, New Delhi',
    offset: 'UTC +05:30',
  },
  {
    identifier: 'Asia/Kathmandu',
    names: {
      en: 'Asia/Kathmandu',
      es: 'Asia/Katmandú',
    },
    description: 'Kathmandu',
    offset: 'UTC +05:45',
  },
  {
    identifier: 'Asia/Tashkent',
    names: {
      en: 'Asia/Tashkent',
      es: 'Asia/Tashkent',
    },
    description: 'Tashkent',
    offset: 'UTC +05:00',
  },
  {
    identifier: 'Asia/Dhaka',
    names: {
      en: 'Asia/Dhaka',
      es: 'Asia/Dhaka',
    },
    description: 'Astana, Dhaka',
    offset: 'UTC +06:00',
  },
  {
    identifier: 'Asia/Almaty',
    names: {
      en: 'Asia/Almaty',
      es: 'Asia/Almaty',
    },
    description: 'Almaty',
    offset: 'UTC +06:00',
  },
  {
    identifier: 'Asia/Urumqi',
    names: {
      en: 'Asia/Urumqi',
      es: 'Asia/Urumqi',
    },
    description: 'Urumqi',
    offset: 'UTC +06:00',
  },
  {
    identifier: 'Asia/Rangoon',
    names: {
      en: 'Asia/Urumqi',
      es: 'Asia/Rangún',
    },
    description: 'Rangoon',
    offset: 'UTC +06:30',
  },
  {
    identifier: 'Asia/Novosibirsk',
    names: {
      en: 'Asia/Novosibirsk',
      es: 'Asia/Novosibirsk',
    },
    description: 'Novosibirsk',
    offset: 'UTC +06:00',
  },
  {
    identifier: 'Asia/Jakarta',
    names: {
      en: 'Asia/Jakarta',
      es: 'Asia/Yakarta',
    },
    description: 'Jakarta',
    offset: 'UTC +07:00',
  },
  {
    identifier: 'Asia/Krasnoyarsk',
    names: {
      en: 'Asia/Krasnoyarsk',
      es: 'Asia/Krasnoyarsk',
    },
    description: 'Krasnoyarsk',
    offset: 'UTC +07:00',
  },
  {
    identifier: 'Asia/Bangkok',
    names: {
      en: 'Asia/Bangkok',
      es: 'Asia/Bangkok',
    },
    description: 'Bangkok, Hanoi',
    offset: 'UTC +07:00',
  },
  {
    identifier: 'Asia/Chongqing',
    names: {
      en: 'Asia/Chongqing',
      es: 'Asia/Chongqing',
    },
    description: 'Chongqing',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Hong_Kong',
    names: {
      en: 'Asia/Hong_Kong',
      es: 'Asia/Hong_Kong',
    },
    description: 'Hong Kong',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Shanghai',
    names: {
      en: 'Asia/Shanghai',
      es: 'Asia/Shanghai',
    },
    description: 'Beijing',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Kuala_Lumpur',
    names: {
      en: 'Asia/Kuala_Lumpur',
      es: 'Asia/Kuala_Lumpur',
    },
    description: 'Kuala Lumpur',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Singapore',
    names: {
      en: 'Asia/Singapore',
      es: 'Asia/Singapur',
    },
    description: 'Singapore',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Taipei',
    names: {
      en: 'Asia/Taipei',
      es: 'Asia/Taipei',
    },
    description: 'Taipei',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Ulaanbaatar',
    names: {
      en: 'Asia/Ulaanbaatar',
      es: 'Asia/Ulaanbaatar',
    },
    description: 'Ulaanbaatar',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Irkutsk',
    names: {
      en: 'Asia/Irkutsk',
      es: 'Asia/Irkutsk',
    },
    description: 'Irkutsk',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Australia/Perth',
    names: {
      en: 'Australia/Perth',
      es: 'Australia/Perth',
    },
    description: 'Perth',
    offset: 'UTC +08:00',
  },
  {
    identifier: 'Asia/Seoul',
    names: {
      en: 'Asia/Seoul',
      es: 'Asia/Seúl',
    },
    description: 'Seoul',
    offset: 'UTC +09:00',
  },
  {
    identifier: 'Asia/Tokyo',
    names: {
      en: 'Asia/Tokyo',
      es: 'Asia/Tokio',
    },
    description: 'Osaka, Sapporo, Tokyo',
    offset: 'UTC +09:00',
  },
  {
    identifier: 'Asia/Yakutsk',
    names: {
      en: 'Asia/Yakutsk',
      es: 'Asia/Yakutsk',
    },
    description: 'Yakutsk',
    offset: 'UTC +09:00',
  },
  {
    identifier: 'Australia/Darwin',
    names: {
      en: 'Australia/Darwin',
      es: 'Australia/Darwin',
    },
    description: 'Darwin',
    offset: 'UTC +09:30',
  },
  {
    identifier: 'Australia/Adelaide',
    names: {
      en: 'Australia/Adelaide',
      es: 'Australia/Adelaide',
    },
    description: 'Adelaide',
    offset: 'UTC +09:30',
  },
  {
    identifier: 'Australia/Sydney',
    names: {
      en: 'Australia/Sydney',
      es: 'Australia/Sydney',
    },
    description: 'Sydney',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Australia/Melbourne',
    names: {
      en: 'Australia/Melbourne',
      es: 'Australia/Melbourne',
    },
    description: 'Canberra, Melbourne',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Australia/Brisbane',
    names: {
      en: 'Australia/Brisbane',
      es: 'Australia/Brisbane',
    },
    description: 'Brisbane',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Australia/Hobart',
    names: {
      en: 'Australia/Hobart',
      es: 'Australia/Hobart',
    },
    description: 'Hobart',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Asia/Vladivostok',
    names: {
      en: 'Asia/Vladivostok',
      es: 'Asia/Vladivostok',
    },
    description: 'Vladivostok',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Pacific/Guam',
    names: {
      en: 'Pacific/Guam',
      es: 'Pacífico/Guam',
    },
    description: 'Guam',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Asia/Magadan',
    names: {
      en: 'Asia/Magadan',
      es: 'Asia/Magadan',
    },
    description: 'Magadan',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Pacific/Port_Moresby',
    names: {
      en: 'Pacific/Port_Moresby',
      es: 'Pacífico/Port_Moresby',
    },
    description: 'Port Moresby',
    offset: 'UTC +10:00',
  },
  {
    identifier: 'Pacific/Guadalcanal',
    names: {
      en: 'Pacific/Guadalcanal',
      es: 'Pacífico/Guadalcanal',
    },
    description: 'Solomon Is.',
    offset: 'UTC +11:00',
  },
  {
    identifier: 'Pacific/Noumea',
    names: {
      en: 'Pacífico/Noumea',
      es: 'Pacífico/Noumea',
    },
    description: 'New Caledonia',
    offset: 'UTC +11:00',
  },
  {
    identifier: 'Asia/Kamchatka',
    names: {
      en: 'Asia/Kamchatka',
      es: 'Asia/Kamchatka',
    },
    description: 'Kamchatka',
    offset: 'UTC +12:00',
  },
  {
    identifier: 'Pacific/Fiji',
    names: {
      en: 'Pacific/Fiji',
      es: 'Pacífico/Fidji',
    },
    description: 'Fiji',
    offset: 'UTC +12:00',
  },
  {
    identifier: 'Pacific/Majuro',
    names: {
      en: 'Pacific/Majuro',
      es: 'Pacífico/Majuro',
    },
    description: 'Marshall Is.',
    offset: 'UTC +12:00',
  },
  {
    identifier: 'Pacific/Chatham',
    names: {
      en: 'Pacific/Chatham',
      es: 'Pacífico/Chatham',
    },
    description: 'Chatham Is.',
    offset: 'UTC +12:45',
  },
  {
    identifier: 'Pacific/Auckland',
    names: {
      en: 'Pacific/Auckland',
      es: 'Pacífico/Auckland',
    },
    description: 'Auckland, Wellington',
    offset: 'UTC +12:00',
  },
  {
    identifier: 'Pacific/Fakaofo',
    names: {
      en: 'Pacific/Fakaofo',
      es: 'Pacífico/Fakaofo',
    },
    description: 'Tokelau Is.',
    offset: 'UTC +13:00',
  },
  {
    identifier: 'Pacific/Tongatapu',
    names: {
      en: 'Pacific/Tongatapu',
      es: 'Pacífico/Tongatapu',
    },
    description: `Nuku'alofa`,
    offset: 'UTC +13:00',
  },
  {
    identifier: 'Pacific/Apia',
    names: {
      en: 'Pacific/Apia',
      es: 'Pacífico/Apia',
    },
    description: 'Samoa',
    offset: 'UTC +13:00',
  },
];
