import { Component, Input } from '@angular/core';

@Component({
  selector: 'emma-loading',
  templateUrl: './emma-loading.component.html',
})
export class EMMALoadingComponent {
  @Input() error?: boolean | string | Error | null;
  @Input() message?: string;
}
